import React from 'react';
import NewBook from '../Book/NewBook';
import Helmet from '../Helmet';
import "./style.css";

const CaptainStory = ({ data }) => {
    const checkWidth = (window.innerWidth > 767);

    return (
        <div id="page-story" className="fade-onload">
            <Helmet
                title="Captain's story | Captain Candy"
                metaDesc="Our stores can be found in various cities in Europe. But beware! Not every shop with a wooden interior is a guarantee of Captain Candy quality. Always look only for the original Captain Candy stores, which are a safe bet. You can find a list of our stores on our website."
            />
            <header>
                <div id="book-bg">
                    <div id="mybook" className="booklet position-relative">
                        <div className='position-relative w-100 h-100'>
                            {checkWidth ? (
                                <NewBook data={data} />
                            ) : (
                                data?.map((el, index) => (
                                    <div key={`data-${index + 1}`}>
                                        {el.data}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default CaptainStory;
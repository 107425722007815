import novinkyNaEmailEn from "../img/novinky-na-email_en.svg";
import { Fragment } from "react";

const NewsLetterContent = {
    en: {
        img: novinkyNaEmailEn,
        title: <Fragment>Hopefully you don't see this as a threat, but if you don't confirm your registration, we'll be allowed to
        Royal Fleet not befriend you...<br />
        It's just one step – check your email box and confirm the message.<br />
        Thank you. We wish you lots of wind in the right direction.</Fragment>,
        placeholder: "Enter your e-mail address",
        buttonName: "Sing Up",
        error: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
        emailExist: <Fragment>Confound it, this e-mail was entered by somebody before you! <br />Enter another e-mail, please.</Fragment>,
    },
};
const NewsLetterContentFaq = {
    en: {
        img: novinkyNaEmailEn,
        title: "Up until a few years back, our messages would have been etched on parchment and sent out in bottles. Fortunately we have e-mail these days. Write your address on the sheet to be the first to find out about all the captain’s expeditions and treasures.",
        placeholder: "Enter your e-mail address",
        buttonName: "Sing Up",
        error: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
    },
};

export {
    NewsLetterContent,
    NewsLetterContentFaq,
};
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Input(props) {
  const {
    type,
    name,
    placeholder,
    icon,
    validationError,
    className,
    change,
    autoComplete,
    readonly,
    defaultValue,
  } = props;
  const handleChange = (event) => {
    change(event);
  };

  return (
    <>
      <div className="he-input-holder he-relative">
        {icon && (
          <span className="he-input__icon he-absolute">
            <picture className="he-d-block">
              <source srcSet={icon} />
              <img loading="lazy" src={icon} alt="Icon" />
            </picture>
          </span>
        )}
        <input aria-label="input-checkbox" value={defaultValue} autoComplete={autoComplete} onChange={handleChange} id={name} name={name} readOnly={readonly} className={`he-input ${className} ${classNames({ 'he-w-100': type !== 'checkbox' }, { 'he-input__icon-padding': icon }, { 'he-input__error': validationError })}`} type={type} placeholder=" " />
        {type !== 'checkbox' && <label className="he-input-label" htmlFor={name}>{placeholder}</label>}
      </div>
      {validationError && <span className="he-d-block he-input__error-msg he-parag-small he-fw-medium he-mt-12 he-text-left">{validationError}</span> }
    </>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  validationError: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  readonly: PropTypes.bool,
  change: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  name: '',
  icon: '',
  placeholder: 'Enter text',
  validationError: '',
  className: '',
  defaultValue: '',
  autoComplete: 'off',
  readonly: false,
  change: () => {},
};

export default Input;

import React, { useState } from 'react';

import Logo from "../../assets/img/brand-logo.svg";
import AccordionMap from '../AccordionMap/AccordionMap';
import ModalGoTo from '../ModalGoTo/ModalGoTo';
import Maps from './Maps';

const HpMap = ({ data, locations }) => {
    const [ID, setID] = useState(null);
    const [link, setLink] = useState(null);

    const goToURL = (e) => {
        setLink(e)
    }

    return (
        <section id="map" className="fade-onload" data-js="scroll_fullpage">
            <ModalGoTo open={link} close={(e) => setLink(e)} />
            <div className="container-custom">
                <span className="hidden-xs hidden-sm">
                    <img src={Logo} alt="Captain Candy" id="map-logo" /> 
                </span>
                <h2 className="section-heading">
                    {data?.title}
                </h2>
                <div className="map-navigation hidden-xs hidden-sm" data-aos="fade-left">
                    {locations?.map((el, index) => (
                        <div
                            onMouseOut={() => setID(null)}
                            onMouseOver={() => setID(el.id)}
                            key={el.id}
                            className={`item item-map item-map-${index + 1} cp ${el.id === ID ? "active" : ""}`}
                            onClick={() => goToURL({ link: el.href, title: el.title })}
                        >
                        <h3 className="ttl">
                            {el.title}
                        </h3>
                    </div>
                    ))}
                </div>

                <div id="map_div-wrap" className="hidden-xs hidden-sm">
                    <a href="/stores" className="btn btn-primary btn-arrow btn-more-info">{data?.button?.name}</a>
                    {/* <div id="map_div">
                    </div> */}
                    <Maps handechanges={(e) => setID(e)} id={ID} locations={locations} />
                </div>
            </div>
            <div className="visible-xs visible-sm">
                <AccordionMap data={locations} open={(e) => goToURL(e)} />
            </div>
        </section>
    )
}

export default HpMap;

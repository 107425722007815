import React from 'react';
import { useState } from 'react';
import NewLetterDashboard from '../components/NewLetterDashboard/NewLetterDashboard';
import "../Utils/dashboard.scss";
import PdfView from '../components/PdfView/PdfView';
import CarrerDashboard from '../components/CarrerDashboard/CarrerDashboard';
import FranchisingDashboard from '../components/FranchisingDashboard/FranchisingDashboard';
import ContactDashboard from '../components/ContactDashboard/ContactDashboard';

const ProductsPage = () => {
    const [show, setShowModel] = useState("PdfView");
   
    return (
        <div className='page dashboard' id="page-kontakt">
            <ul className='dashboard-menu'>
               <li><button onClick={() => setShowModel("PdfView")}>Pdf view</button></li>
               <li><button onClick={() => setShowModel("Letter")}>NewLetter</button></li>
               <li><button onClick={() => setShowModel("Carrer")}>Carrer</button></li>
               <li><button onClick={() => setShowModel("Franchising")}>Franchising</button></li>
               <li><button onClick={() => setShowModel("Contact")}>Contact</button></li>
            </ul>
            <div className='w-100 content-dashboard'>
                {show === "Letter" && <NewLetterDashboard />}
                {show === "PdfView" && <PdfView />}
                {show === "Carrer" && <CarrerDashboard />}
                {show === "Franchising" && <FranchisingDashboard />}
                {show === "Contact" && <ContactDashboard />}
            </div>
        </div>
    )
}

export default ProductsPage;

import React from 'react';
import frame from "../../assets/img/team/fream-2.svg";

import '../HpAbout/about.css';
import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';
import { aboutUs } from '../../assets/dummyData/About';
import { team, teamCZ, teamDE, teamNL, teamIT } from '../../assets/dummyData/team';

import "./team.css"
import { Team } from './Team';
import { TeamGroupSlider } from './TeamGroupSlider';
import { TeamOnly } from './TeamOnly';


const AboutTeam = () => {
    const elementsToAnimate = ["title", "title1", "title2", "title3", "title4", "title5"];
    const { singleRefs } = AnimationEffects(elementsToAnimate, aboutUs, 'opacity', 0.4, 0.12);
    const responsive = [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 787,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        }
    ];
    const responsiveBig = [
            {
                breakpoint: 4024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1624,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 787,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ];

  return (
    <div className='w-100'>
        <section className='team-wrapper-2'>
            <div className="container-custom">
                <h2 className="page-heading opacity-0" ref={singleRefs.elements.title.ref}>Captain Candy Team</h2>
                <br />
                <br />
                <div className='team-sub-title'>
                    <h2 className="sub-heading opacity-0" ref={singleRefs.elements.title1.ref}>HQ</h2>
                    <br /><br />
                </div>
                
                <Team team={team} frame={frame} />
                <br />
                <br />
                <br />
                <br />
                <div className='team-sub-title'>
                    <h2 className="sub-heading opacity-0" ref={singleRefs.elements.title2.ref}>Czech republic</h2>
                    <br /><br />
                </div>
                <div className='team-cz team team-sm'>
                    <TeamOnly team={teamCZ?.team} mb />
                    <TeamGroupSlider team={teamCZ?.allTeam} id="teamcz" responsive={responsive} />
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className='team-sub-title'>
                    <h2 className="sub-heading opacity-0" ref={singleRefs.elements.title3.ref}>Italy</h2>
                    <br /><br />
                </div>
                <div className='team-cz team-it team'>
                    <TeamOnly team={teamIT?.team} id="teamit" />
                </div>
                <br />
                <br />
                <div className='team-cz team'>
                    <TeamGroupSlider team={teamIT.allTeam} id="teamall" fullWidth  responsive={responsiveBig} extraSettings />
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className='team-sub-title'>
                    <h2 className="sub-heading opacity-0" ref={singleRefs.elements.title4.ref}>Netherlands</h2>
                    <br /><br />
                </div>
                <div className='team-cz team-sm team'>
                    <TeamOnly team={teamNL?.team} mb />
                    <TeamGroupSlider team={teamNL?.allTeam} id="teamnl" responsive={responsive}  />
                </div>
                {/* <div className='team'>
                    <TeamOnly team={teamNL?.team} />
                    </div>
                    <br />
                    <br />
                    <br />
                <div className='team-cz team'>
                    <TeamGroupSlider team={teamNL?.allTeam} />
                </div> */}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className='team-sub-title'>
                    <h2 className="sub-heading opacity-0" ref={singleRefs.elements.title5.ref}>Germany</h2>
                    <br /><br />
                </div>
                <br />
                <br />
                <div className='team-cz team-sm team'>
                    <TeamOnly team={teamDE?.team} mb />
                    <TeamGroupSlider team={teamDE?.allTeam} id="teamde" responsive={responsive} />
                </div>
            </div>
        </section>
    </div>
  )
}

export default AboutTeam;
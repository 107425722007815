import aboutMin from "../img/about/1-min.png";
import aboutMin2 from "../img/about/2-min.png";
import aboutMin4 from "../img/about/4-min.png";
import aboutMin5 from "../img/about/5-min.png";
import aboutMin6 from "../img/about/6-min.png";
import aboutMin7 from "../img/about/7-min.png";
import aboutMin8 from "../img/about/8-min.png";
import aboutMin9 from "../img/about/9-min.png";
import about1 from "../img/about/1.svg";
import about2 from "../img/about/2.svg";
import about3 from "../img/about/3.svg";
import about4 from "../img/about/4.svg";
import about5 from "../img/about/5.svg";
import about6 from "../img/about/6.svg";
import about7 from "../img/about/7.svg";
import about8 from "../img/about/8.svg";
import about9 from "../img/about/9.svg";
// import about10 from "../img/about/33.png";
// import about11 from "../img/about/56.png";
import year2009 from "../img/about/2009.svg";
import year2010 from "../img/about/2010.svg";
// import year2011 from "../img/about/2011.svg";
import year2012 from "../img/about/2012.svg";
import year2013 from "../img/about/2013.svg";
import year2014 from "../img/about/2014.svg";
// import year2015 from "../img/about/2015.svg";
import year2016 from "../img/about/2016.svg";
import year2017 from "../img/about/2017.svg";
import year2020 from "../img/about/2020.svg";
import year2021 from "../img/about/2021.svg";
import { Fragment } from "react";

const AboutData = {
    footerText: {
        en: " …the Captain’s story continues!",
    },
    en: [
        {
            img: aboutMin,
            yearImg: year2009,
            year: "2009",
            title: <Fragment>The first lines <br className='hidden-xs' />in&nbsp;the logbook</Fragment>,
            description: "The idea was born to bring the best flavours to everyone and to bring back memories of childhood at least for a little while.",
        },
        {
            img: aboutMin2,
            yearImg: year2010,
            year: "2010",
            title: <Fragment>We start  <br className="hidden-xs" />ploughing the seas</Fragment>,
            description: "In all the harbours we look for merchants who can fill our barrels with the best treasures on offer.",
        },
        // {
        //     img: aboutMin3,
        //     yearImg: year2011,
        //     year: "2011",
        //     title: <Fragment>We have found  <br className="hidden-xs" /> our treasure</Fragment>,
        //     description: " Our barrels are finally full and our desire to share all these tastes can finally be fulfilled. Captain Candy has arrived!",
        // },
        {
            img: aboutMin4,
            yearImg: year2012,
            year: "2012",
            title: <Fragment> We launch <br className="hidden-xs" /> our first ship on the water</Fragment>,
            description: "The very first ship in Captain Candy’s flotilla finds a place to anchor in Melantrichova Street in the centre of Prague",
        },
        {
            img: aboutMin5,
            yearImg: year2013,
            year: "2013",
            title: <Fragment>We leave <br className="hidden-xs" />  our territorial waters</Fragment>,
            description: "For the first time, one of our ships leaves our home port and anchors in one of the most beautiful port cities in the world – Dubrovnik in Croatia.",
        },
        {
            img: aboutMin6,
            yearImg: year2014,
            year: "2014",
            title: <Fragment>Our conques <br className="hidden-xs" /> continues</Fragment>,
            description: "We do well in the Croatian waters and two more stores see the light of day in Dubrovnik!",
        },
        // {
        //     img: aboutMin7,
        //     yearImg: year2015,
        //     year: "2015",
        //     title: <Fragment> We are not idle <br className="hidden-xs" /> at home either</Fragment>,
        //     description: "TWe expand our home port and anchor more ships in the coves of Old Prague, in Karlova and Havelská streets.",
        // },
        {
            img: aboutMin8,
            yearImg: year2016,
            year: "2016",
            title: <Fragment>To the Moats!</Fragment>,
            description: "After oceans, seas, bays, deltas and rivers we also start ploughing through Moats! And so we open our fourth store in Prague on Na Příkopě Street (příkop = moat in Czech!)",
        },
        {
            img: aboutMin9,
            yearImg: year2017,
            year: "2017",
            title: <Fragment>We continue to <br className="hidden-xs" />colonise</Fragment>,
            description: "A fully equipped ship with an experienced crew finds anchor in two stores in the Italian city of Venice..",
        },
        {
            img: aboutMin,
            yearImg: year2020,
            year: "2020",
            title: <Fragment>We are expanding<br className="hidden-xs" />to the Netherlands</Fragment>,
            description: "Amsterdam is our next stop on the way to the conquest of Europe. Our sweets make more and more people happy.",
        },
        {
            img: aboutMin7,
            yearImg: year2021,
            year: "2021",
            title: <Fragment>We have more <br className="hidden-xs" />countries in our sights</Fragment>,
            description: "Our plans are clear, we are sailing to Germany and it certainly won't be our last stop...",
        },
    ],
}

const routeImgData = [
    {
        step: "step1",
        imgAbout: about1,
    },
    {
        step: "step2",
        imgAbout: about2,
    },
    {
        step: "step3",
        imgAbout: about3,
    },
    {
        step: "step4",
        imgAbout: about4,
    },
    {
        step: "step5",
        imgAbout: about5,
    },
    {
        step: "step6",
        imgAbout: about6,
    },
    {
        step: "step7",
        imgAbout: about7,
    },
    {
        step: "step8",
        imgAbout: about8,
    },
    {
        step: "step9",
        imgAbout: about9,
    },
    // {
    //     step: "step10",
    //     imgAbout: about11,
    // },
    // {
    //     step: "step11",
    //     imgAbout: about9,
    // },
];

const aboutUs = [
    {
        id: "12345",
        class: "",
        data: "Captain Candy operates a network of stores selling the most delicious candies in countries across Europe."
    },
    {
        id: "12346",
        class: "",
        data: `Perfect flavours and an unforgettable experience - that's what customers immediately love about Captain Candy. The history of the brand dates back to 2014 and represents a long journey, with a lot of planning, testing and one small store at the beginning to the shape the chain has today. Over the years, Captain Candy has grown into a reputable brand and a well-respected franchise concept at the same time. Many consider it synonymous with quality sweets. But the brand had to overcome many obstacles before it became a "love brand".`,
    },
    {
        id: "12347",
        class: "konopi",
        data: "In addition to the usual competition, the Captain Candy brand has had to face unfair and illegal copies of its concept and logo. However, the unfair competition did not put this original concept on the chopping block. Today, thanks to hard work, dedication, faith and perseverance, Captain Candy is one of the most respected candy retailers in Europe.",
    },
    {
        id: "12348",
        class: "konopi",
        data: "At Captain Candy, we don't just focus on offering tasty sweets, but also on providing the ultimate customer shopping experience. The first store in the historic centre of Prague was carefully selected and planned to provide not only excellent products, but also original and interesting interiors."
    },
    {
        id: "12349",
        class: "konopi",
        data: "After the successful launch of the Prague store, the company decided to expand its operations and try its luck abroad. Today you can find Captain Candy stores not only in the Czech Republic, but also in Italy, the Netherlands and Germany"
    },
    {
        id: "123451",
        class: "konopi",
        data: "In the Czech Republic, in addition to the stores in Prague, we also operate an e-shop, so that customers from other parts of the country can also enjoy our products. In Prague, we also offer delivery via Wolt, so if you don't have a direct route to the city centre, you can use the delivery service."
    },
    {
        id: "123415",
        class: "konopi",
        data: "In addition to our network of stores, we also offer franchise cooperation. We believe that the number of our stores will continue to expand and that we will be able to welcome new and new satisfied customers every day."
    },
    {
        id: "123452",
        class: "text-right",
        data: "We look forward to your visit!"
    },
]

export {
    aboutUs,
    routeImgData,
    AboutData,
};
import React from 'react';
import { CaptainStoryData } from '../assets/dummyData/CaptainStoryData';
import CaptainStory from '../components/CaptainStory/CaptainStory';

const CaptainStoryPage = () => {
  const lang = "en";
  return (
    <CaptainStory data={CaptainStoryData[lang]} />
  )
}

export default CaptainStoryPage;
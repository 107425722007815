import benatky_1 from "../img/stores/benatky_1-min.jpg";
import benatky_2 from "../img/stores/benatky_2-min.jpg";
import benatky_3 from "../img/stores/benatky_3-min.jpg";
import verona_1 from "../img/stores/verona_1-min.jpg";
import verona_2 from "../img/stores/verona_2-min.jpg";
import praha_1 from "../img/stores/praha_1-min.jpg";
import praha_2 from "../img/stores/praha_2-min.jpg";
import florencie_1 from "../img/stores/florencie_1.webp";
import amstrdam_1 from "../img/stores/amstrdam_1-min.jpg";
import amstrdam_2 from "../img/stores/amstrdam_2-min.jpg";
import kolin_nad_rynem_1 from "../img/stores/kolin_nad_rynem_1-min.jpg";
import Kalverstraat from "../img/stores/Kalverstraat.webp";
import roma1 from "../img/stores/roma.webp";

import mapMarker from "../img/icons/map_marker.svg";

import prodejny_map_path_praha from "../img/stores/prodejny_map_path_praha.svg";
// import prodejny_map_path_verona from "../img/stores/prodejny_map_path_verona.svg";
import prodejny_map_path_amsterdam from "../img/stores/prodejny_map_path_amsterdam.svg";
import prodejny_map_path_kolin from "../img/stores/prodejny_map_path_kolin.svg";

import de from "../img/stores/de.svg";
import deXs from "../img/stores/de_xs.svg";
import it from "../img/stores/it.svg";
import itXs from "../img/stores/it_xs.svg";
import nl from "../img/stores/nl.svg";
import nlXs from "../img/stores/nl_xs.svg";
import cz from "../img/stores/cz.svg";
import czXs from "../img/stores/cz_xs.svg";
import pathIt from "../img/stores/path_it.svg";

const StorsData = [
    {
        cityId: "czech",
        id: "prague",
        title: "Our stores in Czech Republic",
        places: [
            {
                city: "Prague",
                img: praha_1,
                title: "Na Příkopě 10",
                link: "https://www.google.com/maps/place/Captain+Candy/@50.0848424,14.4246062,74m/data=!3m1!1e3!4m14!1m7!3m6!1s0x470b95051d1b8d21:0xe88427313b69d732!2sCaptain+Candy!8m2!3d50.0848976!4d14.4248105!16s%2Fg%2F11g1mw32gn!3m5!1s0x470b95051d1b8d21:0xe88427313b69d732!8m2!3d50.0848976!4d14.4248105!16s%2Fg%2F11g1mw32gn",
                // link: "https://goo.gl/maps/XFnaAuFP8e82",
                linkName: "Show on map",
                closet: "Temporarily closed",
                // table: [
                //     {
                //         open: "Monday - Sunday",
                //         time: "10:00—21:00",
                //     },
                // ]
            },
            {
                city: "Prague",
                img: praha_2,
                title: "Karlova 7",
                link: "https://www.google.com/maps/place/Captain+Candy/@50.0860525,14.4172356,46m/data=!3m1!1e3!4m14!1m7!3m6!1s0x470b956e7624eeef:0x9526460ed8bff003!2sCaptain+Candy!8m2!3d50.0861037!4d14.4172316!16s%2Fg%2F11g1mw30vr!3m5!1s0x470b956e7624eeef:0x9526460ed8bff003!8m2!3d50.0861037!4d14.4172316!16s%2Fg%2F11g1mw30vr",
                // link: "https://goo.gl/maps/3c9qQmG18m82",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday - Sunday",
                        time: "10:00—21:00",
                    },
                ]
            },
        ]
    },
    {
        cityId: "italy",
        id: "venecie",
        title: "Our stores in Italy",
        places: [
            {
                city: "Venice",
                img: benatky_1,
                title: "Salizada San Giovanni Grisostomo 5803",
                link: "https://www.google.com/maps/place/Captain+Candy/@45.4389939,12.337011,51m/data=!3m1!1e3!4m14!1m7!3m6!1s0x477eb1b10bfd385d:0x936438772f8a8bb2!2sCaptain+Candy!8m2!3d45.4390208!4d12.3371627!16s%2Fg%2F11skl10jgf!3m5!1s0x477eb1b10bfd385d:0x936438772f8a8bb2!8m2!3d45.4390208!4d12.3371627!16s%2Fg%2F11skl10jgf",
                // link: "https://www.google.cz/maps/place/Salizada+S.+Giovanni+Grisostomo,+5803,+30121+Venezia+VE,+It%C3%A1lie/@45.4390141,12.3349899,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1dbee572bd1:0x87490efe04ea7997!8m2!3d45.4390141!4d12.3371786",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday - Thursday",
                        time: "10:00—22:00",
                    },
                    {
                        open: "Friday - Saturday",
                        time: "10:00—23:00",
                    },
                    {
                        open: "Sunday",
                        time: "10:00—22:00",
                    },
                ]
            },
            {
                city: "Venice",
                img: benatky_2,
                title: "San Marco 5037",
                link: "https://www.google.com/maps/place/Captain+Candy/@45.4370404,12.3364439,110m/data=!3m1!1e3!4m6!3m5!1s0x477eb16559e79c99:0x26007dfdc61aed23!8m2!3d45.4370718!4d12.3365584!16s%2Fg%2F11ghm_b8wg",
                // link: "https://www.google.cz/maps/place/S.+Marco,+4742-4743,+30100+Venezia+VE,+It%C3%A1lie/@45.4362713,12.3335094,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1da159153df:0x4a162b15fe2576a6!8m2!3d45.4362676!4d12.3356981",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday - Thursday",
                        time: "10:00—22:00",
                    },
                    {
                        open: "Friday - Saturday",
                        time: "10:00—23:00",
                    },
                    {
                        open: "Sunday",
                        time: "10:00—22:00",
                    },
                ]
            },
            {
                city: "Venice",
                img: benatky_3,
                title: "Castello 5577/5578",
                link: "https://www.google.com/maps/place/Captain+Candy/@45.4374255,12.3383199,66m/data=!3m1!1e3!4m14!1m7!3m6!1s0x477eb162b2274b33:0xe733e494b53bcfdf!2sCaptain+Candy!8m2!3d45.4374489!4d12.3384141!16s%2Fg%2F11f60xwrw5!3m5!1s0x477eb162b2274b33:0xe733e494b53bcfdf!8m2!3d45.4374489!4d12.3384141!16s%2Fg%2F11f60xwrw5",
                // link: "https://www.google.cz/maps/place/S.+Marco,+4742-4743,+30100+Venezia+VE,+It%C3%A1lie/@45.4362713,12.3335094,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1da159153df:0x4a162b15fe2576a6!8m2!3d45.4362676!4d12.3356981",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday - Thursday",
                        time: "10:00—22:00",
                    },
                    {
                        open: "Friday - Saturday",
                        time: "10:00—23:00",
                    },
                    {
                        open: "Sunday",
                        time: "10:00—22:00",
                    },
                ]
            },
            {
                city: "Rome",
                img: roma1,
                title: "Piazza di Trevi 98",
                link: "https://www.google.com/maps/place/Piazza+di+Trevi,+98,+00187+Roma+RM,+Italy/@41.9007678,12.4830537,17z/data=!3m1!4b1!4m6!3m5!1s0x132f605327b22a25:0xb5ace38e52288812!8m2!3d41.9007678!4d12.4830537!16s%2Fg%2F11c5pl3jlq?entry=ttu",
                // link: "https://www.google.cz/maps/place/S.+Marco,+4742-4743,+30100+Venezia+VE,+It%C3%A1lie/@45.4362713,12.3335094,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1da159153df:0x4a162b15fe2576a6!8m2!3d45.4362676!4d12.3356981",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday - Thursday",
                        time: "10:00-00:00",
                    },
                    {
                        open: "Friday - Sunday",
                        time: "10:00—01:00",
                    },
                ]
            },
            {
                city: "Verona",
                img: verona_1,
                title: "Via Cappello 6",
                link: "https://www.google.com/maps/place/Captain+Candy/@45.4420196,10.9977435,131m/data=!3m1!1e3!4m15!1m7!3m6!1s0x477f5ff2cc71ffd9:0x8b8c63436a39917e!2sCaptain+Candy!8m2!3d45.4420616!4d10.9980342!16s%2Fg%2F11jwy62mly!3m6!1s0x477f5ff2cc71ffd9:0x8b8c63436a39917e!8m2!3d45.4420616!4d10.9980342!15sCh1DYXB0YWluIENhbmR5LCBWaWEgQ2FwcGVsbG8gNloeIhxjYXB0YWluIGNhbmR5IHZpYSBjYXBwZWxsbyA2kgELY2FuZHlfc3RvcmXgAQA!16s%2Fg%2F11jwy62mly",
                // link: "https://www.google.cz/maps/place/Via+Cappello,+6,+37121+Verona+VR,+It%C3%A1lie/@45.4420786,10.9979177,21z/data=!4m5!3m4!1s0x477f5f490033de7d:0x547252f32b8cb95d!8m2!3d45.4420523!4d10.9979918",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday - Thursday",
                        time: "10:00—21:00",
                    },
                    {
                        open: "Friday - Saturday",
                        time: "10:00—22:00",
                    },
                    {
                        open: "Sunday",
                        time: "10:00—21:00",
                    },
                ]
            },
            {
                city: "Verona",
                img: verona_2,
                title: "Via Mazzini 78",
                link: "https://www.google.com/maps/place/Captain+Candy/@45.4397956,10.993574,140m/data=!3m1!1e3!4m14!1m7!3m6!1s0x477f11db0e4ab541:0x8a68b0af81042c30!2sCaptain+Candy!8m2!3d45.4398929!4d10.9937855!16s%2Fg%2F11jylcp_qn!3m5!1s0x477f11db0e4ab541:0x8a68b0af81042c30!8m2!3d45.4398929!4d10.9937855!16s%2Fg%2F11jylcp_qn",
                // link: "https://goo.gl/maps/dPopaAr6mCsU2APZ8",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday - Thursday",
                        time: "10:00—21:00",
                    },
                    {
                        open: "Friday - Saturday",
                        time: "10:00—22:00",
                    },
                    {
                        open: "Sunday",
                        time: "10:00—21:00",
                    },
                ]
            },
            {
                city: "Florence",
                img: florencie_1,
                title: "Via Por Santa Maria 38",
                link: "https://www.google.com/maps/d/u/0/viewer?mid=16L0Iy3_BQDF53QHei8EzXaYBPHI&hl=en_US&ll=43.76910325004388%2C11.254112999999982&z=21",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday - Thursday",
                        time: "10:00—21:00",
                    },
                    {
                        open: "Friday - Saturday",
                        time: "10:00—22:00",
                    },
                    {
                        open: "Sunday",
                        time: "10:00—21:00",
                    },
                ]
            },
        ]
    },
    {
        cityId: "netherland",
        id: "amsterdam",
        title: "Our stores in Netherland",
        places: [
            {
                city: "Amsterdam",
                id: "1",
                position: { lat:  52.37069480816792, lng: 4.891576315967946 },
                icon: mapMarker,
                img: amstrdam_1,
                title: "Kalverstraat 80-82",
                link: "https://www.google.com/maps/place/Captain+Candy/@52.370693,4.8913268,85m/data=!3m1!1e3!4m14!1m7!3m6!1s0x47c609de83ce9a1f:0x1e1239624974bace!2sCaptain+Candy!8m2!3d52.3706901!4d4.8915723!16s%2Fg%2F11q8g1v61l!3m5!1s0x47c609de83ce9a1f:0x1e1239624974bace!8m2!3d52.3706901!4d4.8915723!16s%2Fg%2F11q8g1v61l",
                // link: "https://www.google.com/maps/place/Kalverstraat+80,+1012+PH+Amsterdam,+Nizozemsko/@52.3706901,4.8914355,21z/data=!4m5!3m4!1s0x47c609c11f22fbf3:0x99f1192a9efc529b!8m2!3d52.3706901!4d4.8915723",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday",
                        time: "11:00—20:00",
                    },
                    {
                        open: "Tuesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Wednesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Thursday",
                        time: "10:00—21:00",
                    },
                    {
                        open: "Friday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Saturday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Sunday",
                        time: "11:00—20:00",
                    },
                ]
            },
            {
                id: "2",
                city: "Amsterdam",
                position: { lat: 52.37531920523375, lng: 4.894792735582172 },
                icon: mapMarker,
                img: Kalverstraat,
                title: "Kalverstraat 173",
                link: "https://www.google.com/maps/place/Captain+Candy/@52.3682684,4.8914479,44m/data=!3m1!1e3!4m6!3m5!1s0x47c609edfe471e9b:0xc38f8ad26c080547!8m2!3d52.3682883!4d4.8915916!16s%2Fg%2F11vchk111f?entry=ttu",
                // link: "https://www.google.com/maps/place/Captain+Candy/@52.3752246,4.8946242,52m/data=!3m1!1e3!4m14!1m7!3m6!1s0x47c609a891b29c2d:0xd71d1a64b536022e!2sCaptain+Candy!8m2!3d52.375284!4d4.8947981!16s%2Fg%2F11sk1438yq!3m5!1s0x47c609a891b29c2d:0xd71d1a64b536022e!8m2!3d52.375284!4d4.8947981!16s%2Fg%2F11sk1438yq",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday",
                        time: "11:00—20:00",
                    },
                    {
                        open: "Tuesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Wednesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Thursday",
                        time: "10:00—21:00",
                    },
                    {
                        open: "Friday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Saturday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Sunday",
                        time: "11:00—20:00",
                    },
                ]
            },
            {
                id: "3",
                city: "Amsterdam",
                position: { lat: 52.37531920523375, lng: 4.894792735582172 },
                icon: mapMarker,
                img: amstrdam_2,
                title: "Nieuwendijk 169",
                link: "https://www.google.com/maps/place/Captain+Candy/@52.3752645,4.8946245,44m/data=!3m1!1e3!4m7!3m6!1s0x47c609a891b29c2d:0xd71d1a64b536022e!4b1!8m2!3d52.375284!4d4.8947981!16s%2Fg%2F11sk1438yq?entry=ttu",
                // link: "https://goo.gl/maps/BG12c4EqxvjW9SGx8",
                linkName: "Show on map",
                table: [
                    {
                        open: "Monday",
                        time: "11:00—20:00",
                    },
                    {
                        open: "Tuesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Wednesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Thursday",
                        time: "10:00—21:00",
                    },
                    {
                        open: "Friday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Saturday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Sunday",
                        time: "11:00—20:00",
                    },
                ]
            },
        ]
    },
    {
        cityId: "germany",
        id: "cologne",
        title: "Our stores in Germany",
        places: [
            {
                city: "Cologne",
                img: kolin_nad_rynem_1,
                title: "Hohe Strasse 120-122",
                link: "https://www.google.com/maps/place/Captain+Candy/@50.9382964,6.9562987,93m/data=!3m1!1e3!4m14!1m7!3m6!1s0x47bf25a5ad663ad9:0x6cb65cbc7e7afa9d!2sCaptain+Candy!8m2!3d50.9383585!4d6.9565409!16s%2Fg%2F11t0w0dl8d!3m5!1s0x47bf25a5ad663ad9:0x6cb65cbc7e7afa9d!8m2!3d50.9383585!4d6.9565409!16s%2Fg%2F11t0w0dl8d",
                // link: "https://goo.gl/maps/uXA2JgDoJj8kYCj26",
                linkName: "Show on map",
                closet: "Temporarily closed",
                // table: [
                //     {
                //         open: "Monday - Saturday",
                //         time: "11:00—20:00",
                //     },
                //     {
                //         open: "Sunday",
                //         time: "closed",
                //     },
                // ]
            },
        ]
    },
];

const cities = {
    en: [
        {
            cityId: "czech",
            id: "path-praha",
            scrollId: "prague",
            href: "https://captaincandy.cz",
            name: "Czech Republic",
            path: prodejny_map_path_praha,
            img: cz,
            imgXs: czXs,
        },
        {
            cityId: "italy",
            id: "path-verona",
            scrollId: "verona",
            href: "https://captaincandy.it",
            name: "Italy",
            path: pathIt,
            img: it,
            imgXs: itXs,
        },
        {
            cityId: "netherland",
            id: "path-amsterdam",
            scrollId: "amsterdam",
            href: "https://captaincandy.nl",
            name: "Amsterdam",
            path: prodejny_map_path_amsterdam,
            img: nl,
            imgXs: nlXs,
        },
        // {
        //     id: "path-benatky",
        //     scrollId: "venecie",
        //     name: "Venice",
        //     path: prodejny_map_path_benatky,
        //     img: prodejny_map_path_city_benatky,
        //     imgXs: prodejny_map_path_city_benatkyXs,
        // },
        // {
        //     id: "path-florencie",
        //     scrollId: "florence",
        //     name: "Florence",
        //     path: prodejny_map_path_florencie,
        //     img: prodejny_map_path_city_florencie,
        //     imgXs: prodejny_map_path_city_florencieXs,
        // },
        {
            cityId: "germany",
            id: "path-kolin",
            scrollId: "cologne",
            href: "https://captaincandy.de",
            name: "Germany",
            path: prodejny_map_path_kolin,
            img: de,
            imgXs: deXs,
        },
    ],
};

export {
    StorsData, cities,
}
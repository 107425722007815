import React, {useState} from 'react';
import './ForgotPassword.scss';

// libraries
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { axiosLink } from '../../axiosLink';

const ForgotPassword = (props) => {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [msg, setMsg] = useState('');
    const [searchParams] = useSearchParams();
    const getToken = searchParams.get('token');
    const navigate = useNavigate();
    
    if (!getToken) {
        navigate("/");
    }

    const validateEmail = (e) => {
        e.preventDefault()

        if(password === password2 && password.length > 7)
            axios({
                method: 'PUT',
                url: `${axiosLink}/account-security/change-password`, // request to send email for lost password
                data: {password: password},
                headers: {token: getToken}
            })
            .then(data => {
                setMsg('Password updated !')
                setTimeout(() => {
                    navigate("/");
                }, 500)
            })
            .catch(err => console.log(err))
        else
            setMsg("Couldn't change password or password should be longer than 8 character!")

        };

    return (
        <div className='page' id="page-kontakt">
            <div className="login-form col-lg-6 ResetPassword">
                <div>
                <h1>Reset password</h1>
                <form onSubmit={validateEmail}>
                    <div className="form-group">
                    <label htmlFor="form-group">Type your password</label>
                        <input
                            type="password"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$"
                            className="form-control"
                            placeholder="New password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            name="password"
                            required
                        />
                        <label htmlFor="form-group">Retype your password:</label>
                        <input
                            type="password"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$"
                            className="form-control"
                            placeholder="Retype new password"
                            value={password2}
                            onChange={(event) => setPassword2(event.target.value)}
                            name="password"
                            required
                        />
                    </div>
                    {msg}<br></br><br></br>
                    <button className="btn btn-success" onClick={validateEmail}>Save Password</button>
                </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;
import React from 'react';
import Accordion from '../Accordion/Accordion';

const Faq = ({ data, title, dataProcessing }) => {

  return (
    <div>
        <header id="main"></header>
        <div className={`container-custom page ${dataProcessing && "page-plain fade-onload"}`} id={dataProcessing ? "page-faq" : ""}>
            <h1 className="page-heading fade-onload">
                {title}
            </h1>
            {data && <Accordion data={data} />}
            {dataProcessing && (
              <div className="content-1400">
                {dataProcessing?.map((element, i) => (
                  <p key={`element${i}`}>{element}</p>
                ))}
                <div className="spacer70" />
                <div className="spacer70 hidden-xs" />
              </div>
            )}
        </div>

    </div>
  )
}

export default Faq;
import React from 'react';
import Stores from '../components/Stores/Stores';
import { cities, StorsData } from "../assets/dummyData/StorsData";

const StoresPage = () => {
  const lang = "en";
  return (
    <Stores cities={cities[lang]} data={StorsData} />
  )
}

export default StoresPage;
import React, { useState, useRef, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';

const NewBook = ({ data }) => {
    const [innderWidth, setInnderWidth] = useState(400);
    const [minHeight, setMinHeight] = useState(720);
    const [pageNumber, setPageNumber] = useState(0);
    const checkWidth = (window.innerWidth > 767);

    const book = useRef();

    const onFlip = (data) => {
        setPageNumber(data);
    }
    const elHeight = document.getElementById('mybook')?.clientHeight;

    useEffect(() => {
        if (window.innerWidth >= 1200) {
            const x = ((window.innerWidth/2)/2)
            setInnderWidth(x);
        } else if (window.innerWidth >= 790) {
            const x = ((window.innerWidth/2)*83/100)
            setInnderWidth(x);
            setMinHeight(500);
        } else {
            const x = ((window.innerWidth/2)*82/100)
            setInnderWidth(x);
            setMinHeight(450);
        }
    }, []);
    return (
        <div className="position-relative">
            <HTMLFlipBook
                size="stretch"
                minWidth={innderWidth}
                width={innderWidth}
                height={minHeight}
                maxHeight="730"
                autoSize={true}
                usePortrait={true}
                drawShadow={false}
                ref={book}
                onFlip={(e) => onFlip(e.data)}
            >
                {data?.map((el, index) => (
                    <div className={`b-page b-page-${index} b-p${index + 1}`} style={{ top: '0px' }} key={`element${index + 1}`}>
                        <div className={`b-wrap ${index % 2 ? "b-wrap-right" : "b-wrap-left"} w-100 h-100`} style={{maxHeight: "730px" }}>
                            {el.data}
                        </div>
                        {index > 0 && <div className="b-counter" style={{ top: `${elHeight}px` }}>{index}</div>}
                    </div>
                ))}
            </HTMLFlipBook> 
            {checkWidth && (
                <div className="b-controls" style={{ top: `${elHeight}px` }}>
                    {pageNumber > 0 && (
                        <div
                            className="b-tab b-tab-prev b-prev"
                            title=""
                            style={{ width: "60px", height: "20px", top: "-20px", cursor: "default" }}
                            onClick={() => book.current.pageFlip().flipPrev()}
                        />
                    )}
                    {(pageNumber < (data?.length - 2)) && (
                        <div
                            className="b-tab b-tab-next b-next"
                            title=""
                            onClick={() => book.current.pageFlip().flipNext()}
                            style={{ width: "60px", height: "20px", top: "-20px", cursor: "pointer"}}
                        />
                    )
                    }
                </div>
            )}
        </div>
    )
}

export default NewBook;

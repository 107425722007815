import React, { useState, useRef } from 'react';
import axios from "axios";
import { axiosLink } from '../axiosLink';
import LoaderMail from '../components/LoaderMail/LoaderMail';

import { saveAs } from 'file-saver';
import Input from '../components/Input';
import "../Utils/dashboardClient.scss";
import { Link } from 'react-router-dom';

const PdfClient = () => {
    const [pdfFile, setFile] = useState();
    const [disabledSend, setDisabledSend] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [currentEmail, setCurrentEmail] = useState(false);
    const [exist, setExist] = useState(false);
    const [email, setEmail] = useState();

    const iframeRef = useRef(null);

    const handleTitleChange = () => {
        return 
    }
    // const test = () => {
    //   // Get the window object of the iframe
    //   const iframeWindow = iframeRef;
    //   var myIframe = document.getElementById('test').contentWindow.document.getElementById('title')
    //   // Set the title of the iframe document
    //   var frameObj = document.getElementById("test");
    //   var frameContent = frameObj.contentWindow.document.body.innerHTML;
      

    //     // var text = document.createTextNode("This just got added");

    //     // paragraph.appendChild(text);
    // //   iframeWindow.document.title = "New Title";
    // }
    
    const onchange = (e) => {
        setEmail(e.target.value);
    };

    const checkUser = () => {
        setDisabledSend(true);
        axios.post(
            `${axiosLink}/pdf/checkUser`,
            { email: email },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ).then((response) => {
           if (response.data) {
                if (response.data?.success) {
                    setCurrentEmail('Successful login - "View PDF" or "Download PDF"!');
                    setTimeout(() => {
                            setCurrentEmail(false);
                            setDisabledSend(false);
                            setExist(true);
                    }, 4000);
                } else {
                    setErrorMessage("For this user session has expired, please contact the captain!");
                    setTimeout(() => {
                        setErrorMessage(false);
                        setDisabledSend(false);
                     }, 8000);
                }
            } else {
                setErrorMessage("This user does not exist, check the username or contact the captain!");
                setTimeout(() => {
                    setErrorMessage(false);
                    setDisabledSend(false);
                 }, 8000);
            }
        })
        .catch((err) => {
            console.log("errr", err)
        });
    }

    const getPDF = () => {
        setDisabledSend(true);
        axios.post(
            `${axiosLink}/pdf/getpdf`,
            { email: email },
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ).then((response) => {   
            const URL = window.URL || window.webkitURL;
            const file = URL.createObjectURL(response.data);
            const fileName = { 
                
            }; // Set the desired file name here
          
            // console.log("FILE", file);
            setFile(file);
            setTimeout(() => {
                setDisabledSend(false);
            }, 1000);
        })
        .catch((err) => {
            console.log("errr", err)
        });
    }

    const printTickets = () => {
        setDisabledSend(true);
        axios.post(
            `${axiosLink}/pdf/getpdf`,
            { email: email },
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ).then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' })
            saveAs(blob, "Captaincandy Presentation.pdf");
            setTimeout(() => {
                setDisabledSend(false);
            }, 1000);
        }).catch((err) => {
            console.log("errr", err)
        });
    }

    return (
        <div id="page-kontakt" className="page">
            <div className="content">
                <div className="container-custom">
                    <br />
                    <br />
                    <br />
                    <h1 className="page-heading fade-onload">
                        Captain candy presentation!
                    </h1>
                    <p className="fade-onload">
                        Thank you for visit Captain candy. I would be happy to <Link to="/contact">contact us</Link> in order to obtain access to our presentation.
                        <br />
                        Please let me know the best method of contact and any necessary information that I should provide when doing so.
                        <br />
                        Do you have something on your mind? Would you like to find something out from us? Don’t be afraid to write to us!
                    </p>
                </div>
                   
                <div className="clearfix"></div>
                {!exist && <div className="dashboard-client">
                    <div className='w-100 set-test-center'>
                        <Input change={onchange} defaultValue={email} type="text" name="email" placeholder="Username" />
                        {!!errorMessage && (<span className="he-absolute  he-input__error-msg he-parag-small" style={{ fontWeight: "bold"}}>{errorMessage}</span>)}
                        
                        {!!currentEmail && <span className="he-login-form-success he-parag-small" style={{ fontWeight: "bold"}}>{currentEmail}</span>}
                        <br />
                        <br />
                        <button onClick={() => checkUser()} type="button" className={`btn btn-primary ${(disabledSend) && "disabled"}`} disabled={disabledSend}>{!disabledSend ? "Login" : <LoaderMail /> }</button>
                    </div>
                </div>}
                <div className="clearfix"></div>

                {exist && (
                    <div>
                        <div className='mb-30'>
                            <button onClick={() => getPDF()} type="button" className={`btn mr-3 mb-sm-20 show-on-desktop btn-primary ${(disabledSend) && "disabled"}`} disabled={disabledSend}>{!disabledSend ? "View PDF" : <LoaderMail /> }</button>
                            <button onClick={() => printTickets()} type="button" className={`btn btn-primary ${(disabledSend) && "disabled"}`} disabled={disabledSend}>{!disabledSend ? "Download" : <LoaderMail /> }</button>
                            {/* <button onClick={() => test()} type="button" className={`btn btn-primary ${(disabledSend) && "disabled"}`} disabled={disabledSend}>{!disabledSend ? "Download" : <LoaderMail /> }</button> */}
                        </div>
                        {pdfFile && <div style={{ height: "90vh"}}>
                            <iframe id="test" ref={iframeRef} onLoad={() => handleTitleChange()}  width='100%' height='100%' src={pdfFile}></iframe>
                        </div>}
                        {/* {pdfFile && <div style={{ height: "90vh"}}>
                            <object ref={iframeRef} onLoad={() => handleTitleChange()}  width="100%" height="100%" data={pdfFile} name="adi.pdf" type="application/pdf">
                                <embed id="test" width="100%" height="100%" src={pdfFile} name="adi.pdf" type="application/pdf" />
                            </object>
                        </div>} */}
                    </div>
                )}
            </div>
        </div>
    )
}

export default PdfClient;
import React, { useEffect, useState }from 'react';
import axios from "axios";
import { axiosLink } from '../../axiosLink';
import Token from "../../Utils/Token"
import { useNavigate } from 'react-router-dom';

const NewLetterDashboard = () => {
    const [data, setData] = useState();
    const { activeToken } = Token();
    const navigate = useNavigate();
  
    useEffect(() => {
           axios.get(
            `${axiosLink}/newsletter/all`,
            {
                headers: {
                Authorization: activeToken,
                'Content-Type': 'application/json',
                },
            }
        ).then((res) => {
            if(!res.data?.errMsg) {
                setData(res?.data)
            } else {
                navigate("/")
            }
        })
    }, []);
    return (
            <table className='w-100'>
                <tbody>
                <tr>
                    <th>E-mail</th>
                    <th>State</th>
                    <th>Status</th>
                    <th>Created date</th>
                </tr>
                {data && data?.map((el) => (
                    <tr key={el.id} style={{ borderBottom: "1pt solid black" }}>
                        <td>{el.email}</td>
                        <td> {el.state}</td>
                        <td>
                            {el.status ? 
                                <svg fill="#40C057" viewBox="0 0 64 64" width="24px" height="24px"><path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z"/></svg>
                                : 
                                <svg fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1" viewBox="0 0 460.775 460.775">
                                    <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                                        c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                                        c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                                        c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                                        l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                                        c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                                </svg>
                            }
                        </td>
                        <td>{el.createdAt}</td>
                    </tr>
                ))}
                </tbody>
            </table>
  )
}

export default NewLetterDashboard;
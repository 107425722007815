import React, { useState } from 'react';
import "./style.css";
import { mapStyles } from './map';
import mapMarkerHover from "../../assets/img/icons/map_marker_hover.svg";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useEffect } from 'react';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 49.3,
  lng: 7.9,
};

function Maps({ handechanges, id, locations }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDLKc_wA4t1eOykcIJcs0kd0frcr2sYRvs&amp"
  });

  const open = (hrefLink) => {
    window.open(hrefLink, "_blank");
  }

  const [mark, setMark] = useState(locations);

  const changeImg = (e) => {
    const x = locations?.map((el) => {
      if (el.id === e) {
        return { ...el, icon: mapMarkerHover };
      }
      return el;
    });
    setMark(x);
  }

  useEffect(() => {
    changeImg(id)
  }, [id]);

  return isLoaded ? (
      <GoogleMap
        disableDefaultUI={true}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5.2}
        options={{
          styles: mapStyles,
        }}
      >
        {mark?.map((el) => (
          <Marker 
            key={el.id}
            position={el.position}
            onClick={()=> open(el.link)}
            onMouseOut={() => {
              setMark(locations)
              handechanges(null);
            }}
            onMouseOver={() => {
              handechanges(el.id);
              changeImg(el.id);
            }}
            title={el.title}
            icon={el.icon}
          />
        ))}
      </GoogleMap>
  ) : <></>
}

export default Maps;
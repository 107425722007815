import React, { useState, useEffect } from 'react';
import './modal.scss';
import Anija from "../../assets/img/Anija-svg.svg"
// import vi from "../../assets/img/vi.png"

const ModalGoTo = ({ open, close }) => {
    const [openModal, setOpenModal] = useState(false);
    const [transitiona, setTransitiona] = useState(50);
    const body = document.body;

    const closeModal = (event) => {
        if (event.target.id === "modal-email-success" || event.target.id === "modal-email-success-span") {
            body.classList.remove("modal-open");
            setOpenModal(false);
            close(null)
        }
    }

    const goToURL = () => {
        setOpenModal(false);
        close(null);
        window.open(open?.link, "_blank");
    }

    useEffect(() => {
        if (open) {
            setOpenModal(true);
        }
    }, [open]);

    return (
        <div id="modal-email-success" className="modal active in fade fade-onload" style={openModal ? { display: "block" } : { display: "none" }}  role="dialog" onClick={(e) => closeModal(e)}>
            <div className="modal-dialog map">
                <div className="modal-content">
                    <span className="close" id="modal-email-success-span" data-dismiss="modal" onClick={(e) => closeModal(e)}></span>
                    <div className="modal-body content-modal">
                        <div className="content">
                           <div className="inner">
                                {open?.title && <h2 className="">Do you want to travel to our web on {open?.title}?</h2>}
                                <br />
                                <div className='holder-anija'>
                                    <img className='move-anija' style={{ left: `calc(${transitiona}% - 75px)` }} src={Anija} alt="" />
                                </div>
                                <div className='buttons'   onMouseLeave={() => setTransitiona(50)}>
                                    <button
                                        onMouseEnter={() => setTransitiona(0)}
                                        onClick={() => {setOpenModal(false); close(null)}}
                                        className="btn btn-map maps-stay btn-primary hide-after"
                                    >
                                        Stay
                                    </button>

                                    <button
                                        onClick={() => goToURL()}
                                        onMouseEnter={() => setTransitiona(100)}
                                        className="btn btn-map maps-go btn-primary btn-arrow hide-after"
                                    >
                                        Go
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModalGoTo;

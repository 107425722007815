import React from 'react';
import { useState } from 'react';
import AnimationEffects from '../assets/Animations/hooks/AnimationEffects';
import { NewsLetterContent } from '../assets/dummyData/NewsLetterData';
import { productsItems } from '../assets/dummyData/ProductsPageData';
import Helmet from '../components/Helmet';
import ModalEmailSuccess from '../components/ModalEmailSuccess/ModalEmailSuccess';
import NewLetter from '../components/NewLetter/NewLetter';
import Products from '../components/Products/Products';

const ProductsPage = () => {
    const [shoeModalEmail, setShoeModalEmail] = useState(false);
    const lang = "en";
    const { singleRefs } = AnimationEffects(['NewLetter'], [] ,'fadeInOnce', 0.4, 0.12);

    const modalEmail = () => {
        setShoeModalEmail(true);
        const body = document.body;
        body.classList.add("modal-open");
        setTimeout(() => {
            setShoeModalEmail(false);
        }, 500);
    }
    const title = "Products | Captain Candy"
    const desc = "There are thousands of flavours in the world. Choose your favourite with us. We do everything we can to offer you the best quality candies from the best suppliers to satisfy even the most demanding sweet tooth."
    return (
        <>
            <div id="page-products" className="page fade-onload">
                <Helmet
                    title={title}
                    metaDesc={desc}
                />
                <Products
                    productsItems={productsItems[lang]}
                    title={productsItems.headTitle[lang]}
                    subTitle={productsItems.headSubTitle[lang]}
                    stores={productsItems.stores[lang]}
                />
                <div className="opacity-0" ref={singleRefs.elements.NewLetter.ref}>
                    <NewLetter data={NewsLetterContent[lang]} openModal={modalEmail} />
                </div>
            </div>
            <ModalEmailSuccess open={shoeModalEmail} />
        </>
    )
}

export default ProductsPage;

import React from 'react';
import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';
import frame from "../../assets/img/team/fream-2.svg";

export const TeamOnly = ({ team, mb }) => {
  const elementsToAnimate = ['title', 'description', 'items'];
  const { singleRefs } = AnimationEffects(elementsToAnimate, team, 'opacity', 0.4, 0.12);

  return (
      team?.map((el, index) => (
        <div className={`wrapper opacity-0 ${team?.length === 1 && "flex-none"}`} ref={singleRefs.items[index].ref}>
          <div className='he-relative pb-20'>
            <img className='card-team-frame' src={frame} alt={el.name} />
            <div className="holder-fream">
                <div className="card-team img-hover">
                    {el?.img ?
                        <img className='card-team-img' src={el.img} alt={el.name} /> 
                        : (
                            <svg className='card-team-img' version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="158.000000pt" height="200.000000pt" viewBox="0 0 158.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#000000" stroke="none">
                            <path d="M723 1770 c-53 -11 -117 -44 -171 -88 -56 -46 -142 -175 -142 -213 0
                            -35 30 -68 70 -76 29 -5 30 -7 30 -69 0 -116 43 -249 105 -322 86 -102 244
                            -102 330 0 62 73 105 206 105 323 0 57 2 65 19 65 11 0 27 3 36 6 19 8 45 55
                            45 84 0 52 -93 174 -174 229 -80 54 -174 77 -253 61z m84 -108 c23 -15 34 -62
                            21 -90 -27 -54 -69 -54 -96 0 -20 42 8 98 48 98 8 0 20 -4 27 -8z m154 -205
                            c55 -26 87 -52 77 -62 -2 -3 -45 0 -94 6 -86 11 -334 6 -399 -8 l-30 -6 20 23
                            c50 56 134 80 265 77 91 -2 112 -6 161 -30z m-322 -133 c113 -46 231 -41 329
                            17 18 10 35 19 38 19 9 0 3 -99 -11 -158 -30 -125 -92 -208 -175 -233 -111
                            -34 -227 79 -260 251 -14 76 -13 144 3 137 6 -3 41 -18 76 -33z"/>
                            <path d="M368 1071 c-70 -28 -112 -57 -161 -111 -85 -95 -106 -166 -107 -360
                            0 -121 3 -140 24 -186 37 -80 110 -124 205 -124 l46 0 14 57 c15 64 50 140 89
                            191 14 19 26 43 27 53 2 21 35 50 47 43 4 -3 38 13 75 36 70 42 130 64 207 75
                            46 7 46 8 46 46 0 37 2 39 30 39 28 0 30 -2 30 -39 0 -43 1 -44 74 -56 53 -9
                            139 -48 196 -89 l47 -34 30 29 29 30 22 -24 22 -24 -25 -29 c-14 -16 -25 -32
                            -25 -36 0 -4 13 -26 29 -50 35 -52 54 -96 70 -165 10 -42 17 -53 33 -53 19 0
                            19 7 16 247 l-3 248 -32 67 c-61 129 -181 222 -304 235 -54 5 -54 5 -70 -28
                            -79 -165 -245 -234 -388 -163 -54 27 -118 97 -149 162 -14 28 -20 32 -56 32
                            -23 -1 -62 -9 -88 -19z m836 -56 c49 -13 90 -26 92 -28 2 -1 -12 -15 -30 -29
                            l-33 -26 -94 26 c-52 14 -96 27 -98 28 -6 5 53 53 64 54 6 0 50 -11 99 -25z
                            m-813 -105 c10 -6 19 -19 19 -30 0 -12 5 -17 14 -14 17 6 46 -22 46 -44 0 -10
                            9 -23 21 -30 16 -10 20 -21 17 -42 -3 -21 0 -30 14 -33 29 -8 21 -42 -18 -77
                            -67 -58 -136 -67 -199 -24 -73 49 -101 106 -84 168 11 39 63 96 110 119 41 20
                            36 19 60 7z"/>
                            <path d="M825 673 c-60 -13 -126 -38 -168 -65 l-49 -31 93 -93 c92 -92 93 -93
                            120 -78 15 8 35 14 44 14 13 0 15 19 15 130 0 141 2 136 -55 123z"/>
                            <path d="M940 547 l0 -125 42 -21 43 -21 90 91 90 90 -25 21 c-39 33 -147 80
                            -196 86 l-44 5 0 -126z"/>
                            <path d="M516 468 c-31 -50 -66 -136 -66 -165 0 -10 29 -13 130 -13 l129 0 16
                            30 16 31 -84 84 c-47 47 -90 85 -96 85 -6 0 -26 -24 -45 -52z"/>
                            <path d="M1158 422 c-70 -71 -86 -93 -81 -110 5 -21 10 -22 139 -22 74 0 134
                            1 134 3 0 39 -82 217 -100 217 -3 0 -44 -40 -92 -88z"/>
                            <path d="M818 320 l-33 -29 114 -1 c102 0 112 2 100 16 -21 26 -66 44 -108 44
                            -31 0 -48 -7 -73 -30z"/>
                            </g>
                            </svg>
                        )}
                </div>
            </div>
            <p className='title'>{el.name}</p>
            <p className='positon'>{el.position}</p>
          {/* <p className='email'>{el?.email}</p> */}
          </div>
          {!mb && <><br /><br /></>}
        </div>
      ))
  )
};

import React, { useEffect } from 'react';
import { gsap, TimelineLite, Power3, Power1 } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(CSSPlugin);

export default function AnimationEffects(elements, items, animationName, fromDelay, toDelay) {
  const rootRef = React.createRef();
  const singleRefs = { elements: [], items: [] };

  let incrementalDelay = fromDelay;

  elements.forEach((item) => {
    singleRefs.elements[item] = { ref: React.createRef(), ratio: 0.4 };
  });

  for (let i = 0; i < items.length; i += 1) {
    singleRefs.items.push({ ref: React.createRef(), ratio: 0.4 });
  }

  const effects = [
    {
      opacity: {
        from: { opacity: 0, y: 20 },
        to: { opacity: 1, y: 0 },
        duration: 0.65,
        ease: Power3.easeIn,
      },
    },
    {
      fadeInLeft: {
        from: { opacity: 0, x: -75 },
        to: { opacity: 1, x: 0 },
        duration: 0.65,
        ease: Power3.easeIn,
      },
    },
    {
      fadeIn: {
        from: { opacity: 0, x: 0 },
        to: { opacity: 1, x: 0 },
        duration: 0.5,
        ease: Power3.easeIn,
      },
    },
    {
      fadeInY: {
        from: { opacity: 0, y: 75 },
        to: { opacity: 1, y: 0 },
        duration: 0.55,
        ease: Power3.easeIn,
      },
    },
    {
      fadeInOnce: {
        to: { opacity: 1, y: 0 },
        duration: 0.55,
        ease: Power1.easeIn,
      },
    },
  ];

  const animation = effects.filter((item) => Object.keys(item).toString() === animationName)[0];

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        new TimelineLite({
          delay: incrementalDelay,
          // scrollTrigger: {
          //   start: 'top 25%',
          // }
        })
          .fromTo(entry.target, animation[animationName].duration,
            animation[animationName].from, animation[animationName].to);
        incrementalDelay += toDelay;
        observer.unobserve(entry.target);
      }
    });
  }, {
    root: rootRef.current,
    threshold: 0.35,
  });

  const observeItem = (item) => {
    if (item && item.ref.current && animation) {
      observer.observe(item.ref.current);
    }
  };

  const observeItems = (array) => {
    if (array[0] !== undefined) {
      array.forEach((item) => {
        observeItem(item);
      });
    } else {
      Object.values(array).forEach((item) => observeItem(item));
    }
  };

  useEffect(() => {
    observeItems(singleRefs.elements);
    observeItems(singleRefs.items);
  });

  return { singleRefs };
}

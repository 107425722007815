import Sabina from "../img/team/Sabina-Adameov-web.webp";
// import Nikola from "../img/team/Nikola-Sherri-web.webp";
import Nikola from "../img/team/nikolla.webp";
import Nik from "../img/team/Nik-Sherri-web.webp";
import Rikard from "../img/team/Rikard-Sherri-web.webp";
import Elkson from "../img/team/Elkson-Kodraj-web.webp";
import Karin from "../img/team/Karin.webp";
import Arben from "../img/team/Arben-Kodraj-web.webp";
import Petr from "../img/team/Petr.webp";
import Mark from "../img/team/Mark-Tunaj-web.webp";
import Patrik from "../img/team/Patrik.webp";
import Edvard from "../img/team/Edvard-Kolndrekaj-web.webp";
import Arber from "../img/team/Arber-Kodraj-web.webp";
import Alberto from "../img/team/Alberto-Zampato-web.webp";
import Anna from "../img/team/Anna-Maria-web.webp";
import Milos from "../img/team/Milos-Marjanovic-web.webp";
import Humpal from "../img/team/Humpal.webp";
import Robert from "../img/team/Robert-Gjoka-web.webp";
import Alfred from "../img/team/Alfred-Gjoka-web.webp";
import Levan from "../img/team/Levan.webp";
import kristjan from "../img/team/kristjan.webp";

import imgIT1 from '../img/team/t/IT_1.webp';
import imgIT2 from '../img/team/t/IT_2.webp';
import imgIT3 from '../img/team/t/IT_3.webp';
import imgIT4 from '../img/team/t/IT_4.webp';
import imgIT5 from '../img/team/t/IT_5.webp';
import imgIT6 from '../img/team/t/IT_6.webp';

import imgCZ1 from '../img/team/t/CZ_1.webp';
import imgCZ2 from '../img/team/t/CZ_2.webp';
import imgCZ3 from '../img/team/t/CZ_3.webp';

import NL_1 from '../img/team/t/NL_1.webp';
import NL_2 from '../img/team/t/NL_2.webp';
import NL_3 from '../img/team/t/NL_3.webp';

import DE_1 from '../img/team/t/DE_1.webp';
import DE_2 from '../img/team/t/DE_2.webp';
import DE_3 from '../img/team/t/DE_3.webp';

const team = [
    {
        id: "111",
        name: "Nikola",
        position: "CEO",
        email: "nikola@captaincandy.eu",
        img: Nikola,
    },
    {
        id: "113",
        name: "Rikard",
        position: "COO",
        email: "rikard@captaincandy.eu",
        img: Rikard,
    },
    {
        id: "112",
        name: "Nik",
        position: "CFO",
        email: "nik@captaincandy.eu",
        img: Nik,
    },
    {
        id: "114",
        name: "Elkson",
        position: "Billing and credit control manager",
        email: "elkson@captaincandy.eu",
        img: Elkson,
    },
    {
        id: "115",
        name: "Karin",
        position: "Marketing and project manager",
        email: "karin@captaincandy.eu",
        img: Karin,
    },
    {
        id: "116",
        name: "Ben",
        position: "Product manager",
        email: "ben@captaincandy.eu",
        img: Arben,
    },
    {
        id: "117",
        name: "Petr",
        position: "Warehouse executive",
        email: "petr@captaincandy.eu",
        img: Petr,
    },
    {
        id: "118",
        name: "Mark",
        position: "Logistic manager",
        email: "mark@captaincandy.eu",
        img: Mark,
    },
    {
        id: "119",
        name: "Patrik",
        position: "IT specialist",
        email: "patrik@captaincandy.eu",
        img: Patrik,
    },
    {
        id: "nl2",
        name: "Robert",
        position: "Senior trainer",
        email: "robert@captaicandy.eu",
        img: Robert,
    },
    {
        id: "nl3",
        name: "Alfred",
        position: "Senior trainer",
        email: "alfred@captaincandy.eu",
        img: Alfred,
    },
    {
        id: "nl22",
        name: "Kristian",
        position: "Senior trainer",
        email: "kristian@captaicandy.eu",
        img: kristjan,
    },
    {
        id: "nl3",
        name: "Levan",
        position: "Shop manager",
        email: "levan@captaicandy.eu",
        img: Levan,
    },
];

const teamCZ = {
    allTeam: [
        {
            id: "1",
            img: imgCZ1
        },
        {
            id: "2",
            img: imgCZ2
        },
        {
            id: "3",
            img: imgCZ3
        },
        {
            id: "21",
            img: imgCZ1
        },
        {
            id: "22",
            img: imgCZ2
        },
        {
            id: "23",
            img: imgCZ3
        },
    ],
    team: [
        {
            id: "cz1",
            name: "Edvard",
            position: "Area manager",
            email: "edvard@captaincandy.eu",
            img: Edvard,
        },
    ]
};

const teamDE = {
    allTeam: [
        {
            img: DE_1,
        },
        {
            img: DE_2,
        },
        {
            img: DE_3,
        },
        {
            img: DE_1,
        },
        {
            img: DE_2,
        },
        {
            img: DE_3,
        },
    ],
    team: [
        {
            id: "cz1",
            name: "Sabina",
            position: "General manager",
            email: "sabina@captaincandy.eu",
            img: Sabina,
        },
    ]
};

const teamIT = {
    allTeam: [
        {
            img: imgIT1
        },
        {
            img: imgIT2
        },
        {
            img: imgIT3
        },
        {
            img: imgIT4
        },
        {
            img: imgIT5
        },
        {
            img: imgIT6
        },
    ],
    team: [
        {
            id: "it1",
            name: "Arber",
            position: "Executive area director",
            email: "arber@captaincandy.eu",
            img: Arber,
        },
        {
            id: "it2",
            name: "Alberto",
            position: "Office manager",
            email: "alberto@captaincandy.eu",
            img: Alberto,
        },
        {
            id: "it3",
            name: "Anna Maria",
            position: "HR manager",
            email: "annamaria@captaincandy.eu",
            img: Anna,
        },
        {
            id: "it1it4",
            name: "Milos",
            position: "Area manager",
            email: "milos@captaincandy.eu",
            img: Milos,
        },
    ]
};

const teamNL = {
    allTeam: [
        {
            img: NL_1
        },
        {
            img: NL_2
        },
        {
            img: NL_3
        },
        {
            img: NL_1
        },
        {
            img: NL_2
        },
        {
            img: NL_3
        },
    ],
    team: [
        {
            id: "nl1",
            name: "Jiří",
            position: "Operations manager",
            email: "jiri@captaicandy.eu",
            img: Humpal,
        },
        // {
        //     id: "nl2",
        //     name: "Robert",
        //     position: "Retail manager",
        //     email: "robert@captaicandy.eu",
        //     img: Robert,
        // },
        // {
        //     id: "nl3",
        //     name: "Alfred",
        //     position: "Junior retail manager",
        //     email: "alfred@captaincandy.eu",
        //     img: Alfred,
        // },
    ]
}

export {
    team,
    teamCZ,
    teamDE,
    teamNL,
    teamIT,
};
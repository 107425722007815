import React from 'react';
import svg from "./mail.svg";

const LoaderMail = () => {
    return (
        <div className="loader-mail" title="Loading">
            <img className='loader-mail-img' src={svg} alt="CAPTAIN CANDY" />
        </div>
    )
};

export default LoaderMail;

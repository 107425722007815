import React from 'react';
import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';

export const AllStores = ({ allStore }) => {
    const { singleRefs } = AnimationEffects([], allStore.places, 'opacity', 0.08 , 0.12);
    return (
        <div className="boxes-wrap">
            {allStore?.places?.map((place, i) => (
                <div key={place?.img} className="box aos-init aos-animate opacity-0" ref={singleRefs.items[i].ref}>
                    <div className="inner">
                        <div className="img-wrap">
                            <img src={place?.img} alt= {place?.title} />
                        </div>
                        <div className="content">
                            <h3 className="ttl">
                                <small className='city-space'>{place?.city}</small>
                                {place.title}
                            </h3>
                            <table width="100%">
                                <tbody>
                                    {!!place?.closet && (
                                        <tr>
                                            <td style={{color: "red"}}>{place?.closet}</td>
                                        </tr>
                                    )}
                                    {place?.table?.map((e, i) => (
                                        <tr key={`table${i}`}>
                                            <td>{e?.open}</td>
                                            <td>{e?.time}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <a target="_blank"  rel="noreferrer" href={place.link}>{place?.linkName}</a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
};

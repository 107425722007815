import React, { useEffect, useState }from 'react';
import axios from "axios";
import { axiosLink } from '../../axiosLink';
import Token from "../../Utils/Token"
import { useNavigate } from 'react-router-dom';

const ContactDashboard = () => {
    const [data, setData] = useState();
    const { activeToken } = Token();
    const navigate = useNavigate();
  
    useEffect(() => {
        axios.get(
            `${axiosLink}/mail/all`,
            {
                headers: {
                Authorization: activeToken,
                'Content-Type': 'application/json',
                },
            }
        ).then((res) => {
            if(!res.data?.errMsg) {
                setData(res?.data)
            } else {
                navigate("/")
            }
        })
    }, [activeToken, navigate]);
    return (
            <table className='w-100'>
                <tbody>
                <tr>
                    <th>Fullname</th>
                    <th>Phone</th>
                    <th>E-mail</th>
                    <th>Contact form text</th>
                    <th>State</th>
                    <th>Created date</th>
                </tr>
                {data && data?.map((el) => (
                    <tr key={el.id} style={{ borderBottom: "1pt solid black" }}>
                        <td>{el.contactform_fullName}</td>
                        <td>{el.contactform_phone}</td>
                        <td>{el.contactform_email}</td>
                        <td>{el.contactform_text}</td>
                        <td> {el.state}</td>
                        <td>{el.createdAt}</td>
                    </tr>
                ))}
                </tbody>
            </table>
  )
}

export default ContactDashboard;
import React from 'react'
import { Link } from 'react-router-dom'
import MySvgIcon from './MySvgIcon'
import { allSocial, allSocial2 } from '../../assets/dummyData/Menu';
import followUs from "../../assets/img/followus.svg";

const Social = ({ classProp }) => {
  const openLink = (url) => {
    const ridirectLink = url || "https://captaincandy.eu/"
    window.open(ridirectLink, '_blank');
  };

  return (
    <div className={`${classProp}`}>
      <img src={followUs} alt="Captain Candy" className="follow-us" />
      <div className='social-group'>
        <div className="social-group1">
          {allSocial.map((social) => (
            <div key={social.title}>
              <h5 className="social-title">{social.title}</h5>
              <ul className="wrapper-icon social-links">
                {social.links.map((el, i) => (
                  <li
                    key={el.path}
                    className={`${el.iconName} icon`}
                  >
                    <span class="tooltip-icon">{el.name}</span>
                    <Link onClick={() => openLink(el.path)}
                      className={`${el.icon}`}
                    >
                      <MySvgIcon icon={el.icon} />
                      <span></span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div>
          {allSocial2.map((social) => (
            <div key={social.title}>
              <h5 className="social-title">{social.title}</h5>
              <ul className="wrapper-icon social-links">
                {social.links.map((el, i) => (
                  <li
                    key={el.path}
                    className={`${el.iconName} icon`}
                  >
                    <span class="tooltip-icon">{el.name}</span>
                    <Link onClick={() => openLink(el.path)}
                      className={`${el.icon}`}
                    >
                      <MySvgIcon icon={el.icon} />
                      <span></span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Social;

import mapMarker from "../img/icons/map_marker.svg";

const locations = {
    en: [
            {
                id: "1",
                position: { lat:  50.073658, lng: 14.418540 },
                title: "Czech Republic",
                href: "https://captaincandy.cz",
                link: "https://www.google.com/maps/place/Kalverstraat+80,+1012+PH+Amsterdam,+Nizozemsko/@52.3706901,4.8914355,21z/data=!4m5!3m4!1s0x47c609c11f22fbf3:0x99f1192a9efc529b!8m2!3d52.3706901!4d4.8915723",
                icon: mapMarker,
            },
            {
                id: "2",
                position: { lat: 41.2925, lng: 13.9736 },
                title: "Italy",
                href: "https://captaincandy.it",
                link: "https://goo.gl/maps/BG12c4EqxvjW9SGx8",
                icon: mapMarker,
            },
            {
                id: "3",
                position: { lat: 52.520008, lng: 13.404954 },
                title: "Germany",
                href: "https://captaincandy.de",
                link: "https://goo.gl/maps/BG12c4EqxvjW9SGx8",
                icon: mapMarker,
            },
            {
                id: "4",
                position: { lat: 52.37531920523375, lng: 4.894792735582172 },
                title: "Netherland",
                href: "https://captaincandy.nl",
                link: "https://goo.gl/maps/BG12c4EqxvjW9SGx8",
                icon: mapMarker,
            }
        ],
}

export {
    locations
};

import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';

import Footer from './components/Footer/Footer';
import Loader from './components/Loader/Loader';
import NavBar from './components/NavBar/NavBar';
import AboutPage from './pages/AboutPage';
import AboutPageTeam from './pages/AboutPageTeam';
import ContactPage from './pages/ContactPage';
import FaqPage from './pages/FaqPage';
import FranchisingPage from './pages/FranchisingPage';
import HomePage from './pages/HomePage';
import PageNotFound from './pages/PageNotFound';
import ProductsPage from './pages/ProductsPage';
import StoresPage from './pages/StoresPage';
import CaptainStoryPage from './pages/CaptainStoryPage';
import PopupNewsletter from './components/PopupNewsletter/PopupNewsletter';
import PersonalDataProcessingPage from './pages/PersonalDataProcessingPage';
import ConfirmEmail from './components/ConfirmEmail/ConfirmEmail';
import LoginForm from './components/LoginForm/LoginForm';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './Utils/PrivateRoute';
import PdfClient from './pages/PdfClient';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setLoading(true)
    }, 1140);
  }, [location.pathname]);

  return (
    <div className="web-en">
      <PopupNewsletter />
      <Loader showLoader={loading} />
      <NavBar activeClass={location.pathname} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/captain-story" element={<CaptainStoryPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/team" element={<AboutPageTeam />} />
        <Route path="/stores" element={<StoresPage />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/personal-data-processing" element={<PersonalDataProcessingPage />} />
        <Route path="/franchising" element={<FranchisingPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/amerika" element={<LoginForm />} />
        <Route path="/presentation" element={<PdfClient />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/dashboard" element={<PrivateRoute path="/dashboard"><Dashboard /></PrivateRoute>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer activeClass={location.pathname} />
    </div>
  );
}

export default App;

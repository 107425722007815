const validateEmail = (email) => {
    return String(email)
    .toLowerCase()
    .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validatePhone = (phone) => {
    return String(phone)
    .toLowerCase()
    .match(
        /^([0-9\+\s]){9,}/
    );
};

const validFields = (obj) => {
    let check = false;
    Object.keys(obj).forEach((key) => {
        if (obj[key].length > 2) {
            return false;
        } else {
            if (!check) {
                check = key;
            }
        }
    })
    return check;
}

export {
    validateEmail,
    validatePhone,
    validFields,
}
import React from 'react'

export const FrabchisingSent = ({ sendAnotherEmail }) => {
  return (
    <div class="msg-send" id="contact_form_send_success">
        <span class="ttl">
            Successfully sent!
        </span>
        <p>
            A bottle with your message in it is already on its way to us. As soon as we get your parchment, we will hurry to answer.
        </p>
        <button onClick={() => sendAnotherEmail(true)} class="btn btn-primary">Send another</button>
    </div>
  )
}

import React from 'react';
import Slider from "react-slick";

import Logo from "../../assets/img/brand-logo.svg";
import ArrowDown from "../../assets/img/arrow-down.svg";
import baner1 from "../../assets/img/slider/banner1.jpg";
import baner2 from "../../assets/img/slider/banner2.jpg";
import baner3 from "../../assets/img/slider/banner3.jpg";
import "./style.css";

const Header = ({ promoText }) => {

    const settings = {
        draggable: false,
        arrows: false,
        dots: false,
        fade: true,
        speed: 2000,
        infinite: true,
        cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
        autoplay: 1,
        autoplaySpeed: 4000,
        pauseOnHover: false
    };

    const handleClick = () => {
        const pageHeight = window.innerHeight;
        window.scrollTo({top: pageHeight, left: 0, behavior: 'smooth'})
    };

    return (
        <header id="main" className="scroll-fullpage" data-hash="a">
            <img src={Logo} alt="Captain Candy" id="brand-logo" />
            <img src={ArrowDown} alt="more" id="arrow-down" onClick={() => handleClick()} />
            <h1 id="motto-main">
                <span id="promo1" className="hidden-xs hidden-sm hidden-sm-plus" style={{ display: "block" }}>
                    · {promoText?.text} ·
                </span>
                <div className="visible-xs">
                    <img src={promoText?.promoTextXs} alt={promoText?.text} id="promo-xs" />
                </div>
                <div className="visible-sm visible-sm-plus">
                    <img src={promoText?.promoText} alt={promoText?.text} id="promo-sm" /> 
                </div>
            </h1>
            <div className="slideshow">
                <Slider {...settings}>
                    <div className="item"><img src={baner1} srcSet={baner1} alt="img" /></div>
                    <div className="item"><img src={baner2} srcSet={baner2} alt="img" /></div>
                    <div className="item"><img src={baner3} srcSet={baner3} alt="img" /></div>
                </Slider>
            </div>
        </header>
    )
};

export default Header;

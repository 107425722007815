// import logoEn from "../img/icons/en.jpg";
// import logoNl from "../imgnl/nl.png";
// import logoDe from "../imgde/de.png";

const firstMenu = {
    en: [
        {
            id: "products",
            path: "/products",
            name: "Products",
        },
        {
            id: "captain",
            path: "/captain-story",
            name: "The Captain’s story",
        },
    ],
};

const secondMenu = {
    en: [
        {
            id: "captain",
            path: "/team",
            name: "Team",
        },
        {
            id: "about",
            path: "/about",
            name: "About us",
        },
        {
            id: "stores",
            path: "/stores",
            name: "Stores",
        },
    ]
};

const footerMenu = {
    en: [
        {
            id: "faq",
            path: "/faq",
            name: "FAQ",
        },
        {
            id: "franchising",
            path: "/franchising",
            name: "Franchising",
        },
        {
            id: "contact",
            path: "/contact",
            name: "Contact",
        },
    ],
};

const allSocial = [
  {
    title: "Czech Republic",
    links: [
      {
        path: "https://www.facebook.com/captaincandy.cz",
        name: "/captaincandy.cz",
        icon: "fb",
        iconName: "facebook"
      },
      {
        path: "https://www.instagram.com/captaincandy.cz",
        name: "/captaincandy.cz",
        icon: "ig",
        iconName: "instagram"
      },
      {
        path : "https://www.tiktok.com/@captaincandy.cz",
        name: "@captaincandy.cz",
        icon: "tik",
        iconName: "tiktok"
      },
      {
        path : "https://www.linkedin.com/company/captain-candy-candy-shop",
        name: "/linkedin",
        icon: "in",
        iconName: "linkedin"
      }
    ]
  },
  {
    title: "Italy",
    links: [
      {
        path: "https://www.facebook.com/captaincandy.it",
        name: "/captaincandy.it",
        icon: "fb",
        iconName: "facebook"
      },
      {
        path: "https://www.instagram.com/captaincandy.it",
        name: "/captaincandy.it",
        icon: "ig",
        iconName: "instagram"
      },
      {
        path : "https://www.tiktok.com/@captaincandyitaly",
        name: "@captaincandyitaly",
        icon: "tik",
        iconName: "tiktok"
      },
      {
        path : "https://www.linkedin.com/company/captain-candy-candy-shop",
        name: "/linkedin",
        icon: "in",
        iconName: "linkedin"
      }
    ]
  },
]
const allSocial2 = [
  {
    title: "Germany",
    links: [
      {
        path: "https://www.facebook.com/captaincandydeutschland",
        name: "/captaincandydeutschland",
        icon: "fb",
        iconName: "facebook"
      },
      {
        path: "https://www.instagram.com/captaincandyde/",
        name: "/captaincandyde",
        icon: "ig",
        iconName: "instagram"
      },
      {
        path: "https://www.tiktok.com/@captaincandy.de",
        name: "@captaincandy.de",
        icon: "tik",
        iconName: "tiktok"
      },
      {
        path: "https://www.linkedin.com/company/captain-candy-candy-shop",
        name: "/linkedin",
        icon: "in",
        iconName: "linkedin"
      }
    ]
  },
  {
    title: "Netherland",
    links: [
      {
        path: "https://www.facebook.com/captaincandynetherlands",
        name: "/captaincandynetherlands",
        icon: "fb",
        iconName: "facebook"
      },
      {
        path: "https://www.instagram.com/captaincandy.nl",
        name: "/captaincandy.nl",
        icon: "ig",
        iconName: "instagram"
      },
      {
        path: "https://www.tiktok.com/@captaincandy.nl",
        name: "@captaincandy.nl",
        icon: "tik",
        iconName: "tiktok"
      },
      {
        path: "https://www.linkedin.com/company/captain-candy-candy-shop",
        name: "/linkedin",
        icon: "in",
        iconName: "linkedin"
      }
    ]
  },
]
export {
    firstMenu,
    secondMenu,
    footerMenu,
    allSocial,
    allSocial2,
};
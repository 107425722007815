import React from "react";
import hpBox from "../img/hp_box_1-min.jpg";
import hpBox1 from "../img/hp_box_2-min.jpg";
import hpBox2 from "../img/hp_box_3-min.jpg";
import hpBox3 from "../img/hp_box_4-min.jpg";

const boxes = {
    title: {
        en: "From Captain Candy’s board",
    },
    en: [
        {
            img: hpBox,
            title: <React.Fragment> Can you smell the sweetness in the air?</React.Fragment>,
            description: "Temptation at its best. Discover this world of sweets with us.",
            link: "More information",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox1,
            title: <React.Fragment>All the places we anchor</React.Fragment>,
            description: "You can now find us in four countries in Czech Republic, Italy, Germany, Netherland!",
            link: "More information",
            href: "/stores"
        },
        {
            img: hpBox2,
            title: <React.Fragment>The end of sour looks!</React.Fragment>,
            description: "Do people give you sour looks? Try offering them our sour jelly beans!",
            link: "More information",
            href: "/captain-story",
            longer: true,
        },
        {
            img: hpBox3,
            title: <React.Fragment>Find out more about what we do</React.Fragment>,
            description: "Discover the journey of a Czech company that brings joy from all over the world.",
            link: "More information",
            href: "/about"
        },
    ],
};

const HpMapData = {
    en: {
        title: "Our store in Europe",
        button: {
            name: "Go to",
        }
    },
};
    
export { boxes, HpMapData };
    
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import headerLogo from '../../assets/img//brand-logo.svg';
import Input from '../Input';
import accountIcon from './account-icon.svg';
import passwordIcon from './password-icon.svg';
import { axiosLink } from '../../axiosLink';
import './login.scss';

function LoginForm() {
  const [userData, setUser] = useState();
  const [sendPasswordForgottenEmail, setSendPasswordForgottenEmail] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const errorResponse = (response) => {
    setErrorMessage(response);
    setTimeout(() => {
      setErrorMessage(null);
    }, 7000);
  };
  const handleChange = (event) => {
    setUser({ ...userData, [event.target.name]: event.target.value });
  };
  const handleLogin = (event) => {
    event.preventDefault();
    const validateEmail = (email) => {
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regexp.test(email);
    };
    const isEmail = validateEmail(userData?.username);
    let data = {};
    if (isEmail) {
      data = {
        email: userData?.username,
        password: userData?.password,
      };
    } else {
      data = {
        email: userData?.username,
        password: userData?.password,
      };
    }
    if (data?.password && data?.email && !isResetPassword) {
      Axios.post(`${axiosLink}/authentication/login`, data)
        .then((response) => {
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            navigate('/dashboard');
          } else {
            errorResponse(response.data?.error);
          }
        })
        .catch((error) => {
          if (error?.response?.data.message) {
            errorResponse(error.response.data.message);
          }
        });
    }
    if (isResetPassword && data?.email) {
      Axios.post(`${axiosLink}/account-security/mail-request`,
        {
          email: userData.username,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.msg) {
            setErrorMessage();
            setSendPasswordForgottenEmail(true);
          }
          if (response.data?.error) {
            setSendPasswordForgottenEmail(false);
            setErrorMessage(response.data.error);
          }
        });
    }
  };
  const resetPassword = () => {
    setIsResetPassword(!isResetPassword);
    setErrorMessage();
    setSendPasswordForgottenEmail(false);
  };

  return (
    <div className='page' id="page-kontakt">
      <div className="login-form">
        <Link to="/" className="login-logo">
          <picture className="he-d-block">
            <source srcSet={headerLogo} />
            <img loading="lazy" src={headerLogo} alt="logo" />
          </picture>
        </Link>
        <form onSubmit={handleLogin} className="he-login-form__card he-max-w-100 he-text-center">
          <h3 className="he-title-third he-fw-bold he-mb-48">{ isResetPassword ? 'Passwort zurücksetzen' : 'Broker Login'}</h3>
          <div className="he-login-form__group he-relative he-mb-40">
            <Input change={handleChange} defaultValue={userData?.username} type="text" name="username" icon={accountIcon} placeholder="Email" />
            {isResetPassword && (<span className="he-absolute he-d-block he-input__error-msg he-parag-small he-fw-medium he-mt-12 he-text-left">{errorMessage}</span>)}
            {sendPasswordForgottenEmail && <span className="he-d-block he-login-form-success he-parag-small he-fw-medium he-mt-12 he-text-left">Check your email with the reset password link!</span>}
          </div>
          {
            !isResetPassword
            && (
              <div className="he-login-form__group he-relative he-mb-40">
                <Input change={handleChange} defaultValue={userData?.password} type="password" name="password" icon={passwordIcon} placeholder="Passwort" />
                <span className="he-absolute he-d-block he-input__error-msg he-parag-small he-fw-medium he-mt-12 he-text-left">{errorMessage}</span>
              </div>
            )
          }
          <div className="he-login-form__group he-flex he-flex--direction-column he-flex--items-center">
            <button className="btn  mr-10 he-button he-button__disabled he-w-sm-100 he-button--primary he-button--large he-color-white he-fw-bold he-mb-24" aria-label="login-button" type="submit" disabled={(!userData?.username || ((!userData?.password) && !isResetPassword)) && true}>
              {
                isResetPassword ? 'Reset' : 'Login'
              }
            </button>
            <button onClick={() => resetPassword()} type="button" className="btn he-login-form__link he-cursor-pointer he-fw-bold he-parag-small he-fw-medium he-text-underline">
              {
                isResetPassword ? 'Login' : 'Change Passwort!'
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;

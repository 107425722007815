import React, { useState, useEffect } from 'react';
import axios from "axios";
import { newsletter } from '../../assets/dummyData/popupNewsletter';
import { validateEmail } from '../../assets/function';
import ModalEmailSuccess from '../ModalEmailSuccess/ModalEmailSuccess';
import { axiosLink } from '../../axiosLink';
import { inputsLabel } from "../../assets/dummyData/franchisingData";
import PdfModal from '../PdfModal/PdfModal';

const DataDetails = ({ data, close }) => {
    const [pdf, setPdf] = useState(false);
    const body = document.body;

    const closeModal = (event) => {
        if (event.target.id === "modal-popup-newsletter" || event.target.id === "modal-popup-newsletter-span") {
            body.classList.remove("modal-open");
            close(null)
        }
    }
    const checkForPopupNewsletter = (event) => {
        if (event) {
            body.classList.add("modal-open");
        } else {
            body.classList.remove("modal-open");
        }
        return '';
    };
    
    useEffect(() => {
        checkForPopupNewsletter(!!data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div id="modal-popup-newsletter" className="modal in fade fade-onload" role="dialog" style={!!data ? { display: "block" } : { display: "none" }} onClick={(e) => closeModal(e)}>
            <PdfModal src={pdf} open={!!pdf} close={(e) => setPdf(e)} />
            <div className="modal-dialog">
                <div className="modal-content on-click">
                    <span className="close" id="modal-popup-newsletter-span" data-dismiss="modal" onClick={(e) => closeModal(e)}></span>
                    <div className="modal-body" style={{ backgroundColor: "white" }}>
                        <button className="subttl" onClick={() => setPdf(data.file_upload)}>View document</button>
                        {data && Object.keys(data).map((key) => (
                            <span key={key}>
                                {!(key === "id" || key === "file_upload" || key === "updatedAt" ||  key === "state") && (
                                    <p className="line-b" style={{ textAlign: "left", borderBottom: "1px solid #eee"}}><b>{inputsLabel[key]}</b> <br /> {data[key]}</p>
                                )}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DataDetails;
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';

const Products = ({ productsItems, title, subTitle, stores }) => {
    const elementsToAnimate = ['title'];
    const { singleRefs } = AnimationEffects(elementsToAnimate, productsItems ,'fadeInY',  0.1, 0.08);

    const { hash } = useLocation();
    useEffect(()=>{
        if (hash) {
            const section = document.querySelector(hash);
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        }
     }, [hash])
  return (
    <>
    {/* // <div id="page-products" className="page"><!-- className="fade-onload" --> */}
        <header>
            <div className="content">
                <h1 className="page-heading">
                    {title}
                </h1>
                <p>
                    {subTitle}
                </p>
            </div>
        </header>

        <section id="products-items">
            <div className="content">
                {productsItems?.map((el, index) => (
                    <div key={el.id}  id={el.id} className="item opacity-0" ref={singleRefs.items[index].ref}>
                        <div className="item-content">
                            <h2 className="ttl">
                                {el.title}
                            </h2>
                            <p>{el.description}</p>
                        </div>
                        <div className="img-wrap visible-xs aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <img src={el.img} srcSet={el.img} alt="Tangy jelly" />
                        </div>
                    </div>
                ))}
            </div>
        </section>
    
        <div className="btn-wrap aos-init" data-aos="fade-up">
            <a href="/stores" className="btn btn-primary">{stores}</a>
        </div>

        <div className="spacer100 visible-xs">
            {/* <!-- when newsletter is hidden --> */}
        </div>   
    </>
  )
}

export default Products;
import React from 'react';
import Franchising from '../components/Franchising/Franchising';
import { franchisingData } from "../assets/dummyData/franchisingData";

const FranchisingPage = () => {
  const lang = "en";
  return (
    <Franchising data={franchisingData[lang]} />
  )
}

export default FranchisingPage;
import React, { useRef } from 'react';
import Slider from "react-slick";
import "./slider.scss";
import NxetSvg from "./Vector.svg";
import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';

export const TeamGroupSlider = ({ team, id, fullWidth, responsive, extraSettings }) => {
    const elementsToAnimate = ['slider'];
    const { singleRefs } = AnimationEffects(elementsToAnimate, team, 'opacity', 0.4, 0.12);
    const slider = useRef();
  
    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    }; 

    const onChangeEl = (event) => {
        let eventnr = (event === 0) ? 1 : event + 1
        const element = document.getElementById(id).querySelector(`[data-index="${eventnr}"]`);
        element?.classList.add("mystyle");
    }
    const beforeChangeEl = (event, slick) => {
        let eventnr = (slick === 0) ? 1 : slick + 1
        const element = document.getElementById(id).querySelector(`[data-index="${eventnr}"]`);
        element?.classList.add("mystyle");
        const removeStyle = document.getElementById(id).querySelector(`.mystyleExtra`);
        if (removeStyle) {
            removeStyle.classList.remove("mystyleExtra");
        }
        const removeStyle1 = document.getElementById(id).querySelector(`[data-index="${eventnr + 1}"]`);
        removeStyle1?.classList.remove("mystyle");
    }

    let settings = {
        infinite: true,
        slidesToShow: 3,
        speed: 1000,
        slidesPerRow: 1,
        arrows: false,
        afterChange: (event, slick, currentSlide, nextSlide) => onChangeEl(event, slick, currentSlide, nextSlide),
        beforeChange: (event, slick, currentSlide, nextSlide) => beforeChangeEl(event, slick, currentSlide, nextSlide),
        responsive: responsive,
    };

    if (extraSettings) {
        settings = { ...settings, centerMode: true,  slidesToShow: 5, } 
        // console.log("settings _____", settings)
        // console.log("extraSettings _____", extraSettings)
    }
    // console.log("settings", extraSettings, settings)





    return (
      <div id={id} className={fullWidth ? "teamImgFull opacity-0" : "teamImg opacity-0"} ref={singleRefs.elements.slider.ref}>
            <Slider {...settings} ref={(c) => (slider.current = c)}>
                {team?.map((el, index) => (
                    <div key={el.img} className={`itms check-slide-${index} ${index === 1 ? " mystyleExtra" : ""}`}>
                        <div className='slider-items-team he-relative'>
                            <div className='slider-item-img he-relative'>
                                <img className='he-relative' src={el.img} alt="Team" />
                                <span className='btn-previous'  onClick={() => previous()}>
                                    <img className='he-relative' src={NxetSvg} alt="Team" />
                                </span>
                                <span className='btn-next'  onClick={() => next()}>
                                    <img className='he-relative' src={NxetSvg} alt="Team" />
                                </span>
                            </div>
                        </div>
                    </div>
                ))} 
            </Slider>
          {/* {team?.map((el, index) => (
              <img key={el.img} className='team-img opacity-0' src={el.img} alt="Team CZ" ref={singleRefs.items[index].ref} />
          ))} */}
      </div>
    )
};

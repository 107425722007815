import React, { useState, useEffect } from 'react';
import '../ModalGoTo/modal.scss';
import { axiosLink } from '../../axiosLink';

const PdfModal = ({ open, close, src }) => {
    const [openModal, setOpenModal] = useState(false);
    const body = document.body;

    const closeModal = (event) => {
        if (event.target.id === "modal-email-success" || event.target.id === "modal-email-success-span") {
            body.classList.remove("modal-open");
            setOpenModal(false);
            close(null)
        }
    }

    useEffect(() => {
        if (open) {
            setOpenModal(true);
        }
    }, [open]);

    return (
        <div id="modal-email-success" className="modal active in fade fade-onload" style={openModal ? { display: "block" } : { display: "none" }}  role="dialog" onClick={(e) => closeModal(e)}>
            <div className="modal-dialog map">
                <div className="modal-content">
                    <span className="close" id="modal-email-success-span" data-dismiss="modal" onClick={(e) => closeModal(e)}></span>
                    <div className="modal-body content-modal">
                        <div className="content">
                           <div className="inner">
                                {src && <div style={{ height: "80vh" }}>
                                    <iframe id="test" width='100%' height='100%' src={`${axiosLink}/${src}`}></iframe>
                                </div>}
                                <br />
                                <button
                                    onClick={() => {setOpenModal(false); close(null)}}
                                    className="btn btn-map maps-stay btn-primary hide-after"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PdfModal;

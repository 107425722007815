import React from 'react';
// import About from '../components/About/About';
import Helmet from "../components/Helmet";
import logo from "../assets/img/prodejny_map.png"
import AboutTeam from '../components/About/AboutTeam';

const AboutPage = () => {
    return (
        <section className='page'>
            <Helmet
                title="About Team | Captain Candy"
                metaDesc="There are thousands of flavours in the world. Choose your favourite with us. We do everything we can to offer you the best quality candies from the best suppliers to satisfy even the most demanding sweet tooth."
                twitterImage={logo}
                opengraphImage={logo}
            />
            <div id="page-team">
                <AboutTeam />
            </div>
        </section>
    )
}

export default AboutPage;
import React from 'react';
import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';

const HpAbout = ({ data }) => {
    const elementsToAnimate = ['title', 'description', "buttonName", 'title1', 'description1', "buttonName1"];
    const { singleRefs } = AnimationEffects(elementsToAnimate, data ,'fadeInY', 0.04, 0.22);
    return (
        <section id="hp_about" className="fade-onload">
            <div className="content">
                <div className="items-wrap">
                    {data.map((el, index) => (
                        <div className="item scroll-fullpage" key={el.id}>
                            <div className="pattern" />
                            <div className="col-img">
                                <div className="img-wrap">
                                    <a href="/products">
                                        <img src={el.img} alt="Stores" />
                                    </a>
                                </div>
                            </div>
                            <div className="item-content opacity-0" ref={singleRefs.items[index].ref}>
                                <div data-aos="fade-up">
                                    <a href="/products" className="no-effect">
                                        <h2 className="section-heading">
                                            {el.title}
                                        </h2>
                                    </a>
                                    <p>{el.description}</p>
                                </div>
                                <div data-aos="fade-up">
                                    <a href={el.link || "/products"} className="btn btn-primary btn-arrow">{el.buttonName}</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
  )
}

export default HpAbout;

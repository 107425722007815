import React, { useEffect, useState }from 'react';
import axios from "axios";
import { axiosLink } from '../../axiosLink';
import Token from "../../Utils/Token"
import { useNavigate } from 'react-router-dom';
import LoaderForm from '../LoaderForm/LoaderForm';
import AddNewViewer from '../AddNewViewer/AddNewViewer';

const PdfView = () => {
    const [data, setData] = useState();
    const [editMode, setEditMode] = useState(false);
    const [hideModal, setHideModal] = useState(true);
    const { activeToken } = Token();
    const navigate = useNavigate();

    const changeStatus = (e) => {
        setEditMode(true)
        const updateData = { ...e, status: !e.status}
        axios.put(
            `${axiosLink}/pdfview/update`,
            updateData,
            {
                headers: {
                    authorization: activeToken,
                    'Content-Type': 'application/json',
                },
            },
        ).then((res) => {
            if (res.data) {
                getData();
            } 
        })
    }
    const deleteUser = (e) => {
        setEditMode(true);
        axios.delete(
            `${axiosLink}/pdfview/delete?id=${e}`,
            {
                headers: {
                authorization: activeToken,
                'Content-Type': 'application/json',
                },
            }
        ).then((res) => {
            if(res.data?.msg) {
                getData();
                setEditMode(false)
            } else {
                navigate("/")
            }
        })
    }

    const getData = () => {
        axios.get(
            `${axiosLink}/pdfview/all`,
            {
                headers: {
                authorization: activeToken,
                'Content-Type': 'application/json',
                },
            }
        ).then((res) => {
            if(!res.data?.errMsg) {
                setData(res?.data);
                setEditMode(false)
            } else {
                navigate("/")
            }
        })
    }
    
    useEffect(() => {
        getData();
    }, []);
    return (
        <div className='pdf-dashboard'>
            <button onClick={() => setHideModal(false)} className='button-add-new'>Add new viewer!</button>
            {editMode && <LoaderForm />}
            <AddNewViewer hideModal={(e) => { setHideModal(e); getData(); } } show={hideModal} />
            <table className='w-100 button-dashboard'>
                <tbody>
                <tr>
                    <th>E-mail</th>
                    <th>Status</th>
                    <th>Created date</th>
                </tr>
                {data && data?.map((el) => (
                    <tr key={el?.id} style={{ borderBottom: "1pt solid black" }}>
                        <td>{el?.email}</td>
                        <td>
                            <div className="toggleButton" onClick={() => changeStatus(el)}>
                                <input className="toggleButton__checkbox" type="checkbox" id={el?.id} checked={el?.status} readOnly />
                                <label className="toggleButton__body" htmlFor={el?.id}></label>
                            </div>
                        </td>
                        <td>
                            <div className='wrapper-box'>
                                {el?.createdAt}
                                <svg onClick={() => deleteUser(el.id)} width="24px" height="24px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M352 192V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64H96a32 32 0 0 1 0-64h256zm64 0h192v-64H416v64zM192 960a32 32 0 0 1-32-32V256h704v672a32 32 0 0 1-32 32H192zm224-192a32 32 0 0 0 32-32V416a32 32 0 0 0-64 0v320a32 32 0 0 0 32 32zm192 0a32 32 0 0 0 32-32V416a32 32 0 0 0-64 0v320a32 32 0 0 0 32 32z"/></svg>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
  )
}

export default PdfView;
import React from 'react';
// import { useState, useEffect } from 'react';
// import Logo from "../../assets/img/brand-logo.svg";
import "./loader.css";

const Loader = ({ showLoader }) => {
  // const [showloader, setShowLoader] = useState(false);
  // useEffect(() => {
  //       // setTimeout(() => {
  //       //   setShowLoader(showLoader)
  //       // }, 500);
  //       // eventListener();
  // }, [showLoader])
  return (
    <div id="loader" className="se-pre-con" style={showLoader ? { display: "none" } : {}}>
        <div className="vertical-centered-box">
            <div className="loader-circle"></div>
            <div className="loader-line-mask">
                <div className="loader-line"></div>
            </div>
            <div id="prism-logo">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="120px" height="120px" viewBox="95 80 150 150">
              <g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_1_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_2_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_2_)" fill="#FDFDFD" d="M222.428,125.871c-0.192-0.695-0.88-0.995-1.501-1.038
                      c-0.626-0.073-1.173,0.078-1.173,0.078s0.251-0.028,0.545,0.07c0.294,0.108,0.623,0.334,0.761,0.811
                      c0.248,0.675,0.859,0.949,1.419,1.042c0.546,0.126,1.059,0.081,1.059,0.081s-0.229-0.018-0.478-0.158
                      C222.809,126.616,222.533,126.346,222.428,125.871"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_3_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_4_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_4_)" fill="#FDFDFD" d="M219.853,125.792c-0.278-0.669-0.983-0.882-1.6-0.864
                      c-0.309,0.001-0.598,0.045-0.808,0.088c-0.209,0.047-0.341,0.098-0.341,0.098s0.245-0.056,0.547,0.026
                      c0.3,0.076,0.646,0.268,0.834,0.727c0.317,0.647,0.965,0.846,1.539,0.875c0.288,0.007,0.556,0.009,0.754-0.017
                      c0.199-0.02,0.329-0.047,0.329-0.047S220.185,126.71,219.853,125.792"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_5_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_6_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_6_)" fill="#FDFDFD" d="M217.283,125.984c-0.338-0.641-1.046-0.78-1.658-0.731
                      c-0.61,0.057-1.123,0.271-1.123,0.271s0.978-0.237,1.424,0.646c0.371,0.639,1.037,0.773,1.618,0.744
                      c0.579-0.006,1.082-0.168,1.082-0.168S217.698,126.879,217.283,125.984"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_7_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_8_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_8_)" fill="#FDFDFD" d="M214.739,126.382c-0.388-0.635-1.093-0.706-1.698-0.624
                      c-0.615,0.084-1.088,0.306-1.088,0.306s0.226-0.063,0.525-0.022c0.303,0.04,0.668,0.186,0.919,0.617
                      c0.404,0.616,1.081,0.679,1.663,0.638c0.584-0.053,1.074-0.262,1.074-0.262S215.212,127.26,214.739,126.382"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_9_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_10_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_10_)" fill="#FDFDFD" d="M212.216,126.914c-0.41-0.615-1.082-0.693-1.659-0.634
                      c-0.578,0.037-1.068,0.234-1.068,0.234s0.915-0.199,1.386,0.682c0.38,0.618,1.092,0.695,1.704,0.618
                      c0.604-0.077,1.077-0.305,1.077-0.305S212.736,127.774,212.216,126.914"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_11_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_12_">
                      <use   overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_12_)" fill="#FDFDFD" d="M209.683,127.38c-0.357-0.625-1.015-0.779-1.589-0.783
                      c-0.287,0.001-0.557,0.047-0.753,0.069c-0.196,0.028-0.324,0.062-0.324,0.062s0.23-0.021,0.508,0.075
                      c0.28,0.086,0.604,0.272,0.797,0.732c0.319,0.658,1.033,0.826,1.648,0.768c0.31-0.021,0.595-0.083,0.803-0.139
                      c0.209-0.053,0.336-0.113,0.336-0.113s-0.245,0.063-0.548,0.003C210.255,127.997,209.898,127.827,209.683,127.38"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_13_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_14_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_14_)" fill="#FDFDFD" d="M207.117,127.611c-0.29-0.681-0.921-0.864-1.481-0.958
                      c-0.281-0.04-0.55-0.028-0.748-0.021c-0.195,0.001-0.323,0.016-0.323,0.016s0.228,0.021,0.488,0.138
                      c0.262,0.114,0.558,0.349,0.695,0.829c0.237,0.712,0.935,0.918,1.56,0.948c0.314,0.024,0.606-0.02,0.818-0.066
                      c0.213-0.042,0.345-0.086,0.345-0.086s-0.25,0.04-0.548-0.038C207.627,128.307,207.282,128.076,207.117,127.611"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_15_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_16_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_16_)" fill="#FDFDFD" d="M204.542,127.537c-0.193-0.717-0.783-0.984-1.313-1.164
                      c-0.54-0.141-1.038-0.182-1.038-0.182s0.222,0.048,0.449,0.222c0.235,0.157,0.486,0.43,0.547,0.927
                      c0.122,0.742,0.795,1.047,1.418,1.167c0.313,0.066,0.61,0.043,0.828,0.035c0.219-0.014,0.359-0.038,0.359-0.038
                      s-0.258,0.003-0.545-0.113C204.959,128.284,204.643,128.035,204.542,127.537"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_17_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_18_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_18_)" fill="#FDFDFD" d="M202.014,127.063c-0.032-0.367-0.181-0.644-0.38-0.856
                      c-0.192-0.232-0.438-0.4-0.685-0.521c-0.247-0.122-0.476-0.235-0.66-0.293c-0.182-0.062-0.307-0.089-0.307-0.089
                      s0.21,0.084,0.399,0.292c0.186,0.214,0.371,0.54,0.339,1.026c-0.007,0.363,0.137,0.663,0.357,0.905
                      c0.225,0.238,0.533,0.385,0.835,0.502c0.299,0.123,0.599,0.159,0.822,0.18c0.22,0.02,0.365,0.018,0.365,0.018
                      s-0.261-0.036-0.53-0.196C202.296,127.88,202.03,127.556,202.014,127.063"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_19_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_20_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_20_)" fill="#FDFDFD" d="M199.623,126.114c0.042-0.357-0.029-0.678-0.174-0.933
                      c-0.146-0.249-0.345-0.468-0.546-0.654c-0.21-0.174-0.419-0.319-0.574-0.431c-0.157-0.105-0.271-0.162-0.271-0.162
                      s0.179,0.133,0.311,0.383c0.129,0.253,0.249,0.589,0.093,1.071c-0.195,0.72,0.307,1.296,0.858,1.641
                      c0.279,0.164,0.561,0.287,0.773,0.358c0.219,0.059,0.361,0.083,0.361,0.083s-0.063-0.021-0.155-0.066
                      c-0.092-0.045-0.216-0.114-0.33-0.231C199.741,126.946,199.535,126.598,199.623,126.114"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_21_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_22_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_22_)" fill="#FDFDFD" d="M197.52,124.637c0.123-0.337,0.116-0.652,0.046-0.942
                      c-0.076-0.284-0.226-0.534-0.376-0.765c-0.161-0.222-0.335-0.413-0.461-0.558c-0.13-0.14-0.228-0.222-0.228-0.222
                      s0.143,0.172,0.215,0.447c0.069,0.275,0.102,0.635-0.159,1.065c-0.178,0.329-0.183,0.658-0.081,0.966
                      c0.094,0.314,0.294,0.604,0.529,0.83c0.225,0.236,0.475,0.419,0.676,0.526c0.197,0.114,0.331,0.173,0.331,0.173
                      s-0.226-0.144-0.407-0.405C197.425,125.49,197.321,125.083,197.52,124.637"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_23_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_24_">
                      <use   overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_24_)" fill="#FDFDFD" d="M195.815,122.711c0.402-0.607,0.263-1.232,0.05-1.746
                      c-0.213-0.51-0.519-0.917-0.519-0.917s0.104,0.201,0.126,0.485c0.013,0.288-0.036,0.652-0.379,1.014
                      c-0.48,0.557-0.31,1.296,0.034,1.846c0.162,0.279,0.364,0.512,0.531,0.664c0.166,0.158,0.279,0.246,0.279,0.246
                      s-0.182-0.193-0.297-0.489C195.53,123.519,195.519,123.102,195.815,122.711"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_25_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_26_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_26_)" fill="#FDFDFD" d="M194.559,120.466c0.524-0.518,0.499-1.166,0.378-1.716
                      c-0.122-0.546-0.354-1.004-0.354-1.004s0.072,0.219,0.04,0.505c-0.024,0.285-0.147,0.641-0.551,0.935
                      c-0.566,0.46-0.539,1.208-0.32,1.81c0.214,0.606,0.603,1.045,0.603,1.045s-0.142-0.222-0.188-0.534
                      C194.132,121.19,194.187,120.792,194.559,120.466"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_27_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_28_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_28_)" fill="#FDFDFD" d="M193.738,118.026c0.598-0.422,0.678-1.073,0.647-1.636
                      c-0.036-0.562-0.193-1.045-0.193-1.045s0.034,0.225-0.041,0.501c-0.072,0.277-0.245,0.611-0.688,0.837
                      c-0.638,0.367-0.729,1.104-0.612,1.728c0.107,0.627,0.403,1.125,0.403,1.125s-0.096-0.242-0.082-0.556
                      C193.181,118.668,193.319,118.286,193.738,118.026"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_29_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_30_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_30_)" fill="#FDFDFD" d="M217.329,132.002c-0.732-0.149-1.272,0.384-1.588,0.968
                      c-0.143,0.301-0.246,0.599-0.297,0.827c-0.038,0.235-0.046,0.388-0.046,0.388s0.012-0.068,0.049-0.17
                      c0.039-0.102,0.09-0.244,0.2-0.367c0.208-0.256,0.542-0.497,1.029-0.442c0.755,0.046,1.182-0.392,1.542-0.822
                      c0.32-0.445,0.544-0.906,0.544-0.906s-0.125,0.192-0.371,0.347C218.159,131.985,217.819,132.122,217.329,132.002"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_31_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_32_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_32_)" fill="#FDFDFD" d="M216.273,134.342c-0.735,0.059-1.136,0.771-1.182,1.487
                      c-0.014,0.178-0.014,0.35-0.007,0.509c0.02,0.16,0.056,0.305,0.083,0.426c0.062,0.244,0.118,0.394,0.118,0.394
                      s-0.064-0.288-0.011-0.63c0.053-0.34,0.307-0.71,0.771-0.839c0.693-0.241,0.979-0.738,1.179-1.219
                      c0.087-0.242,0.187-0.475,0.222-0.656c0.04-0.184,0.066-0.303,0.066-0.303s-0.025,0.049-0.063,0.127
                      c-0.038,0.078-0.101,0.178-0.194,0.278c-0.094,0.1-0.221,0.2-0.387,0.276C216.714,134.271,216.521,134.332,216.273,134.342"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_33_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_34_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_34_)" fill="#FDFDFD" d="M216.138,136.89c-0.317,0.199-0.449,0.5-0.495,0.856
                      c-0.02,0.343,0.071,0.734,0.244,1.047c0.089,0.158,0.185,0.307,0.278,0.438c0.1,0.125,0.208,0.225,0.298,0.312
                      c0.18,0.169,0.308,0.263,0.308,0.263s-0.052-0.047-0.121-0.14c-0.067-0.09-0.154-0.223-0.223-0.379
                      c-0.134-0.315-0.132-0.767,0.192-1.12c0.237-0.287,0.356-0.577,0.393-0.833c0.084-0.277,0.034-0.515,0.045-0.759
                      c-0.003-0.121-0.019-0.232-0.04-0.337c-0.01-0.106-0.016-0.206-0.03-0.29c-0.024-0.169-0.055-0.28-0.055-0.28
                      s0.018,0.198-0.086,0.438C216.74,136.345,216.588,136.635,216.138,136.89"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_35_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_36_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_36_)" fill="#FDFDFD" d="M217.347,139.126c-0.157,0.333-0.12,0.662,0.019,0.983
                      c0.153,0.296,0.407,0.582,0.692,0.768c0.283,0.193,0.575,0.334,0.802,0.396c0.226,0.073,0.373,0.104,0.373,0.104
                      s-0.261-0.096-0.503-0.317c-0.238-0.226-0.435-0.608-0.316-1.079c0.07-0.363,0.028-0.677-0.079-0.929
                      c-0.083-0.276-0.251-0.481-0.381-0.686c-0.068-0.099-0.146-0.188-0.22-0.266c-0.064-0.084-0.122-0.162-0.178-0.223
                      c-0.113-0.124-0.196-0.199-0.193-0.201c-0.009,0.005,0.119,0.152,0.157,0.406C217.557,138.34,217.592,138.674,217.347,139.126"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_37_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_38_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_38_)" fill="#FDFDFD" d="M219.491,140.524c-0.023,0.738,0.624,1.189,1.265,1.375
                      c0.32,0.082,0.633,0.112,0.861,0.12c0.23-0.005,0.379-0.024,0.379-0.024s-0.066-0.001-0.173-0.019
                      c-0.103-0.017-0.246-0.047-0.391-0.126c-0.289-0.151-0.594-0.416-0.633-0.923c-0.115-0.726-0.618-1.08-1.07-1.342
                      c-0.231-0.12-0.451-0.217-0.616-0.288c-0.17-0.061-0.28-0.102-0.28-0.102s0.042,0.029,0.113,0.075
                      c0.068,0.048,0.156,0.123,0.242,0.229C219.347,139.72,219.533,140.015,219.491,140.524"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_39_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_40_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_40_)" fill="#FDFDFD" d="M221.99,141.106c0.197,0.708,0.934,0.972,1.592,0.961
                      c0.33,0.007,0.641-0.052,0.86-0.118c0.219-0.059,0.356-0.111,0.356-0.111s-0.261,0.058-0.579,0.004
                      c-0.316-0.046-0.685-0.263-0.86-0.718c-0.306-0.66-0.896-0.877-1.423-0.982c-0.264-0.046-0.51-0.083-0.701-0.083
                      c-0.186-0.008-0.307-0.014-0.307-0.014s0.211,0.045,0.449,0.179C221.612,140.372,221.876,140.63,221.99,141.106"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_41_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_42_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_42_)" fill="#FDFDFD" d="M224.557,140.98c0.378,0.628,1.156,0.676,1.798,0.491
                      c0.319-0.092,0.6-0.248,0.801-0.358c0.199-0.122,0.31-0.226,0.31-0.226s-0.229,0.159-0.558,0.181
                      c-0.326,0.035-0.727-0.036-1.03-0.444c-0.479-0.552-1.096-0.594-1.632-0.562c-0.27,0.02-0.516,0.077-0.701,0.106
                      c-0.185,0.037-0.302,0.078-0.302,0.078s0.216-0.034,0.483,0.05C223.993,140.374,224.317,140.535,224.557,140.98"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_43_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_44_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_44_)" fill="#FDFDFD" d="M226.983,140.143c0.271,0.235,0.604,0.325,0.935,0.289
                      c0.321-0.053,0.672-0.175,0.938-0.384c0.271-0.201,0.51-0.417,0.647-0.613c0.142-0.189,0.223-0.317,0.223-0.317
                      s-0.177,0.213-0.468,0.368c-0.302,0.14-0.719,0.194-1.123-0.08c-0.635-0.375-1.223-0.238-1.714-0.044
                      c-0.25,0.09-0.471,0.199-0.63,0.299c-0.167,0.083-0.269,0.149-0.269,0.149s0.05-0.022,0.136-0.041
                      c0.087-0.016,0.203-0.037,0.339-0.044C226.268,139.715,226.633,139.807,226.983,140.143"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_45_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_46_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_46_)" fill="#FDFDFD" d="M229.014,138.58c0.348,0.14,0.685,0.101,0.976-0.058
                      c0.149-0.076,0.292-0.176,0.424-0.292c0.119-0.124,0.226-0.261,0.322-0.401c0.196-0.269,0.332-0.592,0.397-0.829
                      c0.063-0.234,0.086-0.388,0.086-0.388s-0.074,0.272-0.302,0.533c-0.225,0.272-0.61,0.447-1.089,0.338
                      c-0.355-0.061-0.655,0.003-0.929,0.105c-0.132,0.053-0.251,0.125-0.359,0.206c-0.117,0.074-0.227,0.152-0.325,0.238
                      c-0.185,0.179-0.36,0.345-0.482,0.479c-0.123,0.134-0.193,0.235-0.193,0.235s0.151-0.149,0.409-0.234
                      C228.218,138.438,228.554,138.368,229.014,138.58"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_47_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_48_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_48_)" fill="#FDFDFD" d="M230.355,136.399c0.733-0.016,1.161-0.7,1.292-1.351
                      c0.143-0.656,0.015-1.248,0.015-1.248s0.017,0.271-0.107,0.572c-0.119,0.303-0.386,0.623-0.877,0.697
                      c-0.715,0.161-1.044,0.688-1.27,1.134c-0.102,0.225-0.197,0.437-0.266,0.593c-0.07,0.167-0.104,0.283-0.104,0.283
                      s0.094-0.189,0.312-0.356C229.552,136.565,229.857,136.392,230.355,136.399"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_49_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_50_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_50_)" fill="#FDFDFD" d="M230.778,133.868c0.685-0.248,0.899-1.005,0.83-1.672
                      c-0.062-0.672-0.365-1.208-0.365-1.208s0.1,0.259,0.078,0.585c-0.034,0.329-0.179,0.711-0.637,0.93
                      c-0.628,0.363-0.8,0.957-0.864,1.487c-0.065,0.526-0.028,1.01-0.028,1.01s0.021-0.216,0.146-0.459
                      C230.068,134.296,230.302,134.014,230.778,133.868"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_51_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_52_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_52_)" fill="#FDFDFD" d="M230.421,131.326c0.591-0.449,0.528-1.245,0.238-1.865
                      c-0.149-0.306-0.332-0.573-0.499-0.744c-0.158-0.178-0.271-0.279-0.271-0.279s0.178,0.215,0.28,0.529
                      c0.092,0.318,0.075,0.742-0.268,1.093c-0.485,0.563-0.438,1.153-0.356,1.675c0.099,0.509,0.273,0.959,0.273,0.959
                      s-0.035-0.213,0.007-0.479C229.882,131.945,230.012,131.613,230.421,131.326"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_53_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_54_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_54_)" fill="#FDFDFD" d="M229.24,129.051c0.386-0.627,0.036-1.353-0.455-1.823
                      c-0.497-0.47-1.075-0.664-1.075-0.664s0.241,0.127,0.443,0.384c0.188,0.275,0.338,0.656,0.139,1.116
                      c-0.124,0.351-0.119,0.663-0.046,0.933c0.048,0.292,0.199,0.52,0.318,0.75c0.139,0.217,0.28,0.411,0.389,0.556
                      c0.118,0.139,0.192,0.23,0.192,0.23s-0.023-0.047-0.06-0.124c-0.036-0.076-0.074-0.185-0.095-0.319
                      C228.961,129.817,228.952,129.473,229.24,129.051"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_55_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_56_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_56_)" fill="#FDFDFD" d="M227.314,127.361c0.146-0.726-0.411-1.288-1.006-1.565
                      c-0.293-0.147-0.588-0.238-0.812-0.272c-0.224-0.042-0.368-0.054-0.368-0.054s0.259,0.061,0.517,0.247
                      c0.254,0.188,0.492,0.535,0.449,1.023c-0.004,0.358,0.103,0.659,0.258,0.906c0.165,0.234,0.368,0.436,0.575,0.601
                      c0.22,0.149,0.409,0.299,0.572,0.386c0.16,0.09,0.265,0.148,0.265,0.148s-0.042-0.035-0.1-0.098
                      c-0.062-0.057-0.14-0.145-0.209-0.261C227.324,128.178,227.198,127.844,227.314,127.361"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_57_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_58_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_58_)" fill="#FDFDFD" d="M225.399,127.324c-0.202-0.198-0.43-0.475-0.442-0.981
                      c-0.03-0.39-0.225-0.691-0.496-0.892c-0.187-0.144-0.411-0.256-0.639-0.336c-0.228-0.066-0.46-0.106-0.666-0.128
                      c-0.41-0.052-0.718-0.005-0.718-0.005s0.259-0.003,0.542,0.148c0.28,0.145,0.579,0.411,0.645,0.898
                      c0.076,0.38,0.266,0.664,0.504,0.864c0.339,0.265,0.74,0.455,1.088,0.538c0.33,0.116,0.601,0.15,0.601,0.15
                      S225.604,127.517,225.399,127.324"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_59_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_60_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_60_)" fill="#FDFDFD" d="M116.132,125.871c0.19-0.695,0.879-0.995,1.5-1.038
                      c0.625-0.073,1.172,0.078,1.172,0.078s-0.251-0.028-0.545,0.07c-0.293,0.108-0.623,0.334-0.76,0.811
                      c-0.25,0.675-0.86,0.949-1.417,1.042c-0.546,0.126-1.06,0.081-1.06,0.081s0.229-0.018,0.478-0.158
                      C115.749,126.616,116.024,126.346,116.132,125.871"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_61_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_62_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_62_)" fill="#FDFDFD" d="M118.706,125.792c0.278-0.669,0.983-0.882,1.599-0.864
                      c0.31,0.001,0.598,0.045,0.808,0.088c0.21,0.047,0.34,0.098,0.34,0.098s-0.245-0.056-0.546,0.026
                      c-0.299,0.076-0.646,0.268-0.833,0.727c-0.318,0.647-0.966,0.846-1.54,0.875c-0.288,0.007-0.555,0.009-0.755-0.017
                      c-0.198-0.02-0.327-0.047-0.327-0.047S118.374,126.71,118.706,125.792"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_63_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_64_">
                      <use   overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_64_)" fill="#FDFDFD" d="M121.275,125.984c0.338-0.641,1.047-0.78,1.659-0.731
                      c0.609,0.057,1.123,0.271,1.123,0.271s-0.979-0.237-1.425,0.646c-0.369,0.639-1.036,0.773-1.617,0.744
                      c-0.58-0.006-1.083-0.168-1.083-0.168S120.862,126.879,121.275,125.984"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_65_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_66_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_66_)" fill="#FDFDFD" d="M123.821,126.382c0.387-0.635,1.093-0.706,1.698-0.624
                      c0.615,0.084,1.088,0.306,1.088,0.306s-0.225-0.063-0.526-0.022c-0.303,0.04-0.669,0.186-0.917,0.617
                      c-0.405,0.616-1.082,0.679-1.665,0.638c-0.583-0.053-1.075-0.262-1.075-0.262S123.346,127.26,123.821,126.382"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_67_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_68_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_68_)" fill="#FDFDFD" d="M126.343,126.914c0.409-0.615,1.082-0.693,1.658-0.634
                      c0.578,0.037,1.068,0.234,1.068,0.234s-0.915-0.199-1.385,0.682c-0.38,0.618-1.093,0.695-1.705,0.618
                      c-0.604-0.077-1.075-0.305-1.075-0.305S125.822,127.774,126.343,126.914"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_69_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_70_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_70_)" fill="#FDFDFD" d="M128.876,127.38c0.356-0.625,1.016-0.779,1.589-0.783
                      c0.288,0.001,0.556,0.047,0.752,0.069c0.197,0.028,0.324,0.062,0.324,0.062s-0.233-0.021-0.51,0.075
                      c-0.277,0.086-0.603,0.272-0.795,0.732c-0.319,0.658-1.035,0.826-1.649,0.768c-0.308-0.021-0.595-0.083-0.802-0.139
                      c-0.208-0.053-0.336-0.113-0.336-0.113s0.244,0.063,0.549,0.003C128.303,127.997,128.661,127.827,128.876,127.38"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_71_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_72_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_72_)" fill="#FDFDFD" d="M131.442,127.611c0.289-0.681,0.921-0.864,1.481-0.958
                      c0.281-0.04,0.55-0.028,0.748-0.021c0.194,0.001,0.323,0.016,0.323,0.016s-0.228,0.021-0.488,0.138
                      c-0.261,0.114-0.557,0.349-0.694,0.829c-0.238,0.712-0.936,0.918-1.561,0.948c-0.314,0.024-0.605-0.02-0.817-0.066
                      c-0.213-0.042-0.348-0.086-0.348-0.086s0.252,0.04,0.549-0.038C130.931,128.307,131.276,128.076,131.442,127.611"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_73_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_74_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_74_)" fill="#FDFDFD" d="M134.016,127.537c0.193-0.717,0.784-0.984,1.313-1.164
                      c0.54-0.141,1.038-0.182,1.038-0.182s-0.221,0.048-0.449,0.222c-0.235,0.157-0.487,0.43-0.546,0.927
                      c-0.123,0.742-0.793,1.047-1.418,1.167c-0.313,0.066-0.61,0.043-0.828,0.035c-0.221-0.014-0.359-0.038-0.359-0.038
                      s0.257,0.003,0.545-0.113C133.599,128.284,133.914,128.035,134.016,127.537"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_75_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_76_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_76_)" fill="#FDFDFD" d="M136.545,127.063c0.033-0.367,0.181-0.644,0.38-0.856
                      c0.192-0.232,0.437-0.4,0.685-0.521c0.247-0.122,0.476-0.235,0.661-0.293c0.181-0.062,0.306-0.089,0.306-0.089
                      s-0.208,0.084-0.398,0.292c-0.187,0.214-0.371,0.54-0.338,1.026c0.007,0.363-0.137,0.663-0.36,0.905
                      c-0.223,0.238-0.531,0.385-0.831,0.502c-0.3,0.123-0.601,0.159-0.822,0.18c-0.222,0.02-0.365,0.018-0.365,0.018
                      s0.257-0.036,0.528-0.196C136.262,127.88,136.529,127.556,136.545,127.063"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_77_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_78_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_78_)" fill="#FDFDFD" d="M138.935,126.114c-0.042-0.357,0.03-0.678,0.173-0.933
                      c0.147-0.249,0.344-0.468,0.546-0.654c0.209-0.174,0.419-0.319,0.573-0.431c0.158-0.105,0.271-0.162,0.271-0.162
                      s-0.178,0.133-0.308,0.383c-0.129,0.253-0.249,0.589-0.095,1.071c0.196,0.72-0.305,1.296-0.856,1.641
                      c-0.28,0.164-0.562,0.287-0.775,0.358c-0.218,0.059-0.362,0.083-0.362,0.083s0.063-0.021,0.156-0.066
                      c0.092-0.045,0.217-0.114,0.33-0.231C138.816,126.946,139.024,126.598,138.935,126.114"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_79_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_80_">
                      <use overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_80_)" fill="#FDFDFD" d="M141.039,124.637c-0.124-0.337-0.117-0.652-0.047-0.942
                      c0.077-0.284,0.227-0.534,0.376-0.765c0.161-0.222,0.334-0.413,0.461-0.558c0.131-0.14,0.228-0.222,0.228-0.222
                      s-0.143,0.172-0.215,0.447c-0.071,0.275-0.103,0.635,0.159,1.065c0.179,0.329,0.183,0.658,0.081,0.966
                      c-0.094,0.314-0.293,0.604-0.529,0.83c-0.226,0.236-0.476,0.419-0.676,0.526c-0.197,0.114-0.332,0.173-0.332,0.173
                      s0.225-0.144,0.407-0.405C141.133,125.49,141.238,125.083,141.039,124.637"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_81_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_82_">
                      <use  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_82_)" fill="#FDFDFD" d="M142.743,122.711c-0.403-0.607-0.26-1.232-0.05-1.746
                      c0.214-0.51,0.52-0.917,0.52-0.917s-0.105,0.201-0.126,0.485c-0.014,0.288,0.036,0.652,0.378,1.014
                      c0.48,0.557,0.311,1.296-0.034,1.846c-0.164,0.279-0.365,0.512-0.533,0.664c-0.164,0.158-0.278,0.246-0.278,0.246
                      s0.183-0.193,0.297-0.489C143.031,123.519,143.039,123.102,142.743,122.711"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_83_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_84_">
                      <use xlinkHref="#SVGID_83_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_84_)" fill="#FDFDFD" d="M143.999,120.466c-0.523-0.518-0.496-1.166-0.377-1.716
                      c0.123-0.546,0.355-1.004,0.355-1.004s-0.073,0.219-0.041,0.505c0.025,0.285,0.148,0.641,0.55,0.935
                      c0.568,0.46,0.541,1.208,0.322,1.81c-0.215,0.606-0.604,1.045-0.604,1.045s0.141-0.222,0.188-0.534
                      C144.427,121.19,144.372,120.792,143.999,120.466"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_85_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_86_">
                      <use xlinkHref="#SVGID_85_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_86_)" fill="#FDFDFD" d="M144.821,118.026c-0.598-0.422-0.679-1.073-0.646-1.636
                      c0.036-0.562,0.194-1.045,0.194-1.045s-0.033,0.225,0.04,0.501c0.072,0.277,0.246,0.611,0.688,0.837
                      c0.637,0.367,0.729,1.104,0.612,1.728c-0.106,0.627-0.404,1.125-0.404,1.125s0.097-0.242,0.083-0.556
                      C145.379,118.668,145.239,118.286,144.821,118.026"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_87_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_88_">
                      <use xlinkHref="#SVGID_87_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_88_)" fill="#FDFDFD" d="M121.228,132.002c0.734-0.149,1.273,0.384,1.589,0.968
                      c0.143,0.301,0.247,0.599,0.298,0.827c0.038,0.235,0.047,0.388,0.047,0.388s-0.014-0.068-0.051-0.17
                      c-0.039-0.102-0.09-0.244-0.2-0.367c-0.208-0.256-0.542-0.497-1.028-0.442c-0.756,0.046-1.183-0.392-1.544-0.822
                      c-0.319-0.445-0.542-0.906-0.542-0.906s0.125,0.192,0.37,0.347C120.399,131.985,120.74,132.122,121.228,132.002"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_89_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_90_">
                      <use xlinkHref="#SVGID_89_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_90_)" fill="#FDFDFD" d="M122.286,134.342c0.734,0.059,1.135,0.771,1.183,1.487
                      c0.013,0.178,0.013,0.35,0.005,0.509c-0.019,0.16-0.055,0.305-0.083,0.426c-0.061,0.244-0.117,0.394-0.117,0.394
                      s0.063-0.288,0.008-0.63c-0.052-0.34-0.305-0.71-0.769-0.839c-0.694-0.241-0.98-0.738-1.181-1.219
                      c-0.084-0.242-0.186-0.475-0.221-0.656c-0.04-0.184-0.066-0.303-0.066-0.303s0.025,0.049,0.062,0.127
                      c0.04,0.078,0.103,0.178,0.196,0.278c0.095,0.1,0.221,0.2,0.388,0.276C121.844,134.271,122.038,134.332,122.286,134.342"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_91_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_92_">
                      <use xlinkHref="#SVGID_91_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_92_)" fill="#FDFDFD" d="M122.422,136.89c0.316,0.199,0.449,0.5,0.496,0.856
                      c0.02,0.343-0.071,0.734-0.246,1.047c-0.088,0.158-0.185,0.307-0.278,0.438c-0.099,0.125-0.208,0.225-0.297,0.312
                      c-0.181,0.169-0.308,0.263-0.308,0.263s0.054-0.047,0.122-0.14c0.068-0.09,0.154-0.223,0.221-0.379
                      c0.134-0.315,0.133-0.767-0.19-1.12c-0.237-0.287-0.357-0.577-0.393-0.833c-0.083-0.277-0.035-0.515-0.046-0.759
                      c0.004-0.121,0.021-0.232,0.042-0.337c0.009-0.106,0.014-0.206,0.028-0.29c0.025-0.169,0.058-0.28,0.058-0.28
                      s-0.02,0.198,0.083,0.438C121.82,136.345,121.97,136.635,122.422,136.89"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_93_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_94_">
                      <use xlinkHref="#SVGID_93_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_94_)" fill="#FDFDFD" d="M121.211,139.126c0.157,0.333,0.12,0.662-0.019,0.983
                      c-0.152,0.296-0.407,0.582-0.691,0.768c-0.285,0.193-0.577,0.334-0.803,0.396c-0.224,0.073-0.372,0.104-0.372,0.104
                      s0.26-0.096,0.502-0.317c0.239-0.226,0.436-0.608,0.316-1.079c-0.071-0.363-0.028-0.677,0.079-0.929
                      c0.083-0.276,0.251-0.481,0.382-0.686c0.068-0.099,0.144-0.188,0.22-0.266c0.063-0.084,0.122-0.162,0.177-0.223
                      c0.114-0.124,0.198-0.199,0.192-0.201c0.009,0.005-0.117,0.152-0.157,0.406C121.001,138.34,120.966,138.674,121.211,139.126"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_95_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_96_">
                      <use xlinkHref="#SVGID_95_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_96_)" fill="#FDFDFD" d="M119.068,140.524c0.022,0.738-0.625,1.189-1.265,1.375
                      c-0.32,0.082-0.633,0.112-0.861,0.12c-0.23-0.005-0.377-0.024-0.377-0.024s0.066-0.001,0.172-0.019
                      c0.103-0.017,0.246-0.047,0.389-0.126c0.29-0.151,0.594-0.416,0.636-0.923c0.113-0.726,0.616-1.08,1.068-1.342
                      c0.232-0.12,0.452-0.217,0.616-0.288c0.171-0.061,0.282-0.102,0.282-0.102s-0.043,0.029-0.112,0.075
                      c-0.069,0.048-0.158,0.123-0.242,0.229C119.211,139.72,119.026,140.015,119.068,140.524"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_97_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_98_">
                      <use xlinkHref="#SVGID_97_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_98_)" fill="#FDFDFD" d="M116.568,141.106c-0.197,0.708-0.932,0.972-1.591,0.961
                      c-0.33,0.007-0.638-0.052-0.859-0.118c-0.221-0.059-0.358-0.111-0.358-0.111s0.263,0.058,0.581,0.004
                      c0.316-0.046,0.682-0.263,0.859-0.718c0.307-0.66,0.897-0.877,1.423-0.982c0.265-0.046,0.511-0.083,0.7-0.083
                      c0.188-0.008,0.309-0.014,0.309-0.014s-0.212,0.045-0.449,0.179C116.947,140.372,116.682,140.63,116.568,141.106"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_99_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_100_">
                      <use xlinkHref="#SVGID_99_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_100_)" fill="#FDFDFD" d="M114.002,140.98c-0.379,0.628-1.156,0.676-1.798,0.491
                      c-0.32-0.092-0.599-0.248-0.801-0.358c-0.199-0.122-0.311-0.226-0.311-0.226s0.228,0.159,0.559,0.181
                      c0.326,0.035,0.728-0.036,1.03-0.444c0.479-0.552,1.095-0.594,1.632-0.562c0.269,0.02,0.516,0.077,0.701,0.106
                      c0.184,0.037,0.303,0.078,0.303,0.078s-0.217-0.034-0.486,0.05C114.565,140.374,114.242,140.535,114.002,140.98"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_101_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_102_">
                      <use xlinkHref="#SVGID_101_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_102_)" fill="#FDFDFD" d="M111.576,140.143c-0.272,0.235-0.603,0.325-0.935,0.289
                      c-0.321-0.053-0.671-0.175-0.937-0.384c-0.272-0.201-0.511-0.417-0.647-0.613c-0.145-0.189-0.223-0.317-0.223-0.317
                      s0.177,0.213,0.466,0.368c0.303,0.14,0.72,0.194,1.125-0.08c0.634-0.375,1.223-0.238,1.714-0.044
                      c0.249,0.09,0.471,0.199,0.63,0.299c0.165,0.083,0.269,0.149,0.269,0.149s-0.051-0.022-0.135-0.041
                      c-0.087-0.016-0.204-0.037-0.339-0.044C112.292,139.715,111.926,139.807,111.576,140.143"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_103_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_104_">
                      <use xlinkHref="#SVGID_103_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_104_)" fill="#FDFDFD" d="M109.544,138.58c-0.348,0.14-0.685,0.101-0.976-0.058
                      c-0.149-0.076-0.292-0.176-0.424-0.292c-0.117-0.124-0.225-0.261-0.322-0.401c-0.197-0.269-0.331-0.592-0.398-0.829
                      c-0.063-0.234-0.086-0.388-0.086-0.388s0.073,0.272,0.302,0.533c0.223,0.272,0.61,0.447,1.087,0.338
                      c0.357-0.061,0.656,0.003,0.93,0.105c0.132,0.053,0.251,0.125,0.359,0.206c0.116,0.074,0.228,0.152,0.325,0.238
                      c0.186,0.179,0.362,0.345,0.485,0.479c0.121,0.134,0.192,0.235,0.192,0.235s-0.15-0.149-0.41-0.234
                      C110.34,138.438,110.004,138.368,109.544,138.58"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_105_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_106_">
                      <use xlinkHref="#SVGID_105_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_106_)" fill="#FDFDFD" d="M108.204,136.399c-0.734-0.016-1.162-0.7-1.293-1.351
                      c-0.142-0.656-0.015-1.248-0.015-1.248s-0.017,0.271,0.108,0.572c0.12,0.303,0.385,0.623,0.876,0.697
                      c0.714,0.161,1.044,0.688,1.269,1.134c0.102,0.225,0.196,0.437,0.266,0.593c0.07,0.167,0.102,0.283,0.102,0.283
                      s-0.092-0.189-0.311-0.356C109.004,136.565,108.702,136.392,108.204,136.399"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_107_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_108_">
                      <use xlinkHref="#SVGID_107_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_108_)" fill="#FDFDFD" d="M107.782,133.868c-0.686-0.248-0.899-1.005-0.831-1.672
                      c0.061-0.672,0.363-1.208,0.363-1.208s-0.096,0.259-0.076,0.585c0.034,0.329,0.178,0.711,0.634,0.93
                      c0.629,0.363,0.802,0.957,0.867,1.487c0.064,0.526,0.027,1.01,0.027,1.01s-0.021-0.216-0.147-0.459
                      C108.49,134.296,108.257,134.014,107.782,133.868"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_109_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_110_">
                      <use xlinkHref="#SVGID_109_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_110_)" fill="#FDFDFD" d="M108.138,131.326c-0.591-0.449-0.529-1.245-0.238-1.865
                      c0.149-0.306,0.332-0.573,0.499-0.744c0.158-0.178,0.271-0.279,0.271-0.279s-0.178,0.215-0.28,0.529
                      c-0.092,0.318-0.075,0.742,0.269,1.093c0.482,0.563,0.436,1.153,0.356,1.675c-0.1,0.509-0.274,0.959-0.274,0.959
                      s0.033-0.213-0.007-0.479C108.677,131.945,108.546,131.613,108.138,131.326"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_111_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_112_">
                      <use xlinkHref="#SVGID_111_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_112_)" fill="#FDFDFD" d="M109.318,129.051c-0.385-0.627-0.037-1.353,0.456-1.823
                      c0.497-0.47,1.075-0.664,1.075-0.664s-0.243,0.127-0.443,0.384c-0.189,0.275-0.338,0.656-0.14,1.116
                      c0.126,0.351,0.121,0.663,0.047,0.933c-0.05,0.292-0.198,0.52-0.318,0.75c-0.139,0.217-0.281,0.411-0.388,0.556
                      c-0.117,0.139-0.193,0.23-0.193,0.23s0.023-0.047,0.059-0.124c0.036-0.076,0.075-0.185,0.095-0.319
                      C109.597,129.817,109.607,129.473,109.318,129.051"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_113_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_114_">
                      <use xlinkHref="#SVGID_113_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_114_)" fill="#FDFDFD" d="M111.243,127.361c-0.146-0.726,0.412-1.288,1.006-1.565
                      c0.293-0.147,0.589-0.238,0.813-0.272c0.223-0.042,0.367-0.054,0.367-0.054s-0.258,0.061-0.515,0.247
                      c-0.256,0.188-0.493,0.535-0.452,1.023c0.006,0.358-0.1,0.659-0.256,0.906c-0.166,0.234-0.369,0.436-0.576,0.601
                      c-0.218,0.149-0.408,0.299-0.572,0.386c-0.159,0.09-0.264,0.148-0.264,0.148s0.042-0.035,0.099-0.098
                      c0.063-0.057,0.141-0.145,0.211-0.261C111.233,128.178,111.361,127.844,111.243,127.361"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_115_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_116_">
                      <use xlinkHref="#SVGID_115_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_116_)" fill="#FDFDFD" d="M113.159,127.324c0.202-0.198,0.43-0.475,0.443-0.981
                      c0.031-0.39,0.224-0.691,0.496-0.892c0.188-0.144,0.412-0.256,0.639-0.336c0.228-0.066,0.458-0.106,0.665-0.128
                      c0.412-0.052,0.717-0.005,0.717-0.005s-0.257-0.003-0.54,0.148c-0.28,0.145-0.578,0.411-0.645,0.898
                      c-0.076,0.38-0.266,0.664-0.504,0.864c-0.338,0.265-0.74,0.455-1.088,0.538c-0.332,0.116-0.601,0.15-0.601,0.15
                      S112.954,127.517,113.159,127.324"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_117_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_118_">
                      <use xlinkHref="#SVGID_117_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_118_)" fill="#FDFDFD" d="M190.177,163.693c2.572-1.588,2.783-7.52-1.404-6.99
                      c0.346,0.903,1.923,2.614,0.874,4.294c-0.751,1.204-2.803,1.369-4.128,1.026c-3.121-0.806-4.199-4.265-4.182-6.907
                      c0.05-10.032,8.14-8.369,8.436-6.234c0.283,1.923-1.888,3.659-2.403,3.527c5.19,1.402,6.933-7.392,0.147-7.615
                      c-7.771-0.168-9.596,6.754-8.944,12.318C179.282,162.931,185.059,166.896,190.177,163.693"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_119_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_120_">
                      <use xlinkHref="#SVGID_119_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_120_)" fill="#FDFDFD" d="M223.007,164.385c-0.559,1.107-2.88,2.926-4.094,0.216
                      c-1.193-2.594,2.501-6.644,5.263-5.359C223.586,162.298,223.58,163.23,223.007,164.385 M195.566,162.558
                      c-0.701-0.189-0.956-1.187-0.297-2.084c1.179-1.602,3.697-1.227,3.696-1.222C198.965,159.255,199.249,163.573,195.566,162.558
                      M238.965,161.231c-0.108,1.088-0.354,2.06-0.703,3.099c-0.543,1.623-1.302,3.992-3.307,3.813
                      c-3.326-0.285-0.946-9.073-0.477-11.548c-2.427,0.291-5.171,2.048-5.133,3.172c-0.005-0.121,1.51-0.541,2.313-0.629
                      c-0.394,2.683-1.369,7.12-4.063,8.614c-0.933,0.516-2.752-0.004-1.812-4.303c0.829-3.768,2.265-9.01,2.411-9.919
                      c0.33,0.056,0.744,0.34,1.221,0.496c-0.708-1.085-1.446-2.998-1.98-3.215c-0.788-0.324-1.24,0.485-1.511,1.38
                      c-0.71,2.346-0.657,2.714-1.263,4.538c-3.477-1.184-7.84,2.168-8.329,5.87c-0.124,0.888-0.139,1.92,0.059,2.898
                      c-1.112,0.602-2.483,0.848-2.011-2.368c0.079-0.53,0.59-4.116,0.724-4.93c0.176-1.075-0.23-2.586-1.578-3.257
                      c-2.551-1.266-4.759-0.5-5.234,0.346c0.104-0.82,0.214-1.304,0.326-2.136c-1.377-0.249-5.356,3.391-4.202,3.235
                      c0.615-0.084,1.607-0.822,2.435-0.832c-0.176,2.121-0.48,4.448-1.372,6.422c-0.793,1.726-4.288,2.961-4.253,0.378
                      c0.025-2.628,1.259-5.235,0.509-7.717c-1.339-4.399-8.047-3.33-7.922,0.01c0.076,0.505,0.918,0.807,1.31,0.267
                      c1.825-2.509,4.422-0.488,4.53,0.726c0.197,2.042-1.461,1.492-3.109,1.838c-2.604,0.544-4.631,2.721-3.896,5.316
                      c0.901,3.206,5.725,3.104,6.88,0.643c-0.598,2.885,4.584,3.788,5.845,1.198c-0.231,1.045-0.115,1.388-0.015,2.52
                      c3.607-0.158,2.208-5.959,4.009-9.197c1.035-1.836,3.623-0.797,3.237,1.313c-0.494,2.688-0.792,4.623-0.963,6.116
                      c-0.196,1.741,1.573,3.496,3.631,2.815c0.803-0.265,1.265-0.756,1.564-1.362c0.276,0.565,0.665,1.068,1.198,1.459
                      c1.657,1.203,4.193,1.062,5.633-0.366c0.646,1.58,1.101,2.206,2.821,2.332c2.057,0.151,3.379-0.834,4.497-2.514
                      c-0.31,2.193,3.993,4.049,5.603,2.593c-0.56,2.312-0.875,5.317-2.945,6.18c-2.022,0.855-3.32-3.026-1.73-4.315
                      c-2.192,0.278-2.449,2.388-2.029,4.15c0.567,2.373,3.234,3.45,5.374,2.213c3.723-2.133,5.185-14.498,6.803-18.264
                      C241.054,160.518,239.803,160.717,238.965,161.231"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_121_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_122_">
                      <use xlinkHref="#SVGID_121_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_122_)" fill="#FDFDFD" d="M131.516,162.571c-3.177-0.278-2.461-5.805-2.451-5.809
                      c0,0,1.561-1.015,3.533-0.337C135.684,157.506,135.154,162.914,131.516,162.571 M119.168,165.277
                      c-0.716,0.008-1.223-0.864-0.835-1.886c0.695-1.822,3.179-2.136,3.178-2.133C121.514,161.263,122.927,165.258,119.168,165.277
                      M136.716,157.265c-0.925-4.075-6.708-4.469-8.488-2.977c-0.014-0.072-0.388-2.352-1.089-2.198
                      c-0.53,0.113-1.331,1.929-2.063,2.912c0.454-0.117,0.858-0.357,1.17-0.385c0.165,1.924,0.847,5.989,1.429,9.302
                      c-1.028,1.038-2.729,1.432-3.202-0.329c-0.669-2.486-0.193-5.271-1.56-7.411c-2.428-3.793-8.5-1.009-7.498,2.105
                      c0.203,0.457,1.082,0.519,1.31-0.094c1.069-2.849,4.059-1.632,4.484-0.518c0.728,1.874-0.989,1.795-2.458,2.559
                      c-2.324,1.201-3.672,3.792-2.288,6.046c1.705,2.782,6.25,1.405,6.692-1.22c0.152,2.244,3.338,2.646,4.977,1.427
                      c0.475,2.22,0.698,4.716,0.775,5.46c1.451-1.002,2.152-1.892,1.949-3.231c-0.29-1.913-0.518-3.561-0.518-3.561
                      C130.343,165.114,138.607,165.557,136.716,157.265"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_123_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_124_">
                      <use xlinkHref="#SVGID_123_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_124_)" fill="#FDFDFD" d="M113.612,167.652c2.347-2.539,0.751-9.104-3.726-7.219
                      c0.665,0.883,2.942,2.262,2.302,4.429c-0.457,1.553-2.672,2.377-4.245,2.419c-3.702,0.098-5.987-3.337-6.798-6.233
                      c-3.115-10.993,6.425-11.741,7.415-9.493c0.913,2.021-0.958,4.603-1.568,4.619c6.192-0.088,5.413-10.269-2.218-8.38
                      c-8.717,2.279-8.554,10.451-6.068,16.32C101.347,170.244,108.96,172.747,113.612,167.652"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_125_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_126_">
                      <use xlinkHref="#SVGID_125_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_126_)" fill="#FDFDFD" d="M160.141,147.218c-3.084-2.103-4.667,2.023-1.406,2.013
                      C159.622,149.231,160.774,147.656,160.141,147.218"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_127_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_128_">
                      <use xlinkHref="#SVGID_127_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_128_)" fill="#FFFFFF" d="M148.863,161.317c-0.687-0.067-1.083-0.954-0.605-1.896
                      c0.849-1.677,3.259-1.723,3.258-1.721C151.517,157.706,152.463,161.687,148.863,161.317 M174.009,163.349
                      c1.447-0.73,1.473-2.117,1.576-3.523c-1.036,0.917-2.403,2.213-2.975,0.377c-0.265-0.856-0.277-3.749-0.219-6.041
                      c0.013-0.638-0.146-2.662-1.985-3.236c-2.563-0.801-4.107,0.197-4.436,1.05c-0.015-0.778,0.02-1.247,0.01-2.041
                      c-1.319-0.039-4.544,3.912-3.485,3.606c0.563-0.163,1.389-0.988,2.163-1.114c0.091,1.897,0.226,3.721-0.393,6.257
                      c-0.333,1.365-3.217,4.114-4.075,0.371c-0.383-1.588,1.598-7.64-1.567-7.853c-1.354-0.086-2.85,1.894-2.809,3.01
                      c0.882-0.598,1.49-1.23,2.098-0.339c0.354,0.519,0.148,4.53,0.129,5.234c-0.058,2.03-3.504,3.77-3.927,1.132
                      c-0.394-2.455,0.349-5.08-0.741-7.273c-1.936-3.888-8.026-1.841-7.387,1.251c0.148,0.461,0.981,0.608,1.264,0.044
                      c1.312-2.623,4.048-1.146,4.341-0.034c0.507,1.872-1.128,1.621-2.613,2.202c-1.313,0.512-2.18,1.22-2.823,2.482
                      c-0.238,0.466-3.481,5.318-4.698,1.143c-0.19-0.652-0.438-3.589-0.807-6.785c1.253-0.054,2.962-0.813,3.752-1.52
                      c0.84-0.755,0.854-3.388-0.521-3.713c0.701,2.39-1.416,2.823-3.526,2.863c-0.186-1.355-0.47-3.335-0.682-4.176
                      c-0.384-1.517-0.715-1.988-2.772-2.644c0.368,2.668,1.621,15.081,2.174,17.953c0.541,2.8,3.605,2.981,5.372,1.849
                      c0.417-0.27,1.595-1.258,1.792-1.751c1.425,2.68,5.782,1.818,6.462-0.62c0.027,3.131,5.399,2.894,5.644,0.463
                      c0.735,1.531,4.112,2.688,6.184-0.694c-0.064,1.243-0.036,1.517,0.239,2.583c3.357-0.649,1.339-6.259,2.576-9.537
                      c0.715-1.857,2.842-1.253,2.807,0.773c-0.04,2.615,0.076,6.388,0.084,6.521C170.339,163.333,172.137,164.293,174.009,163.349"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_129_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_130_">
                      <use xlinkHref="#SVGID_129_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_130_)" fill="#FDFDFD" d="M184.209,197.256c-0.074,0.048-0.147,0.12-0.227,0.213
                      c-0.143,0.17-0.192,0.443-0.146,0.814l0.073,0.616c0.021,0.163,0.083,0.292,0.194,0.384c0.107,0.094,0.222,0.13,0.34,0.116
                      s0.237-0.077,0.355-0.188l-0.258-2.141C184.393,197.146,184.281,197.208,184.209,197.256"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_131_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_132_">
                      <use xlinkHref="#SVGID_131_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_132_)" fill="#FDFDFD" d="M158.668,196.41c-0.126-0.01-0.254,0.029-0.381,0.114l-0.213,2.814
                      c-0.035,0.473,0.109,0.719,0.433,0.742c0.121,0.009,0.247-0.032,0.382-0.124l0.212-2.808
                      C159.135,196.683,158.991,196.435,158.668,196.41"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_133_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_134_">
                      <use xlinkHref="#SVGID_133_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_134_)" fill="#FDFDFD" d="M148.155,194.966c-0.181-0.027-0.376-0.002-0.582,0.084l-0.104,0.041
                      l-0.456,2.932c-0.073,0.467,0.051,0.722,0.372,0.772c0.119,0.019,0.25-0.012,0.391-0.093l0.512-3.279
                      c0.006-0.039-0.009-0.124-0.043-0.253C148.21,195.037,148.181,194.971,148.155,194.966"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_135_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_136_">
                      <use xlinkHref="#SVGID_135_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_136_)" fill="#FDFDFD" d="M180.429,196.367c-0.092-0.117-0.202-0.17-0.327-0.157
                      c-0.125,0.012-0.26,0.072-0.4,0.182l0.201,2.193c0.552-0.212,0.803-0.578,0.754-1.104l-0.063-0.675
                      C180.576,196.627,180.524,196.481,180.429,196.367"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_137_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_138_">
                      <use xlinkHref="#SVGID_137_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_138_)" fill="#FDFDFD" d="M206.315,186.823c-1.673,0.674-4.059,0.279-4.059,0.279
                      c1.899-0.14,4.407-1.052,4.407-1.052l0.584,3.235L206.315,186.823z M201.855,196.226c0.216,0,0.467-0.036,0.75-0.105l0.067,0.269
                      c-0.929,0.591-1.427,0.894-1.495,0.91c-0.187,0.046-0.406-0.038-0.661-0.253c-0.258-0.215-0.43-0.499-0.517-0.851l-0.771-3.11
                      c-0.011-0.045,0.303-0.283,0.941-0.721c0.641-0.436,1.011-0.667,1.116-0.694c0.03-0.008,0.12,0.021,0.267,0.089
                      c0.148,0.063,0.306,0.166,0.471,0.304c0.164,0.14,0.268,0.288,0.308,0.45l0.384,1.555c0.029,0.112-0.102,0.286-0.389,0.529
                      c-0.289,0.241-0.572,0.453-0.853,0.627c-0.28,0.18-0.432,0.271-0.457,0.276C201.135,195.976,201.413,196.22,201.855,196.226
                      M197.474,196.7c0.084,0.378,0.256,0.565,0.523,0.559l0.045,0.206l-0.825,0.737c-0.148-0.028-0.296-0.091-0.441-0.193
                      c-0.146-0.103-0.232-0.208-0.254-0.32l-0.679-3.095c-0.059-0.272-0.152-0.468-0.277-0.584c-0.127-0.114-0.243-0.16-0.357-0.136
                      l-0.045-0.207l0.902-0.782c0.046,0.003,0.103,0.009,0.165,0.019c0.063,0.009,0.158,0.051,0.281,0.127
                      c0.125,0.078,0.201,0.179,0.228,0.304c0.029-0.021,0.155-0.149,0.38-0.391c0.225-0.24,0.373-0.37,0.447-0.386
                      c0.199-0.044,0.415,0.047,0.646,0.27c0.229,0.223,0.369,0.443,0.416,0.66l0.1,0.454l-0.843,0.752l-0.187-0.096
                      c-0.115-0.522-0.229-0.809-0.343-0.862c-0.13-0.056-0.306-0.058-0.53-0.011L197.474,196.7z M194.574,198.739
                      c-0.157-0.034-0.304-0.104-0.441-0.204c-0.139-0.101-0.218-0.209-0.238-0.32l-1.055,0.846c-0.022-0.008-0.053-0.021-0.097-0.034
                      c-0.039-0.016-0.112-0.049-0.216-0.105c-0.104-0.056-0.198-0.117-0.283-0.185c-0.226-0.171-0.364-0.388-0.411-0.65l-0.53-2.843
                      c-0.063-0.343-0.255-0.518-0.572-0.523l-0.039-0.206l0.916-0.763c0.146,0.03,0.292,0.099,0.435,0.204s0.225,0.217,0.246,0.327
                      l0.606,3.255c0.031,0.162,0.105,0.286,0.219,0.37c0.117,0.086,0.235,0.117,0.353,0.096c0.119-0.023,0.236-0.092,0.35-0.211
                      l-0.545-2.917c-0.068-0.382-0.238-0.572-0.505-0.575l-0.037-0.206l0.846-0.714c0.147,0.031,0.294,0.102,0.436,0.207
                      c0.145,0.105,0.226,0.215,0.248,0.327l0.594,3.178c0.036,0.207,0.112,0.378,0.229,0.512c0.115,0.132,0.241,0.188,0.369,0.163
                      l0.039,0.206C195.447,197.99,195.142,198.243,194.574,198.739 M189.458,199.278c-0.675,0.403-1.066,0.613-1.174,0.631
                      c-0.044,0.007-0.091-0.027-0.144-0.099s-0.129-0.185-0.227-0.338c-0.098-0.152-0.189-0.279-0.277-0.381
                      c-0.104-0.138-0.221-0.198-0.345-0.178l-0.028-0.188c0.093-0.061,0.219-0.146,0.374-0.261c0.362-0.282,0.561-0.424,0.591-0.429
                      c0.095-0.016,0.24,0.152,0.439,0.501c0.199,0.351,0.341,0.518,0.431,0.502c0.063-0.008,0.118-0.043,0.167-0.101
                      c0.049-0.061,0.066-0.134,0.053-0.221l-0.183-1.178c-0.042-0.284-0.144-0.413-0.301-0.389c-0.051,0.008-0.236,0.114-0.558,0.318
                      c-0.324,0.204-0.507,0.308-0.55,0.318c-0.159,0.023-0.312-0.076-0.461-0.297c-0.149-0.223-0.252-0.506-0.306-0.852l-0.157-1.018
                      c-0.02-0.12,0.278-0.377,0.896-0.77c0.615-0.396,0.974-0.599,1.073-0.614c0.057-0.01,0.112,0.017,0.165,0.076
                      c0.054,0.06,0.099,0.106,0.128,0.143c0.032,0.039,0.083,0.101,0.15,0.191c0.067,0.086,0.127,0.16,0.176,0.219
                      c0.104,0.137,0.219,0.196,0.344,0.179l0.029,0.187c-0.094,0.061-0.216,0.146-0.365,0.261c-0.368,0.279-0.568,0.425-0.6,0.429
                      c-0.095,0.017-0.221-0.123-0.378-0.415c-0.157-0.289-0.279-0.43-0.369-0.417c-0.111,0.018-0.155,0.12-0.124,0.311l0.177,1.158
                      c0.04,0.251,0.115,0.367,0.229,0.351c0.024-0.003,0.207-0.116,0.546-0.336c0.342-0.219,0.548-0.335,0.623-0.346
                      c0.15-0.024,0.303,0.077,0.457,0.302c0.153,0.225,0.26,0.526,0.317,0.901l0.164,1.066
                      C190.462,198.615,190.133,198.876,189.458,199.278 M185.495,200.202c-0.145-0.041-0.286-0.118-0.422-0.232
                      c-0.136-0.115-0.209-0.227-0.223-0.334l-1.105,0.781c-0.021-0.009-0.053-0.026-0.094-0.043c-0.037-0.019-0.109-0.057-0.211-0.116
                      c-0.1-0.064-0.19-0.13-0.27-0.204c-0.213-0.185-0.339-0.411-0.372-0.676l-0.188-1.553c-0.014-0.103,0.135-0.251,0.444-0.447
                      c0.309-0.199,0.621-0.368,0.936-0.511l0.482-0.222c-0.044-0.36-0.17-0.601-0.377-0.719c-0.206-0.117-0.581-0.146-1.123-0.079
                      l-0.034-0.283c0.936-0.468,1.463-0.709,1.583-0.722c0.208-0.026,0.423,0.084,0.646,0.332c0.224,0.25,0.353,0.52,0.388,0.809
                      l0.329,2.679c0.027,0.233,0.084,0.393,0.173,0.474c0.088,0.082,0.214,0.132,0.375,0.151l0.024,0.209L185.495,200.202z
                      M181.382,198.314c-0.321,0.196-0.633,0.36-0.935,0.493c-0.305,0.134-0.47,0.202-0.495,0.204
                      c0.046,0.486,0.287,0.769,0.721,0.843c0.214,0.032,0.468,0.034,0.757,0.007l0.026,0.274c-1.007,0.446-1.545,0.673-1.613,0.679
                      c-0.19,0.018-0.396-0.099-0.617-0.35c-0.222-0.251-0.349-0.557-0.382-0.919l-0.297-3.189c-0.004-0.044,0.342-0.235,1.038-0.571
                      c0.696-0.338,1.099-0.509,1.207-0.52c0.032-0.003,0.115,0.04,0.25,0.126c0.136,0.085,0.275,0.209,0.418,0.372
                      c0.144,0.161,0.223,0.325,0.237,0.49l0.149,1.597C181.857,197.963,181.702,198.116,181.382,198.314 M176.985,197.745l-0.17-0.121
                      c-0.035-0.532-0.104-0.833-0.21-0.904c-0.121-0.074-0.294-0.105-0.521-0.09l0.193,3.036c0.024,0.388,0.168,0.599,0.432,0.632
                      l0.013,0.211l-0.927,0.602c-0.142-0.047-0.279-0.132-0.405-0.256c-0.131-0.122-0.199-0.239-0.207-0.354l-0.201-3.159
                      c-0.018-0.279-0.079-0.487-0.188-0.62c-0.106-0.134-0.219-0.196-0.331-0.188l-0.015-0.211l1.012-0.638
                      c0.043,0.011,0.099,0.023,0.16,0.041c0.063,0.021,0.147,0.077,0.259,0.173c0.111,0.095,0.171,0.204,0.179,0.332
                      c0.031-0.015,0.176-0.124,0.435-0.329c0.257-0.204,0.423-0.31,0.499-0.314c0.203-0.013,0.401,0.108,0.598,0.364
                      c0.196,0.255,0.299,0.492,0.313,0.715l0.029,0.467L176.985,197.745z M172.615,201.255c-0.143-0.052-0.274-0.142-0.399-0.27
                      c-0.126-0.124-0.189-0.246-0.194-0.359l-0.142-4.166l-0.391,0.014l0.081-0.404c0.19-0.006,0.417-0.143,0.678-0.41
                      c0.261-0.265,0.412-0.547,0.453-0.84l0.27-0.011l0.037,1.137l0.486-0.019l-0.079,0.47l-0.392,0.014l0.117,3.414
                      c0.014,0.388,0.152,0.604,0.413,0.646l0.009,0.21L172.615,201.255z M168.869,197.505l-0.988,0.546l-0.161-0.136
                      c0.006-0.534-0.043-0.841-0.144-0.917c-0.114-0.084-0.286-0.127-0.514-0.129l-0.024,3.043c-0.005,0.39,0.124,0.607,0.384,0.662
                      l-0.001,0.209l-0.968,0.536c-0.14-0.058-0.269-0.152-0.389-0.284c-0.12-0.133-0.18-0.254-0.179-0.368l0.027-3.168
                      c0.002-0.28-0.046-0.489-0.143-0.631c-0.098-0.141-0.204-0.211-0.318-0.213l0.001-0.21l1.055-0.563
                      c0.044,0.013,0.096,0.03,0.158,0.052c0.061,0.025,0.141,0.088,0.246,0.188c0.104,0.104,0.157,0.219,0.155,0.346
                      c0.032-0.013,0.184-0.11,0.455-0.297c0.271-0.186,0.447-0.277,0.523-0.276c0.203,0.001,0.393,0.136,0.569,0.402
                      c0.175,0.271,0.262,0.517,0.261,0.739L168.869,197.505z M164.986,200.722c-0.045,0.006-0.398,0.185-1.063,0.538
                      c-0.143-0.069-0.271-0.167-0.383-0.297c-0.113-0.129-0.167-0.251-0.162-0.363l-1.217,0.587c-0.019-0.013-0.046-0.03-0.083-0.056
                      c-0.038-0.023-0.102-0.074-0.189-0.15c-0.09-0.077-0.167-0.157-0.233-0.243c-0.181-0.219-0.268-0.46-0.256-0.728l0.12-2.888
                      c0.015-0.35-0.133-0.563-0.44-0.639l0.008-0.212l1.065-0.536c0.136,0.063,0.263,0.162,0.378,0.296
                      c0.115,0.137,0.17,0.263,0.166,0.375l-0.137,3.308c-0.009,0.166,0.037,0.303,0.131,0.412c0.094,0.108,0.201,0.165,0.322,0.17
                      c0.12,0.007,0.25-0.036,0.387-0.128l0.123-2.963c0.017-0.388-0.105-0.611-0.363-0.674l0.01-0.211l0.985-0.503
                      c0.139,0.064,0.264,0.162,0.379,0.297c0.116,0.137,0.171,0.261,0.166,0.377l-0.134,3.23c-0.01,0.209,0.028,0.391,0.112,0.549
                      c0.081,0.156,0.189,0.236,0.323,0.24L164.986,200.722z M160.028,200.185c-0.003,0.051-0.362,0.187-1.077,0.403
                      c-0.716,0.219-1.149,0.32-1.306,0.309c-0.156-0.012-0.323-0.163-0.503-0.45c-0.178-0.288-0.253-0.622-0.224-1.002l0.238-3.159
                      c0.004-0.046,0.377-0.175,1.12-0.388c0.744-0.215,1.169-0.32,1.278-0.312c0.106,0.011,0.26,0.152,0.459,0.429
                      c0.198,0.277,0.291,0.519,0.274,0.729L160.028,200.185z M155.529,199.7c-0.026,0.24-0.171,0.572-0.434,0.993
                      c-0.264,0.422-0.535,0.788-0.811,1.103c-0.277,0.312-0.459,0.463-0.547,0.455c-0.159-0.019-0.365-0.09-0.621-0.212
                      c-0.256-0.124-0.471-0.243-0.652-0.36l-0.259-0.173l0.031-0.285l0.322,0.035c0.247,0.025,0.543-0.083,0.89-0.33
                      c0.347-0.245,0.649-0.592,0.908-1.038c-0.75,0.257-1.179,0.381-1.287,0.37c-0.195-0.023-0.358-0.167-0.486-0.434
                      c-0.126-0.266-0.174-0.554-0.14-0.862l0.283-2.609c0.038-0.346-0.094-0.57-0.398-0.667l0.022-0.209l1.097-0.465
                      c0.132,0.071,0.252,0.18,0.358,0.32c0.107,0.143,0.154,0.271,0.141,0.386l-0.278,2.57c-0.048,0.441-0.011,0.746,0.112,0.908
                      c0.122,0.165,0.329,0.263,0.62,0.292l0.318-2.919c0.042-0.388-0.065-0.618-0.319-0.696l0.023-0.209l1.017-0.437
                      c0.133,0.071,0.251,0.178,0.359,0.322c0.105,0.144,0.153,0.271,0.139,0.384L155.529,199.7z M149.43,195.58l-0.449,2.875
                      c-0.022,0.145-0.006,0.292,0.052,0.443c0.057,0.149,0.155,0.246,0.298,0.286l-0.033,0.207c-0.045,0-0.417,0.14-1.117,0.417
                      c-0.135-0.085-0.25-0.199-0.347-0.341c-0.098-0.14-0.137-0.263-0.121-0.371c-0.737,0.31-1.163,0.454-1.275,0.438
                      c-0.045-0.007-0.121-0.069-0.228-0.186s-0.208-0.278-0.301-0.481c-0.094-0.205-0.125-0.403-0.095-0.599l0.525-3.366
                      c0.01-0.061,0.575-0.196,1.694-0.4c-0.148-0.53-0.276-0.91-0.383-1.132c-0.107-0.223-0.226-0.343-0.358-0.364l-0.311-0.047
                      l0.044-0.283c0.747-0.205,1.25-0.287,1.515-0.246c0.168,0.026,0.372,0.477,0.608,1.36
                      C149.386,194.67,149.479,195.267,149.43,195.58 M144.633,198.44l-0.039,0.207l-1.131,0.379c-0.125-0.084-0.238-0.198-0.331-0.351
                      c-0.094-0.15-0.131-0.283-0.109-0.395l0.514-2.717c0.066-0.35,0.078-0.624,0.036-0.823c-0.044-0.2-0.165-0.319-0.365-0.356
                      l-0.319-0.003l-0.566,2.988c-0.073,0.383,0.013,0.623,0.261,0.721l-0.039,0.207l-1.049,0.354
                      c-0.127-0.081-0.238-0.198-0.332-0.348c-0.094-0.151-0.132-0.283-0.109-0.396l0.602-3.178c0.039-0.205,0.03-0.392-0.029-0.559
                      c-0.06-0.168-0.155-0.264-0.285-0.288l0.038-0.205c0.045,0.001,0.422-0.125,1.131-0.378c0.041,0.02,0.09,0.046,0.149,0.081
                      c0.058,0.034,0.129,0.111,0.213,0.229c0.084,0.121,0.115,0.246,0.089,0.378c0.041-0.012,0.158-0.051,0.353-0.117
                      c0.512-0.175,0.812-0.254,0.898-0.237c0.2,0.037,0.362,0.204,0.488,0.5c0.125,0.295,0.167,0.552,0.125,0.77l-0.538,2.842
                      C144.224,198.089,144.339,198.318,144.633,198.44 M140.637,191.288l-0.147,0.68c-0.004,0.019-0.141,0.048-0.41,0.092
                      c-0.27,0.043-0.433,0.058-0.489,0.047c-0.055-0.015-0.107-0.082-0.155-0.205c-0.049-0.125-0.053-0.276-0.013-0.458l0.123-0.567
                      c0.005-0.019,0.145-0.048,0.425-0.089c0.278-0.039,0.437-0.056,0.478-0.047c0.041,0.01,0.087,0.073,0.143,0.197
                      C140.647,191.06,140.662,191.176,140.637,191.288 M139.818,197.541l-0.044,0.204l-1.057,0.328
                      c-0.125-0.086-0.232-0.204-0.322-0.357c-0.091-0.153-0.124-0.288-0.1-0.398l0.716-3.3c0.082-0.38,0.002-0.621-0.243-0.727
                      l0.045-0.205l1.056-0.328c0.125,0.086,0.232,0.206,0.324,0.358c0.091,0.155,0.123,0.288,0.099,0.399l-0.716,3.3
                      C139.492,197.195,139.573,197.438,139.818,197.541 M137.728,193.056l-0.52,0.12l-0.483-0.117l-0.71,2.95
                      c-0.09,0.379-0.016,0.621,0.226,0.731l-0.048,0.203l-1.065,0.305c-0.122-0.089-0.227-0.21-0.314-0.365
                      c-0.085-0.155-0.116-0.288-0.089-0.399l0.889-3.69l-0.566-0.136l0.049-0.204l0.625-0.115l0.318-1.317
                      c0.027-0.116,0.391-0.244,1.09-0.38c0.701-0.136,1.119-0.186,1.255-0.154c0.049,0.01,0.083,0.045,0.102,0.099
                      c0.021,0.053,0.036,0.103,0.045,0.143l0.041,0.194c0.024,0.093,0.048,0.18,0.074,0.264c0.044,0.17,0.128,0.27,0.252,0.299
                      l-0.044,0.183c-0.109,0.021-0.258,0.057-0.448,0.109c-0.441,0.118-0.677,0.171-0.707,0.163c-0.092-0.021-0.145-0.18-0.157-0.469
                      c-0.012-0.292-0.061-0.446-0.147-0.467c-0.113-0.028-0.19,0.052-0.235,0.236l-0.328,1.364l0.946,0.227L137.728,193.056z
                      M130.199,186.819l-0.934,2.462l0.585-3.233c0,0,2.507,0.911,4.407,1.051C134.258,187.099,131.871,187.495,130.199,186.819
                      M207.885,183.983c0,0-18.347,6.69-39.628,6.69c-21.281,0-39.627-6.69-39.627-6.69l-3.479,15.172c0,0,18.971,6.984,43.107,6.984
                      c24.136,0,43.107-6.984,43.107-6.984L207.885,183.983z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_139_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_140_">
                      <use xlinkHref="#SVGID_139_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_140_)" fill="#FDFDFD" d="M201.489,193.88l-0.162-0.658c-0.042-0.172-0.12-0.309-0.229-0.41
                      c-0.11-0.101-0.229-0.134-0.351-0.104c-0.124,0.031-0.249,0.111-0.374,0.24l0.531,2.139
                      C201.42,194.794,201.615,194.392,201.489,193.88"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_141_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_142_">
                      <use xlinkHref="#SVGID_141_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_142_)" fill="#282425" d="M138.613,192.03c-0.002,0.032-0.01,0.069-0.01,0.1l0.022-0.091
                      C138.62,192.035,138.617,192.032,138.613,192.03"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_143_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_144_">
                      <use xlinkHref="#SVGID_143_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_144_)" fill="#FDFDFD" d="M221.039,190.57c-1.132,1.14-2.705,1.418-2.705,1.418l2.007-1.418
                      l-1.529-2.103C220.016,189.067,221.039,190.57,221.039,190.57 M216.87,184.726c1.65-3.379,1.416-9.321,1.416-9.321
                      c-7.697,4.99-14.442,5.42-14.442,5.42l5.798,1.705l2.486,11.838c5.451,0.283,10.415-3.566,10.415-3.566
                      C222.073,189.658,216.87,184.726,216.87,184.726"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_145_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_146_">
                      <use xlinkHref="#SVGID_145_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_146_)" fill="#FDFDFD" d="M115.988,190.612c0,0,1.024-1.503,2.23-2.105l-1.532,2.105l2.007,1.42
                      C118.692,192.032,117.12,191.749,115.988,190.612 M132.384,180.914c0,0-6.749-0.428-14.443-5.41c0,0-0.24,5.938,1.416,9.313
                      c0,0-5.204,4.94-5.685,6.083c0,0,4.978,3.845,10.422,3.56l2.485-11.831L132.384,180.914z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_147_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_148_">
                      <use xlinkHref="#SVGID_147_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_148_)" fill="#FDFDFD" d="M144.241,177.026c0-1.522,0.879-2.606,2.442-2.606
                      c0.459,0,1.02,0.088,1.307,0.375l-0.22,0.635c-0.42-0.198-0.806-0.213-1.175-0.213c-0.886,0-1.453,0.563-1.453,1.697
                      c0,1.108,0.597,1.624,1.504,1.624c0.473,0,0.923-0.103,1.359-0.362l0.236,0.592c-0.347,0.36-1.108,0.567-1.794,0.567
                      C145.105,179.334,144.241,178.471,144.241,177.026"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_149_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_150_">
                      <use xlinkHref="#SVGID_149_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_150_)" fill="#FDFDFD" d="M150.76,177.452c0.067-0.053,0.244-0.074,0.651-0.074h0.981l-0.76-2.046
                      h-0.06L150.76,177.452z M149.455,178.811c0-0.493,0.856-2.421,1.166-3.033c0.133-0.267,0.266-0.472,0.356-0.555l-0.459-0.146
                      l0.089-0.568c0.214-0.067,0.531-0.088,0.784-0.088c0.479,0,0.722,0.25,0.906,0.612c0.273,0.508,0.879,2.287,1.123,2.885
                      c0.176,0.429,0.33,0.656,0.487,0.673v0.62c-0.083,0.05-0.17,0.074-0.324,0.074c-0.236,0-0.488-0.134-0.702-0.547
                      c-0.119-0.23-0.317-0.711-0.324-0.731c-0.082,0.06-0.259,0.089-0.605,0.089h-1.395l-0.39,1.151h-0.606
                      C149.484,179.149,149.455,179.01,149.455,178.811"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_151_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_152_">
                      <use xlinkHref="#SVGID_151_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_152_)" fill="#FDFDFD" d="M155.311,178.192v-1.665c0-0.441-0.03-1.045,0.125-1.229l-0.479-0.125
                      l0.044-0.636c0.176-0.082,0.354-0.082,0.517-0.082c0.324,0,0.583,0.171,0.783,0.37c0.288,0.266,1.86,2.76,2.31,3.579h0.029
                      v-3.114l-0.545-0.134v-0.619c0.102-0.052,0.258-0.074,0.354-0.082c0.102-0.016,0.2-0.016,0.302,0
                      c0.526,0.029,0.666,0.34,0.666,0.931v3.069c0,0.62-0.237,0.813-0.643,0.813c-0.177,0-0.324-0.023-0.523-0.224
                      c-0.238-0.206-1.728-2.605-2.097-3.238h-0.022v3.438h-0.724C155.319,179.018,155.311,178.745,155.311,178.192"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_153_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_154_">
                      <use xlinkHref="#SVGID_153_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_154_)" fill="#FDFDFD" d="M162.185,178.442c0.235,0.146,0.488,0.212,0.812,0.212
                      c1.063,0,1.528-0.546,1.528-1.702c0-1.101-0.479-1.833-1.645-1.833c-0.236,0-0.459,0.021-0.695,0.096V178.442z M161.323,178.419
                      v-3.188c-0.236-0.017-0.354-0.091-0.436-0.186l0.095-0.585c0.066,0.017,0.34,0.03,0.628,0.03c0.28,0,0.724-0.074,1.299-0.074
                      c1.609,0,2.502,0.857,2.502,2.413c0,1.735-0.998,2.519-2.475,2.519c-0.663,0-1.253-0.104-1.675-0.43
                      C161.278,178.811,161.323,178.641,161.323,178.419"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_155_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_156_">
                      <use xlinkHref="#SVGID_155_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_156_)" fill="#FDFDFD" d="M168.138,178.507c0-0.162,0-0.531,0.016-0.714
                      c-0.4-0.097-1.33-2.532-1.744-2.547l0.03-0.711c0.11-0.036,0.25-0.051,0.324-0.051c0.539,0,0.796,0.487,1.01,0.916
                      c0.267,0.526,0.605,1.3,0.834,1.86h0.015c0.42-0.825,1.011-1.778,1.017-2.553c0-0.066,0-0.141-0.013-0.214h0.768
                      c0.119,0.133,0.147,0.258,0.154,0.42c0,0.147-0.028,0.31-0.074,0.472c-0.051,0.318-0.567,1.589-1.394,2.436v1.426h-0.842
                      C168.138,179.076,168.138,178.899,168.138,178.507"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_157_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_158_">
                      <use xlinkHref="#SVGID_157_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_158_)" fill="#FDFDFD" d="M174.172,178.139c0.384,0.243,0.871,0.434,1.395,0.434
                      c0.488,0,0.969-0.169,0.969-0.694c0-0.471-0.517-0.566-1.286-0.848c-0.591-0.2-1.145-0.466-1.145-1.218
                      c0-0.811,0.71-1.409,1.684-1.409c0.539,0,0.99,0.088,1.373,0.369l-0.229,0.657c-0.34-0.163-0.716-0.245-1.175-0.245
                      c-0.494,0-0.833,0.172-0.833,0.555c0,0.453,0.671,0.576,1.327,0.807c0.547,0.176,1.138,0.406,1.138,1.23
                      c0,0.881-0.686,1.558-1.86,1.558c-0.636,0-1.277-0.206-1.61-0.589L174.172,178.139z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_159_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_160_">
                      <use xlinkHref="#SVGID_159_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_160_)" fill="#FDFDFD" d="M178.964,178.44v-3.948h0.739c0.103,0.157,0.124,0.326,0.124,1.019v1.063
                      c0.067-0.061,0.34-0.096,0.621-0.096h1.544v-1.986h0.729c0.119,0.147,0.119,0.437,0.119,0.916v3.838h-0.769
                      c-0.08-0.267-0.097-0.421-0.097-0.872v-1.136c-0.118,0.06-0.449,0.06-0.892,0.06h-1.256v1.948h-0.775
                      C178.986,179.09,178.964,178.862,178.964,178.44"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_161_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_162_">
                      <use xlinkHref="#SVGID_161_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_162_)" fill="#FDFDFD" d="M188.069,176.883c0-1.225-0.54-1.764-1.353-1.764
                      c-0.861,0-1.386,0.59-1.386,1.711c0,1.007,0.316,1.832,1.372,1.832C187.603,178.662,188.069,178.117,188.069,176.883
                      M184.414,176.942c0-1.534,0.908-2.529,2.385-2.529c1.461,0,2.178,0.906,2.178,2.361c0,1.639-0.844,2.598-2.386,2.598
                      C185.219,179.371,184.414,178.545,184.414,176.942"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_163_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_164_">
                      <use xlinkHref="#SVGID_163_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_164_)" fill="#FDFDFD" d="M191.445,176.965c0.244,0.147,0.479,0.192,0.781,0.192
                      c0.709,0,1.005-0.412,1.005-1.069c0-0.649-0.428-0.975-1.079-0.975c-0.294,0-0.559,0.022-0.707,0.074V176.965z M190.603,178.086
                      v-2.848c-0.213-0.037-0.339-0.083-0.45-0.192l0.095-0.585c0.133,0.023,0.399,0.03,0.547,0.03c0.51,0,0.769-0.074,1.4-0.074
                      c1.109,0,1.891,0.475,1.891,1.581c0,1.306-0.818,1.853-1.793,1.853c-0.303,0-0.626-0.046-0.848-0.156v1.551h-0.747
                      C190.587,179.031,190.603,178.824,190.603,178.086"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_165_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_166_">
                      <use xlinkHref="#SVGID_165_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_166_)" fill="#FDFDFD" d="M174.212,80.085c-0.615,1.638-1.576,3.309-1.936,5.018
                      c-0.324-1.663-1.212-3.197-2.336-4.451c-0.177-0.203-0.403-0.281-0.653-0.282c-0.655,0-1.48,0.542-2.042,0.777
                      c-3.698,1.543-6.793,4.019-9.33,7.1c-2.65,3.221-4.838,6.866-8.027,9.611c-2.047,1.763-5.45,3.925-8.509,3.925
                      c-1.222,0-2.387-0.344-3.39-1.195c0.015,5.084,5.104,9.698,9.194,11.758c1.428,0.718,2.921,1.346,4.482,1.703
                      c1.546,0.351,2.522-0.219,2.588,1.638c0.061,1.768-7.017,7.299-0.85,15.007c-0.358-0.449,0.287-1.506,0.923-2.101
                      c-0.681,0.574,0.344,4.163,3.035,4.801c-0.37-0.908-1.213-2.482-0.975-3.486c0.851,3.771,3.656,3.112,6.817,3.587
                      c2.774,0.417,0.748,2.637-0.249,2.637c-0.142,0-0.264-0.046-0.346-0.147c0.477,0.612,1.05,0.846,1.62,0.846
                      c1.27,0,2.515-1.165,2.595-1.847c0.358,1.448-0.01,3.065-2.056,3.065c-0.329,0-0.703-0.043-1.124-0.134
                      c1.048,1.041,2.088,1.444,3.08,1.444c2.039,0,3.872-1.703,5.143-3.072c0.011,1.077-0.683,2.758-1.992,3.33
                      c0.58,0.149,1.096,0.217,1.555,0.217c2.949,0,3.628-2.789,4.816-4.691c-0.089,1.015,0.417,3.325,2.012,3.325
                      c0.532,0,1.184-0.256,1.973-0.903c-0.444,0.05-0.782,0.07-1.039,0.07c-0.768,0-0.811-0.184-0.868-0.283
                      c-0.491-0.808,0.091-1.988,0.652-2.584c1.321-1.4,3.32-1.699,4.504-3.337c-0.175,0.893-0.646,1.796-1.252,2.472
                      c1.785-1.116,3.504-2.81,4.007-4.924c0.668,1.375-0.183,2.074-0.707,3.222c5.165-3.077,2.937-7.02-0.172-9.011
                      c2.918-0.229,4.462-4.122,2.276-5.651c0.084,1.5-1.222,2.873-2.81,2.873c-0.314,0-0.64-0.053-0.971-0.171
                      c1.964-0.716,3.813-4.604,1.384-5.195c0.435,1.42-0.838,2.529-2.198,2.529c-0.561,0-1.134-0.187-1.612-0.617
                      c1.098-1.368,0.507-2.998-0.773-2.998c-0.241,0-0.507,0.058-0.788,0.185c-0.519-1.878,0.62-1.294,2.176-1.911
                      c2.281-0.91,4.497-2.463,6.229-4.193c2.674-2.678,4.3-5.985,5.42-9.559c-0.049,0.017-0.095,0.034-0.142,0.051
                      c-0.004,0.001-0.008,0.003-0.009,0.003c-0.003,0.002-0.007,0.003-0.007,0.003s0.003-0.001,0.007-0.003
                      c0.001,0,0.004-0.001,0.005-0.001c-0.829,0.3-1.674,0.438-2.515,0.438c-4.192,0-8.272-3.426-9.885-7.114
                      C179.287,87.632,178.858,82.342,174.212,80.085 M169.289,81.233c0.006,0,0.012,0,0.016,0c1.558,1.61,2.879,5.656,2.879,5.656
                      s2.249-5.277,2.458-5.575c2.523,1.637,3.463,4.473,4.453,7.459c0.379,1.142,0.768,2.321,1.255,3.43
                      c1.619,3.699,5.91,7.63,10.677,7.63c0.449,0,0.893-0.035,1.327-0.106c-1.175,3.218-2.688,5.691-4.696,7.704
                      c-1.751,1.754-3.862,3.175-5.938,4.001c-0.37,0.147-0.716,0.212-1.051,0.276c-0.563,0.106-1.199,0.226-1.563,0.809
                      c-0.36,0.577-0.227,1.295-0.073,1.856l0.269,0.969l0.916-0.412c0.16-0.073,0.308-0.109,0.435-0.109c0.072,0,0.288,0,0.414,0.263
                      c0.132,0.273,0.129,0.787-0.312,1.335l-0.51,0.636l0.604,0.544c0.602,0.54,1.379,0.837,2.189,0.837
                      c0.825,0,1.629-0.308,2.218-0.828c-0.372,0.8-1.222,1.556-1.815,1.832c-0.622,0.291-2.125,0.809-2.125,0.809l2.248,0.803
                      c0.412,0.147,0.836,0.221,1.259,0.221c1.003,0,1.979-0.425,2.682-1.167c0.104-0.11,0.2-0.224,0.29-0.344
                      c-0.016,0.186-0.051,0.348-0.082,0.461c-0.257,0.944-1.125,2.006-2.426,2.108l-2.556,0.201l2.157,1.384
                      c1.398,0.895,2.882,2.44,2.857,4.092c-0.011,0.502-0.157,0.991-0.447,1.461c-0.049-0.27-0.141-0.558-0.292-0.867l-1.053-2.171
                      l-0.559,2.348c-0.17,0.712-0.493,1.353-0.896,1.918l0.568-2.94l-2.288,3.169c-0.535,0.744-1.323,1.179-2.156,1.64
                      c-0.778,0.433-1.587,0.879-2.273,1.611c-0.489,0.519-1.014,1.417-1.069,2.329c-0.149-0.466-0.206-0.97-0.176-1.287l0.319-3.591
                      l-1.91,3.059c-0.262,0.422-0.501,0.878-0.729,1.321c-0.867,1.669-1.525,2.745-2.896,2.935c0.551-0.828,0.849-1.822,0.84-2.664
                      l-0.021-2.159l-1.472,1.58c-1.117,1.201-2.799,2.797-4.512,2.797c-0.079,0-0.157-0.003-0.235-0.01
                      c0.318-0.188,0.549-0.415,0.712-0.622c0.591-0.755,0.759-1.882,0.46-3.09l-1.131-4.6c0,0-0.08,3.649-0.557,4.69
                      c-0.135,0.305-0.436,0.619-0.947,0.885c0.245-0.34,0.411-0.724,0.44-1.116c0.028-0.387-0.04-1.678-2.132-1.993
                      c-0.642-0.097-1.254-0.147-1.845-0.196c-2.575-0.212-3.736-0.403-4.26-2.728l-0.821-3.637l-0.859,3.628
                      c-0.093,0.392-0.08,0.811-0.003,1.235c-0.017-0.027-0.458-1.147-0.431-1.879c0.038-1.039,0.702-2.498,0.624-2.433
                      c0,0-2.105,0.904-2.677,2.076c-2.788-4.622-0.553-8.172,0.974-10.598c0.64-1.019,1.104-1.753,1.08-2.454
                      c-0.075-2.142-1.358-2.245-2.296-2.32c-0.304-0.025-0.62-0.05-0.96-0.127c-1.302-0.297-2.662-0.816-4.287-1.631
                      c-3.78-1.906-7.608-5.567-8.518-9.356c0.713,0.282,1.49,0.423,2.326,0.423c3.961,0,7.944-3.164,9.07-4.133
                      c2.248-1.936,4.014-4.302,5.723-6.592c0.776-1.041,1.58-2.117,2.408-3.125c2.579-3.132,5.605-5.438,8.997-6.853
                      c0.183-0.076,0.372-0.171,0.57-0.271C168.516,81.486,169.018,81.233,169.289,81.233"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_167_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_168_">
                      <use xlinkHref="#SVGID_167_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_168_)" fill="#FDFDFD" d="M171.338,90.926c0,0-0.038,0.23-0.023,0.25
                      c0.03,0.016,0.122,0.012,0.156-0.011c0-0.069,0.005-0.149-0.009-0.19C171.448,90.932,171.419,90.938,171.338,90.926"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_169_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_170_">
                      <use xlinkHref="#SVGID_169_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_170_)" fill="#FDFDFD" d="M171.649,91.149c0.011-0.011,0.044-0.147,0.031-0.208
                      c-0.025-0.033-0.067-0.038-0.123-0.042l-0.013,0.117C171.543,91.085,171.557,91.217,171.649,91.149"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_171_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_172_">
                      <use xlinkHref="#SVGID_171_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_172_)" fill="#FDFDFD" d="M171.429,91.563c0,0,0.021,0.087,0.047,0.091
                      c0.02,0.009,0.046-0.022,0.066-0.045C171.702,91.449,171.371,91.321,171.429,91.563"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_173_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_174_">
                      <use xlinkHref="#SVGID_173_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_174_)" fill="#FDFDFD" d="M170.584,88.941c0.278-0.035,0.523,0.21,0.409,0.525
                      c-0.092,0.204-0.327,0.378-0.524,0.408c-0.197,0.031-0.296-0.113-0.296-0.295C170.173,89.283,170.257,88.981,170.584,88.941
                      M171.198,89.647c0.115,0,0.177,0.264,0.227,0.683c0,0-0.156-0.052-0.25-0.046c-0.094,0.007-0.205,0.067-0.205,0.067
                      C170.95,90.13,171.112,89.647,171.198,89.647 M171.9,88.948c0.467,0.07,0.565,0.92,0.049,0.949
                      c-0.239,0.014-0.563-0.385-0.525-0.615C171.459,89.053,171.75,88.927,171.9,88.948 M169.838,88.833
                      c0.023-0.136,0.114-0.272,0.181-0.409c0.047-0.136,0.114-0.317,0.273-0.363c-0.045,0.137-0.069,0.137-0.114,0.25l-0.068,0.25
                      c-0.045,0.112-0.113,0.159-0.137,0.272c-0.047,0.16-0.014,0.547-0.023,0.728c-0.013,0.305-0.311,0.13-0.304,0.407
                      c0.01,0.277,0.485,0.367,0.667,0.458c0.181,0.113,0.137,0.227,0.317,0.34c0.46,0.199,1.069,0.091,1.139,0.046
                      c0.182-0.069,0.205-0.251,0.387-0.364c0.138-0.069,0.432-0.114,0.566-0.318c0.186-0.345-0.18-0.25-0.25-0.41
                      c-0.047-0.113,0.006-0.572,0.046-0.591c0.092-0.045,0.029,0.478,0.091,0.5c0.201-0.001,0.237-0.342,0.252-0.547
                      c0.043-0.635,0.015-1.757-1.611-1.8c-1.597-0.042-2.01,1.659-1.437,2.347C169.884,89.425,169.815,89.062,169.838,88.833"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_175_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_176_">
                      <use xlinkHref="#SVGID_175_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_176_)" fill="#FDFDFD" d="M157.539,115.801c-0.014,0.042-0.03,0.106-0.042,0.194
                      c-0.012,0.075-0.01,0.129-0.008,0.163c0.241,0.086,0.44,0.313,0.576,0.622c0.117,0.263,0.185,0.585,0.185,0.936
                      c0,0.88-0.449,1.568-1.02,1.568c-0.57,0-1.019-0.688-1.019-1.568c0-0.512,0.047-0.894,0.263-1.172
                      c-0.04-0.053-0.088-0.119-0.112-0.15c-0.016-0.023-0.116-0.125-0.222-0.232c-0.196,0.31-0.282,0.752-0.282,1.44
                      c0,1.102,0.631,2.182,1.275,2.182s1.422-0.892,1.422-2.001c0-0.401-0.089-0.788-0.232-1.117
                      C158.139,116.232,157.857,115.902,157.539,115.801"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_177_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_178_">
                      <use xlinkHref="#SVGID_177_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_178_)" fill="#FDFDFD" d="M158.323,114.747c-0.481,0-0.656-0.354-0.847-0.728
                      c-0.129-0.256-0.29-0.387-0.479-0.568c-0.024-0.022-0.045-0.042-0.067-0.065c-0.25-0.251-0.203-0.473-0.269-0.796
                      c-0.005-0.025,0.012-0.048,0.035-0.051c0.015-0.005,0.03-0.002,0.042,0.011c0.052,0.062,0.113,0.117,0.176,0.173
                      c0.264,0.225,0.594,0.419,0.724,0.729c0.105,0.237,0.196,0.658,0.425,0.833c-0.092-0.535-0.087-1.239-0.551-1.492
                      c-0.251-0.135-0.492-0.23-0.44-0.561c0.064-0.406,0.202-0.912,0.215-1.384c-0.198-0.48-0.437-0.947-0.726-1.382
                      c-0.219,0.157-0.314,0.541-0.355,0.864c-0.037,0.281-0.031,0.517-0.031,0.519c0,0.013-0.165-0.404-0.216-0.833
                      c-0.026-0.207-0.026-0.416,0.031-0.582c0.046-0.129,0.139-0.288,0.258-0.4c-0.106-0.137-0.223-0.273-0.345-0.398
                      c-0.435-0.458-0.86-0.513-1.108,0.175c-0.027,0.074-0.053,0.154-0.077,0.236c-0.084,0.283-0.133,0.597-0.04,0.872
                      c0.025,0.071,0.054,0.139,0.1,0.203c0.162,0.23,0.33,0.442,0.483,0.677c0.014,0.022-0.003,0.044-0.024,0.048
                      c-0.01,0.003-0.021,0.001-0.03-0.009c-0.25-0.293-0.624-0.573-0.76-0.952c-0.008-0.023-0.019-0.045-0.024-0.069
                      c-0.077-0.275-0.064-0.578-0.018-0.872c0.02-0.134,0.044-0.266,0.076-0.393c0.211-0.904,1.28-0.798,1.469-0.617
                      c0.498,0.303,0.874,0.857,1.162,1.323c0.448,0.731,0.745,1.611,0.598,2.471c-0.01,0.051-0.075,0.063-0.092,0.01
                      c-0.014-0.04-0.027-0.079-0.041-0.118c-0.012,0.111-0.028,0.215-0.038,0.307c-0.006,0.041-0.05,0.365-0.063,0.365
                      c0.091,0.044,0.189,0.082,0.272,0.133c0.163,0.1,0.346,0.221,0.436,0.395c0.254,0.497,0.308,1.188,0.344,1.739
                      C158.531,114.669,158.42,114.747,158.323,114.747 M158.085,109.362c-0.154-0.361-0.302-0.764-0.523-1.09
                      c-0.116-0.171-0.246-0.331-0.375-0.492c-0.121-0.095-0.243-0.181-0.363-0.255c-0.395-0.246-0.902-0.595-1.369-0.696
                      c-0.553-0.119-0.966,0.44-1.239,0.841c-0.36,0.537-0.559,1.152-0.621,1.792c-0.04,0.442-0.137,0.992,0.222,1.337
                      c0.418,0.397,0.916,0.701,1.358,1.071c0.387,0.322,0.702,0.732,0.846,1.188c0.078-0.448,0.078-0.897-0.141-1.352
                      c-0.005-0.01,0.006-0.02,0.013-0.017c0.003,0.001,0.005,0.003,0.01,0.005c0.371,0.553,0.413,1.1,0.331,1.758
                      c-0.028,0.235-0.184,1.338-0.064,1.837c0.13,0.539,0.384,1.187,0.921,1.453c0.254,0.126,0.504,0.144,0.746,0.099
                      c-0.117-0.252-0.283-0.43-0.474-0.473l-0.057-0.014l-0.023-0.052c-0.009-0.019-0.049-0.122-0.021-0.336
                      c0.035-0.25,0.099-0.353,0.112-0.371l0.042-0.063l0.076,0.014c0.426,0.077,0.804,0.468,1.042,1.001
                      c0.027-0.018,0.056-0.034,0.083-0.052c0.252-1.43,0.314-2.953,0.184-4.393C158.713,111.146,158.464,110.247,158.085,109.362"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_179_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_180_">
                      <use xlinkHref="#SVGID_179_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_180_)" fill="#FDFDFD" d="M161.893,104.307c1.232-0.657,1.095-1.369,2.694-2.023
                      c-1.469-0.54-1.759,0.447-2.819,1.073c-1.21,0.716-1.499-0.913-0.78-1.576c0.595-0.548,1.81-1.375,2.839-1.553
                      c1.974-0.338,3.782,0.623,4.937,2.306c0.858,1.247,2.195-0.113,1.974-0.636c0.662,0.922-0.098,2.981-1.268,2.303
                      c0.034,0.762,0.139,1.45,0.017,2.22c-0.963-2.658-1.306-1.855-3.495-1.701C164.935,104.796,162.719,105.271,161.893,104.307
                      M170.993,111.52c-0.118,0.186-1.89-0.482-1.806-0.861c0.065-0.305,0.434-0.136,0.642-0.055
                      C170.155,110.731,171.195,111.197,170.993,111.52 M168.859,111.868c-3.022-2.013,1.465-4.082,1.736-2.1
                      c-0.991-0.974-3.166,0.624-1.447,1.61c1.332,0.764,2.712,0.921,3.752-0.226c0.545-0.292,0.362-1.189,0.257-1.691l0.007-0.001
                      c-0.324-1.582,0.284-2.902-0.606-4.339c-0.755-1.22-0.63-2.625-0.423-3.973c-0.011,0.067-2.944-1.232-3.289-1.361
                      c-2.314-0.874-3.576-1.21-5.865-0.202l-2.694,1.769c-1.226,1.119-1.317,1.754-1.097,3.413c0.099,0.758,1.684,1.916,1.689,2.028
                      c0.104,1.793,0.06,3.913-0.264,5.681c1.473-0.766,2.377,0.58,1.689,1.357c-0.184,0.208-0.038-0.207-0.079-0.28
                      c-0.508-0.959-1.934,0.745-0.719,1.448c0.957,0.556,2.059-0.478,2.556-1.133C165.141,112.448,166.3,110.163,168.859,111.868"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_181_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_182_">
                      <use xlinkHref="#SVGID_181_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_182_)" fill="#FDFDFD" d="M173.474,94.145c0.015,0.273-0.293,0.511-0.521,0.488
                      c-0.091-0.023-0.297-0.137-0.363,0c-0.082,0.166,0.106,0.266,0.204,0.295c0.296,0.088,0.588-0.083,0.681-0.129
                      c0.353-0.177,0.377-0.584,0.393-0.628c0.933,0.072,0.735,0.072,1.539,0.191c0.417,0.063,0.463-0.551,0.185-0.684
                      c-0.275-0.133-0.743,0.045-1.375-0.063c0.249-0.525,0.49-0.551,0.58-0.574c-0.046-0.068-0.239-0.446-0.59-0.167
                      c-0.206,0.163-0.137,0.344-0.373,0.659c0,0-4.344-0.394-7.791-5.312C165.653,89.167,168.693,93.579,173.474,94.145"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_183_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_184_">
                      <use xlinkHref="#SVGID_183_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_184_)" fill="#FDFDFD" d="M171.156,91.449c-0.067,0.045-0.07,0.13-0.046,0.172
                      c0.026,0.043,0.083,0.043,0.113,0.032c0.022-0.012,0.059-0.067,0.061-0.12C171.286,91.482,171.259,91.438,171.156,91.449"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_185_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_186_">
                      <use xlinkHref="#SVGID_185_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_186_)" fill="#FDFDFD" d="M172.299,93.018c3.912-1.974,4.225-4.571,4.179-4.685
                      c-0.34,0.477-2.209,2.979-5.16,4.308C171.672,92.82,172.179,92.97,172.299,93.018"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_187_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_188_">
                      <use xlinkHref="#SVGID_187_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_188_)" fill="#FDFDFD" d="M172.257,116.841c0.033-0.148,0.04-0.3,0.029-0.45
                      c-0.014-0.254-0.021-0.52-0.178-0.734c-0.087-0.123-0.357-0.299-0.522-0.312c-0.115,0.188-0.211,0.386-0.264,0.604
                      c-0.07,0.294-0.021,0.56-0.043,0.858c0.03,0.02,0.053,0.047,0.062,0.09c0.174,0.035,0.354,0.068,0.53,0.079
                      C172.013,116.986,172.219,117.038,172.257,116.841"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_189_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_190_">
                      <use xlinkHref="#SVGID_189_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_190_)" fill="#FDFDFD" d="M166.982,93.411c-0.232-0.027-0.487,0.021-0.473,0.385
                      c0.012,0.285,0.289,0.341,0.385,0.334l0.991-0.025l0.557-0.038c0.035,0.263,0.192,0.474,0.372,0.588
                      c0.205,0.136,0.387,0.226,0.638,0.158c0.136-0.045,0.271-0.136,0.205-0.294c-0.069-0.16-0.319,0-0.479-0.045
                      c-0.146-0.064-0.312-0.261-0.337-0.433c0,0,0.689,0.016,2.149-0.459c-0.263-0.124-1.016-0.518-1.016-0.518
                      s-0.91,0.236-1.529,0.31c-0.045-0.044-0.137-0.154-0.176-0.241c-0.067-0.159-0.067-0.387-0.182-0.5
                      c-0.206-0.159-0.477-0.023-0.545,0.205c0.352,0.092,0.417,0.35,0.528,0.579C168.069,93.415,167.36,93.456,166.982,93.411"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_191_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_192_">
                      <use xlinkHref="#SVGID_191_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_192_)" fill="#FDFDFD" d="M170.863,91.016c0,0-0.014-0.104-0.031-0.113c-0.021-0.008-0.061,0-0.082,0
                      c-0.178,0.081,0.044,0.386,0.113,0.182V91.016L170.863,91.016z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_193_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_194_">
                      <use xlinkHref="#SVGID_193_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_194_)" fill="#FDFDFD" d="M167.339,98.465c4.187,1.304,7.856,3.401,11.414,5.943
                      C178.241,101.939,175.115,97.901,167.339,98.465"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_195_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_196_">
                      <use xlinkHref="#SVGID_195_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_196_)" fill="#FDFDFD" d="M171.132,90.937c-0.046,0.044-0.065,0.193,0.003,0.239
                      c0.042,0.011,0.113,0.021,0.136-0.07V91.04c0,0,0-0.074-0.022-0.114C171.224,90.926,171.154,90.915,171.132,90.937"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_197_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_198_">
                      <use xlinkHref="#SVGID_197_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_198_)" fill="#FDFDFD" d="M170.906,91.539c0,0.023,0.024,0.069,0.047,0.092
                      c0.093,0.046,0.137-0.182,0.068-0.205c-0.046-0.024-0.091,0-0.138,0L170.906,91.539z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_199_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_200_">
                      <use xlinkHref="#SVGID_199_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_200_)" fill="#FDFDFD" d="M171.071,91.152l-0.004-0.136c-0.023-0.09-0.092-0.112-0.138-0.067
                      c-0.024,0.045-0.024,0.159-0.024,0.204C170.967,91.186,171.071,91.152,171.071,91.152"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_201_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_202_">
                      <use xlinkHref="#SVGID_201_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_202_)" fill="#FDFDFD" d="M170.702,91.403c0,0.058,0.006,0.126,0.028,0.161
                      c0.023,0.034,0.108,0.038,0.132,0.022c0.017-0.02-0.018-0.109-0.04-0.134C170.804,91.427,170.771,91.415,170.702,91.403"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_203_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_204_">
                      <use xlinkHref="#SVGID_203_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_204_)" fill="#FDFDFD" d="M170.521,91.403c-0.115-0.204,0.045-0.409-0.206-0.659
                      c-0.045-0.022-0.114-0.089-0.16-0.091c-0.099-0.005-0.037,0.28-0.029,0.497c0.015,0.419,0.032,0.45,0.259,0.639
                      c0.089,0.137,0.158,0.274,0.317,0.342c0.205,0.069,0.887,0.069,1.048-0.022c0.157-0.091,0.461-0.52,0.476-0.637
                      c0.015-0.119,0.019-0.383,0.093-0.772c-0.069,0.022-0.159,0.022-0.229,0.067c-0.228,0.16-0.113,0.546-0.317,0.796
                      c-0.092,0.114-0.295,0.158-0.454,0.158C171.021,91.721,170.656,91.699,170.521,91.403"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_205_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_206_">
                      <use xlinkHref="#SVGID_205_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_206_)" fill="#FDFDFD" d="M170.687,118.964c0.324-0.326,0.373-1.002,0.37-1.479
                      c-0.281,0.037-0.65,0.03-0.923-0.032c0.003,0.235,0.009,0.466,0.063,0.707C170.223,118.276,170.453,119.198,170.687,118.964"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_207_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_208_">
                      <use xlinkHref="#SVGID_207_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_208_)" fill="#FDFDFD" d="M168.964,118.614c-0.235-0.452-0.299-0.972-0.168-1.467
                      c-0.038-0.011-0.073-0.027-0.106-0.044c-0.02,0.027-0.058,0.05-0.094,0.044c-0.214-0.035-0.405-0.031-0.598,0.042
                      C168.008,118.282,168.719,118.533,168.964,118.614"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_209_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_210_">
                      <use xlinkHref="#SVGID_209_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_210_)" fill="#FDFDFD" d="M172.553,117.441c-0.004,0.029-0.073,0.428,0.021,0.717
                      c0.036,0.116,0.049,0.263,0.099,0.403c0.008-0.002,0.014-0.003,0.025-0.006c0.409-0.083,0.499-0.758,0.606-1.114
                      C173.081,117.462,172.747,117.468,172.553,117.441"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_211_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_212_">
                      <use xlinkHref="#SVGID_211_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_212_)" fill="#FDFDFD" d="M173.48,119.306c0.081,0.034,3.181,0.353,2.686,1.292
                      c0.089-0.42-2.58-1.125-5.288-0.615c-1.745,0.327-3.942-0.348-4.845-1.421c-0.612-0.73-0.716-0.94-1.191-1.911
                      c-0.382,1.892,0.295,4.742,2.697,6.967c1.114,1.032,3.057,0.44,1.998-0.235c-0.455-0.289-1.634-1.121-1.867-1.501
                      c0.714,0.092,2.119-0.922,4.115-1.153c1.875-0.218,3.246-0.243,3.851,1.045c-0.603-0.446-1.814-0.25-1.826-0.265
                      c0.688,0.861,1.166,0.701,2.217,0.555c0.488-0.067-0.611,0.7-1.539,0.578c-0.567-0.075-0.214,1.122-0.096,1.679
                      c0.886-0.358,1.441-0.255,2.073-0.819c1.347-1.182,0.76-7.928,0.079-8.504C176.488,117.323,175.572,119.002,173.48,119.306"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_213_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_214_">
                      <use xlinkHref="#SVGID_213_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_214_)" fill="#FDFDFD" d="M173.48,116.579c-0.007-0.339-0.086-0.621-0.187-0.917
                      c-0.121-0.106-0.239-0.221-0.347-0.34c-0.231,0.526-0.396,1.021-0.421,1.62C172.922,117.002,173.493,117.086,173.48,116.579"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_215_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_216_">
                      <use xlinkHref="#SVGID_215_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_216_)" fill="#FDFDFD" d="M168.453,115.942c-0.012-0.098-0.03-0.196-0.05-0.294
                      c-0.156,0.023-0.311,0.042-0.466,0.056c-0.02,0.067-0.038,0.137-0.045,0.209c0.041,0.243,0.04,0.501,0.026,0.738
                      c0.184-0.048,0.368-0.047,0.558-0.024c0.001-0.026,0.002-0.051,0.005-0.076c-0.05-0.156-0.056-0.338-0.04-0.492
                      C168.443,116.024,168.448,115.984,168.453,115.942"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_217_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_218_">
                      <use xlinkHref="#SVGID_217_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_218_)" fill="#FDFDFD" d="M169.097,117.332c0.072,0.368,0.054,0.753,0.211,1.104
                      c0.055,0.118,0.109,0.238,0.184,0.345c0.104,0.146,0.15,0.143,0.308,0.107c0.026-0.006,0.048,0,0.065,0.013
                      c0.07-0.188,0.072-0.439,0.111-0.59c-0.093-0.244-0.079-0.823-0.079-0.888C169.897,117.356,169.586,117.378,169.097,117.332"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_219_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_220_">
                      <use xlinkHref="#SVGID_219_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_220_)" fill="#FDFDFD" d="M167.538,116.266c-0.012-0.285-0.028-0.597-0.085-0.887
                      c-0.092,0.016-0.183,0.028-0.273,0.04c-0.01,0.153-0.019,0.308-0.007,0.462C167.195,116.155,167.298,116.293,167.538,116.266"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_221_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_222_">
                      <use xlinkHref="#SVGID_221_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_222_)" fill="#FDFDFD" d="M167.879,118.27c0.099,0.063,0.181,0.024,0.25-0.042
                      c-0.096-0.089-0.176-0.194-0.232-0.296c-0.216-0.38-0.276-0.843-0.123-1.246c-0.011,0.005-0.357,0.093-0.562-0.083
                      C167.264,117.532,167.699,118.15,167.879,118.27"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_223_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_224_">
                      <use xlinkHref="#SVGID_223_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_224_)" fill="#FDFDFD" d="M166.646,116.246c0.079,1.548,0.531,1.505,0.634,1.499
                      c-0.005-0.008-0.01-0.015-0.014-0.023c-0.243-0.413-0.446-1.001-0.347-1.498C166.899,116.234,166.724,116.289,166.646,116.246"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_225_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_226_">
                      <use xlinkHref="#SVGID_225_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_226_)" fill="#FDFDFD" d="M179.709,108.008c-0.247-0.389-0.954-2.312-1.053-2.393
                      c-0.876,2.107-3.215,2.539-5.01,1.265c0.067,0.833-0.138,1.707,0.053,2.533c0.247,0.705,0.121,1.304-0.382,1.795
                      c1.709-1.282,1.176-0.012-0.118,0.604c-0.339,0.159-0.658,0.321-0.986,0.487c0.771-0.228,1.702-0.174,2.632-1.173
                      c0.865-0.932-0.383-1.563-0.578-1.413c0.506-0.795,2.536,0.718,0.964,1.589c0.995-0.01,1.854,0.898,2.244,1.39
                      c0.09-0.659,0.757-2.107,1.546-2.107C179.793,109.815,180.377,109.061,179.709,108.008"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_227_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_228_">
                      <use xlinkHref="#SVGID_227_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_228_)" fill="#FDFDFD" d="M174.902,116.829c0.042,0.015,0.079,0.045,0.093,0.09
                      c0.004-0.005,0.008-0.011,0.016-0.015c0.061-0.114,0.09-0.235,0.082-0.365c0.01-0.16,0.008-0.317-0.003-0.476
                      c-0.032,0.058-0.075,0.106-0.125,0.137c0.021,0.179,0.012,0.355-0.038,0.547C174.917,116.776,174.91,116.802,174.902,116.829"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_229_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_230_">
                      <use xlinkHref="#SVGID_229_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_230_)" fill="#FDFDFD" d="M173.671,115.361c0.088,0.31,0.102,0.645,0.02,0.886
                      c-0.004,0.011-0.012,0.264-0.019,0.274c0.184,0.047,0.362-0.058,0.548,0c0.023-0.29-0.082-0.598-0.034-0.883
                      C174.009,115.565,173.837,115.47,173.671,115.361"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_231_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_232_">
                      <use xlinkHref="#SVGID_231_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_232_)" fill="#FDFDFD" d="M171.329,117.515c-0.01,0.384-0.034,0.869,0.145,1.192
                      c0.182,0.333,0.45-0.264,0.496-0.415c0.067-0.218,0.119-0.49,0.187-0.709C171.894,117.63,171.599,117.567,171.329,117.515"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_233_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_234_">
                      <use xlinkHref="#SVGID_233_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_234_)" fill="#FDFDFD" d="M173.672,116.998c0,0-0.007,0.271,0.021,0.432
                      c0.021,0.124,0.046,0.267,0.091,0.402c0.235-0.255,0.341-0.538,0.437-0.859C174.036,116.923,173.672,116.998,173.672,116.998"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_235_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_236_">
                      <use xlinkHref="#SVGID_235_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_236_)" fill="#FDFDFD" d="M174.392,116.91c0.062,0.099,0.011,0.226,0.056,0.339
                      c0.027,0.074,0.063,0.033,0.104-0.034c0.124-0.19,0.18-0.429,0.207-0.667c-0.005-0.006-0.01-0.014-0.014-0.022
                      c-0.014-0.034-0.022-0.069-0.022-0.102c-0.012,0.005-0.026,0.007-0.044,0.009c-0.072,0.008-0.154,0.001-0.241-0.013
                      C174.452,116.59,174.437,116.753,174.392,116.91L174.392,116.91L174.392,116.91z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_237_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_238_">
                      <use xlinkHref="#SVGID_237_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_238_)" fill="#FDFDFD" d="M174.496,116.075c0.024-0.037,0.053-0.077,0.075-0.116
                      c0.026-0.047,0.053-0.093,0.079-0.14c-0.018-0.127-0.009-0.325-0.049-0.495c-0.07-0.015-0.14-0.031-0.21-0.054
                      c0.01,0.085,0.015,0.165,0.022,0.191c0.036,0.19,0.073,0.39,0.068,0.585C174.485,116.048,174.493,116.069,174.496,116.075"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_239_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_240_">
                      <use xlinkHref="#SVGID_239_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_240_)" fill="#FDFDFD" d="M170.868,115.406c-0.096,0.105-0.568,0.293-0.674,0.388
                      c-0.086,0.259-0.158,0.506-0.164,0.803c-0.011,0.444,0.49,0.37,0.838,0.318C170.844,116.39,170.933,115.863,170.868,115.406"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_241_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_242_">
                      <use xlinkHref="#SVGID_241_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_242_)" fill="#FDFDFD" d="M166.044,104.48c0.176-0.022,1.305-0.021,1.362-0.368
                      c-0.62-0.242-1.164-0.637-1.74-0.828C166.048,103.537,166.263,104.031,166.044,104.48"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_243_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_244_">
                      <use xlinkHref="#SVGID_243_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_244_)" fill="#FDFDFD" d="M164.739,103.204c-0.482,0.086-1.011,0.393-1.635,1.088
                      c-0.207,0.358,1.018,0.342,1.355,0.329C164.051,104.148,164.241,103.447,164.739,103.204"/>
                  </g>
                </g>
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_245_" x="-17.547" y="19.18" width="374.38" height="264.712"/>
                    </defs>
                    <clipPath id="SVGID_246_">
                      <use xlinkHref="#SVGID_245_"  overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#SVGID_246_)" fill="#FDFDFD" d="M164.926,103.554c0.101,0.019,0.187,0.074,0.224,0.184
                      c0.036,0.107-0.02,0.278-0.202,0.285c-0.183,0.006-0.279-0.158-0.254-0.292C164.716,103.621,164.824,103.536,164.926,103.554"/>
                  </g>
                </g>
              </g>
              </svg>
                {/* <img src={Logo} width="120" height="120" alt="Captain Candy" /> */}
            </div>
        </div>
    </div>
  )
}

export default Loader;

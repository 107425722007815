import React from 'react';
// import collageCZ from "../../assets/img/team/Collage_CZ.svg";
// import cz from "../../assets/img/team/cz.png";
// import rope from "../../assets/img/newsletter-rope.png";
// import frame from "../../assets/img/team/fream-1.png";

import '../HpAbout/about.css';
import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';
import { aboutUs } from '../../assets/dummyData/About';
// import { team, teamCZ, teamDE, teamNL, teamIT } from '../../assets/dummyData/team';

import "./team.css";
// import { Team } from './Team';
// import { TeamGroup } from './TeamGroup';
// import { TeamOnly } from './TeamOnly';


const About = () => {
    const elementsToAnimate = ["title"];
    const { singleRefs } = AnimationEffects(elementsToAnimate, aboutUs, 'opacity', 0.4, 0.12);

  return (
    <div className='w-100'>
        <section className="team-wrapper">
            <div className="content">
                {aboutUs.map((el, i) => (
                    <p key={el.id} className={`${el.class} opacity-0`} ref={singleRefs.items[i].ref}>
                        {el.data}
                        <br />
                        <br />
                    </p>
                ))}
            </div>
        </section>
    </div>
  )
}

export default About;
import { Fragment } from "react";
const newsletter = {
    en: {
        title: "WANT TO BE THE FIRST?",
        subttl: "Back in the olden days, you couldn’t get hold of exclusive information without having to undergo torture, walk the plank or be subjected to any keelhauling. These days you just need to send us an e-mail and hey presto!",
        placeholder: "Enter your e-mail address",
        button: "Sing up",
        error: "Body of a thousand whales! This email address has already been entered by someone else! Enter another email address.",
        emailExist: <Fragment>Confound it, this e-mail was entered by somebody before you! <br />Enter another e-mail, please.</Fragment>
    },
};

export {
    newsletter
};
import { Fragment } from "react";

const faqData = {
    title: {
        en: "Frequently asked questions",
    },
    en: [
    	{
            id: "1",
            title: " Where do the sweets in Captain Candy’s stores come from?", 
            content: "Captain Candy’s ships travel all over Europe (from Spain to Germany, from Sweden to Italy) to the very best suppliers for candy. All you have to do is look at the barrel or the box and that will tell you where the particular candy has sailed from."
        },
        {
            id: "2",
            title: " What is the shelf life of the candy?", 
            content: "As we pirates say, “You can find the answer to everything in a barrel”. In this case not actually in the barrel, but on the barrel. The candy’s shelf life is written on each of them.",
        },
        {
            id: "3",
            title: "How should the candy be stored after it has been bought?", 
            content: "In the same way as supplies on pirate expeditions - if possible in dry and cold conditions",
        },
        {
            id: "4",
            title: "Is it okay that the candy in the stores lies in open barrels?", 
            content: "Although pirates often lived outside the law, everything’s all right here. It’s called “free sale” and doesn’t break hygiene rules in any way. It’s like when you put bread in a bag in a port’s “super” market…",
        },
        {
            id: "5",
            title: "Where does Captain Candy come from?", 
            content: "The first Captain Candy store was opened in Prague and after this success we decided to expand further. You can currently find us in several countries and we are planning more!",
        },
        {
            id: "6",
            title: "Why can’t we try the candy in the barrels?", 
            content: "Unfortunately, it’s not possible for hygiene reasons. But don’t be afraid to ask our crews in the stores – they will be happy to help you find the exact taste you are looking for.",
        },
        {
            id: "7",
            title: "Is it hygienic to put tongs on barrels? ", 
            content: "The old pirate rule states that “tongs belong on a barrel”. What’s more, here too we respect the law which doesn’t see any problem with this.",
        },
        // {
        //     id: "8",
        //     title: "How many stores does Captain Candy have?", 
        //     content: "So far we have dropped anchor in four places in Prague and we have two harbours each in Dubrovnik in Croatia and in Venice in Italy. And we are planning more expeditions!",
        // },
        {
            id: "9",
            title: "Does the Captain Candy concept also work as a franchise?", 
            content: 'Yes, the Captain Candy concept successfully operates several franchise stores. If you are interested in cooperation, please contact us via the form in the "Franchising" section.',
        },
        {
            id: "10",
            title: "Are the barrels really completely full?", 
            content: "Pirates must have some secrets. And that’s why we’ll leave it to you to guess.",
        },
        {
            id: "11",
            title: "Which is the most popular candy?", 
            content: "Not even the famous sorceress from the Island of Crystal Balls could answer that. Everyone chooses whatever suits their taste and the best-selling candy often changes. If you look at the barrels in the stores you will find out more.",
        },
        {
            id: "12",
            title: "What are the stores’ opening hours?", 
            content: <Fragment>From Monday to Friday we take in every traveller from 10 in the morning till 11 at night. At weekends between 10 o’clock in the morning and midnight. You can look in detail here. <a href="/stores">here</a>.</Fragment>,
        },
        {
            id: "13",
            title: " Why isn’t the price of the candy written on each barrel?", 
            content: " Because there’s no need. ;) The price per 100 g is uniform for the entire selection and you will always find it written in the store.",
        },
    ],
};

const dataProcessing = {
    en: {
        title: "Personal data processing",
        parag: [
            `In order to protect personal data, I agree, as a personal data subject, to the collection, storage and processing of the following personal data: name, surname, e-mail address, residential address, date of birth, telephone number mobile phone, information on educational qualifications and other information voluntarily provided in the questionnaire sent, by the owner of the personal data, namely: Tresher s.r.o., registered office: Prague - Na Maninách 1040/14, Holešovice (Prague 7), 170 00 Prague, tax code 24217280 , VAT number CZ24217280, registered at the Court of Prague, deeds no. 189598 C ("owner").`,
            "This consent is granted for an indefinite period until the subject withdraws his/her consent.",
            "The purpose of the processing of the personal data of the interested party established by the owner is to use the personal data only for the possible establishment of a commercial cooperation with the interested party.",
            "The owner declares that he will collect personal data to the extent necessary for the fulfillment of the aforementioned purposes and that he will only process them in accordance with the purpose for which they were collected. The holder declares that the personal data will be processed in the following way: a) mechanically (automatically), by computer and software within the information system; b) manually.",
            "Furthermore, I declare that I have been informed and know the owner's privacy policy.",
        ]
    },
}

export {
    faqData,
    dataProcessing,
};
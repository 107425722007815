import { Fragment } from "react";
import { Link } from "react-router-dom";
const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const franchisingData = {
    en: {
        title: "Captain Candy Franchising",
        history: [
            {
                question: "Captain Candy concept caught your eye?",
                answer: <Fragment>Captain Candy is a successful and proven concept operating a network of candy shops. Our shops are so unique that in many cases they have been the subject of illegal copying. However, there is only one original Captain Candy. In our stores, we do not only focus on a wide selection of the most delicious candies, but the overall customer experience is just as important to us.  <br />With a Captain Candy franchise, you can offer your customers the joy of discovery and the excitement of returning to their childhood. <br />For more information, please fill out the questionnaire below.</Fragment>,
            },
            {
                question: "Keen to spread the joy of returning to being a kid to others around you?",
                answer: " Check out what you can gain from a Captain Candy franchise and get in touch. We can then talk through the rest in person.",
            },
        ],
        list: [
            {
                headingtitle: "Why work with us as a franchising partner?",
                headingLi: [
                    'Our concept boasts its own unique style',
                    "We offer quality of an international calibre",
                    "We’re constantly developing and improving",
                    "We’re a tried and tested European brand",
                ],
            },
            {
                headingtitle: "What we offer franchising partners?",
                headingLi: [
                    "The security of working for an international brand",
                    "Tried and tested sales know-how, which is constantly being innovated",
                    "Well-thought out, tried and tested and ready-made solutions",
                    "Help not only before opening your store and in the early stages, but also any time further down the line",
                    "The opportunity for personal development and self-realisation in getting your business off the ground",
                    "The chance to contribute to the development and growth of the brand",
                    "Stocking up with products and the necessary equipment to operate a franchise",
                    "System of regular checks and consultations",
                    "Long-term income and appreciation of your investment",
                    "Mutual agreement on a specific form of cooperation",
                ]
            }
        ],
        listParagraphsTitle: "How to get a Captain Candy franchise",
        listParagraphs:  [
            "We see franchising as a longstanding and mutually beneficial relationship, a bit like accepting another person into our business family. We therefore take great care to ensure our selection process is fair, thorough and prudent.",
            " For this reason anyone interested in applying is asked to complete a QUESTIONNAIRE to share preliminary (and non-binding) information with us, which allows us to ascertain whether our future collaboration is built on sufficiently solid foundations and has real prospects for success. We’ll get in touch after reading through your responses.",
            <Fragment><i> By sending your responses, you are automatically entered onto our database of people interested in working with us. We treat any information contained in the questionnaire in accordance with the General Data Protection Regulation (GDPR) and use it exclusively for the purpose of potential collaboration with you.</i></Fragment>,
        ],
        questionTitle: "QUESTIONNAIRE",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname*",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail*",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text_1",
                name: "contactform_text_1",
                label: "Why do you want to work with Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_2",
                name: "contactform_text_2",
                label: "How did you find out about Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_3",
                name: "contactform_text_3",
                label: "What is your current professional experience?*",
                textarea: true,
            },
            {
                id: "file_upload",
                name: "file_upload",
                label: "Please attach a detailed CV*",
                button: "Browse"
            },
            {
                id: "contactform_text_4",
                name: "contactform_text_4",
                label: "Do you have any experience running a small business? If o what?*",
                textarea: true,
            },
            {
                id: "contactform_text_5",
                name: "contactform_text_5",
                label: "Do you work for any other brands?*",
                textarea: true,
            },
            {
                id: "contactform_text_6",
                name: "contactform_text_6",
                label: "Do you have any other commitments or things that would restrict you from this work? Have you signed a competition clause preventing you from working with a particular brand or concept?*",
                textarea: true,
            },
            {
                id: "contactform_text_7",
                name: "contactform_text_7",
                label: "Do you or have you (or anyone close to you) previously run a franchise?",
                textarea: true,
            },
            {
                id: "contactform_text_8",
                name: "contactform_text_8",
                label: "If you’ve never run your own business before, what is your motivation for starting one now?",
                textarea: true,
            },
            {
                id: "contactform_text_9",
                name: "contactform_text_9",
                label: "Do you have a clean criminal record and are you prepared to supply proof of this?*",
                textarea: true,
            },
            {
                id: "contactform_text_10",
                name: "contactform_text_10",
                label: "To what degree can you take part in the day to day running of the store?*",
                textarea: true,
            },
            {
                id: "contactform_text_11",
                name: "contactform_text_11",
                label: "What makes you think that Captain Candy will be a success in your city, country or region?",
                textarea: true,
            },
            {
                id: "contactform_text_12",
                name: "contactform_text_12",
                label: "Where do you want to open your premises? What town, big city, region or country interest you in terms of running Captain Candy franchising stores?*",
                textarea: true,
            },
            {
                id: "contactform_text_13",
                name: "contactform_text_13",
                label: "Do you have your own/rented sales spaces to run your Captain Candy store? How big is it, what are the floor plans and where is it? How long will you have it for?",
                textarea: true,
            },
            {
                id: "contactform_text_14",
                name: "contactform_text_14",
                label: "What level of funds are you prepared to invest in the Captain Candy project? How do you plan to finance construction, furnishings and running costs during the start-up phase? How much can you source from your own funds?*",
                textarea: true,
            },
        ],
        link: <Fragment>“I consent to the  <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send"
    },
};

const inputsLabel = {
    contactform_fullName: "Name and surname",
    contactform_email: "E-mail",
    contactform_phone: "Telephone number",
    contactform_text_1: "Why do you want to work with Captain Candy?",
    contactform_text_2: "How did you find out about Captain Candy?",
    contactform_text_3: "What is your current professional experience?",
    contactform_text_4: "Do you have any experience running a small business? If o what?",
    contactform_text_5: "Do you work for any other brands?",
    contactform_text_6: "Do you have any other commitments or things that would restrict you from this work? Have you signed a competition clause preventing you from working with a particular brand or concept?",
    contactform_text_7: "Do you or have you (or anyone close to you) previously run a franchise?",
    contactform_text_8: "If you’ve never run your own business before, what is your motivation for starting one now?",
    contactform_text_9: "Do you have a clean criminal record and are you prepared to supply proof of this?",
    contactform_text_10: "To what degree can you take part in the day to day running of the store?",
    contactform_text_11: "What makes you think that Captain Candy will be a success in your city, country or region?",
    contactform_text_12: "Where do you want to open your premises? What town, big city, region or country interest you in terms of running Captain Candy franchising stores?",
    contactform_text_13: "Do you have your own/rented sales spaces to run your Captain Candy store? How big is it, what are the floor plans and where is it? How long will you have it for?",
    contactform_text_14: "What level of funds are you prepared to invest in the Captain Candy project? How do you plan to finance construction, furnishings and running costs during the start-up phase? How much can you source from your own funds?",
    createdAt: "Created at"   
};

export {
    franchisingData,
    inputsLabel,
};
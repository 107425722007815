import React from 'react';
import { Navigate } from 'react-router-dom';
import Token from './Token';

const PrivateRoute = ({ children }) => {
  const token = Token();
  if (!localStorage.getItem('token') && token.activeToken) {
    return <Navigate to="/" replace />
  }
  return children
}

export default PrivateRoute;

import React from 'react';

export const SentEmail = ({sendAnotherEmail}) => {
  return (
    <div className="msg-send" id="contact_form_send_success">
        <span className="ttl">
            Successfully sent!
        </span>
        <p>
            A bottle with your message in it is already on its way to us.
        </p>
        <p>
            Fear not, no torture awaits, but unless you confirm your registration in the e-mail we just pinged your way, we’re under strict orders from the royal fleet to steer clear…
        </p>
        <button type="button" onClick={() => sendAnotherEmail(true)} className="btn btn-primary">Send another</button>
    </div>
  )
}

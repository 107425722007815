import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { emailConfirmData } from '../../assets/dummyData/emailStatus';
import { axiosLink } from '../../axiosLink';

function ConfirmEmail() {
    const lang = "en";
    const data = emailConfirmData[lang];
    const [searchParams] = useSearchParams();
    const x = searchParams.get("token");

    useEffect(() => {
        axios.put(
            `${axiosLink}/mail/confirm`,
            { state: "nl" },
            {
                headers: {
                    token: x,
                }
            }).then((data) => {
                console.log("wbnmrcdgfmpykkjm", data);
            }).catch((err) => {
                console.log("err", err);
            })
    }, [x]);
    
    return (
        <>
            <div id="page-kontakt" className="page-plain" style={{ padding:" 40px 0 80px 0", minHeight: "70vh" }}>
                <div className="container-custom">         
                    <div className="msg-send" id="page-faq" style={{ maxWidth: "800px" }}>
                        <span className="ttl">{data?.title}</span>
                        <p>{data?.subttl}</p>
                        <br /> 
                        <p>{data?.subttl1}</p>
                        <br />  
                        <p>{data?.subttl2}</p>
                        <Link to="/" onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="btn btn-primary">{data?.buttonName}</Link>
                    </div>
                </div>
            </div>

        {/* <div id="page-kontakt" className="page-plain" style={{ padding: "40px 0", minHeight: "70vh" }}>
        <div className="container-custom">         
            <div className="msg-send" id="page-faq" style={{ maxWidth: "800px" }}>
                <span style={{
                fonSsize: "35px",
                lineHeight: "40px",
                fontWeight: "bold",
                display: "block",
                margin: "0 0 30px 0",
                }}>
                    Chyba
                </span>
                <p>
                    Omlouváme se, ale něco se pokazilo. Zkuste to prosím znovu, nebo nás <a href="/kontakt">kontaktujte</a>.
                </p>
                <a href="/" className="btn btn-primary">Pokračovat</a>
            </div>
        </div>
        </div> */}
        </>
    )
}

export default ConfirmEmail
import { Fragment } from "react";
import img1 from "../img/products/1-xs-min.png"
import img2 from "../img/products/2-xs-min.png"
import img3 from "../img/products/3-xs-min.png"
import img4 from "../img/products/4-xs-min.png"
import img5 from "../img/products/5-xs-min.png"
import img6 from "../img/products/6-xs-min.png"

const productsItems = {
    headTitle: {
        en: <Fragment>Hundreds of shapes, even more flavours <br className="hidden-xs" /> There are thousands of flavours in the world. Everyone has the chance to discover their favourite!</Fragment>,
    },
    headSubTitle: {
        en: "We believe everyone deserves to discover sweets they’ll want to devour. This is why we choose the very best ingredients and the most reliable suppliers. Take a peek inside our barrels!",
    },
    stores: {
        en: "Our stores",
    },
    en: [
        {
            id: "tangy-jelly",
            title: "Tangy jelly",
            description: "Not every pirate will admit to having a sweet tooth. This seriously tangy jelly is different!  Treat yourself to your favourite sweet while holding onto your hard-core image with its rock hard outside and sweet soft centre.",
            img: img1,
        },
        {
            id: "sweet-jelly",
            title: "Sweet jelly",
            description: "Banana, strawberry, bear or a dual-coloured skull, take your pick. This is the king among sweets. On a number of islands we visited, the wealth of the future bride is judged by the size of her jelly dowry!",
            img: img2,
        },
        {
            id: "batons",
            title: "Batons",
            description: "The baton forms an integral part of all pirate expeditions. Not only does it taste great and have fantastic lasting power, but if necessary it can also double up as a sailing rope and can come in handy for tying up hostages.",
            img: img3,
        },
        {
            id: "marshmallow",
            title:<Fragment>Marsh-<br />mallow</Fragment>,
            description: "Not every pirate will admit to having a sweet tooth. This seriously tangy jelly is different!  Treat yourself to your favourite sweet while holding onto your hard-core image with its rock hard outside and sweet soft centre.",
            img: img4,
        },
        {
            id: "chocolate",
            title: "Chocolate",
            description: "While every proper pirate is tough on the outside, but soft on the inside, you’ll find the exact opposite with these chocolate sweets. Beneath the softest chocolate shell we find crunchy almonds, hazelnuts, chunks of coconut and much more!",
            img: img5,
        },
        {
            id: "chewing-gum",
            title: "Chewing gum",
            description: "When not chewing coca leaves, pirates can be found chewing gum. But not the typical white variety. Even the lowest ranking sailor turns his nose up at that. Pirate chewing gum is colourful and tastes different every time!",
            img: img6,
        },
    ],
}

export { productsItems };
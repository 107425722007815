import React from 'react';
// import { useLocation } from 'react-router-dom';

import prodejnyMapBg from "../../assets/img/stores/prodejny_map_bg-min.jpg";
import Helmet from '../Helmet';
import { AllStores } from './AllStores';

const Stores = ({ cities, data }) => {

    const scroll = (link) => {
        const section = document.getElementById( link );
        section.scrollIntoView( { behavior: 'smooth'} );
    };
   
      
    return (
        <div id="page-prodejny" className="page fade-onload">
            <Helmet
                title="Stors | Captain Candy"
                metaDesc="Our stores can be found in various cities in Europe. But beware! Not every shop with a wooden interior is a guarantee of Captain Candy quality. Always look only for the original Captain Candy stores, which are a safe bet. You can find a list of our stores on our website."
            />
            <header>
                <img src={prodejnyMapBg} alt="Stores map" />
                <div className="map-wrap">
                    <div id="img-map">
                        <div className="text-center">
                         
                        </div>
                    </div>
                    {cities?.map((city) => (
                        <div className="path" id={city.id} key={city.scrollId}>
                            <img src={city.path} alt={city.name} className="prodejny-map-path" />
                            <button className="map-button" onClick={() => scroll(city?.cityId)}>
                                <img src={city.img} alt={city.name} className="prodejny-map-path-city hidden-xs" />
                                <img src={city.imgXs} alt={city.name} className="prodejny-map-path-city visible-xs" />
                            </button>
                        </div>
                    ))}
                </div>
            </header>
            <section className="boxes" data-js="scroll_fullpage" data-scroll-js="normal_scroll_content_start" id="our-stores">
                <div className="container-custom" id="benatky">
                    {data?.map((store) => (
                        <div className="row-custom" id={store.cityId} key={store.cit}>
                            <h1 className="heading-big aos-init aos-animate" data-aos="fade-up">
                                {store?.title}
                            </h1>
                            <AllStores allStore={store} />
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default Stores;
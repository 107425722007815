const InstagramIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30px" height="30px" className="instagram-icon">
        <defs>
          <linearGradient id="instagramGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: "#405DE6" }} />
            <stop offset="25%" style={{ stopColor: "#5851DB" }} />
            <stop offset="50%" style={{ stopColor: "#833AB4" }} />
            <stop offset="75%" style={{ stopColor: "#C13584" }} />
            <stop offset="100%" style={{ stopColor: "#E1306C" }} />
          </linearGradient>
        </defs>
        <path
          d="M16.5 5C10.16639 5 5 10.16639 5 16.5L5 31.5C5 37.832757 10.166209 43 16.5 43L31.5 43C37.832938 43 43 37.832938 43 31.5L43 16.5C43 10.166209 37.832757 5 31.5 5L16.5 5z M16.5 8L31.5 8C36.211243 8 40 11.787791 40 16.5L40 31.5C40 36.211062 36.211062 40 31.5 40L16.5 40C11.787791 40 8 36.211243 8 31.5L8 16.5C8 11.78761 11.78761 8 16.5 8z M34 12C32.895 12 32 12.895 32 14C32 15.105 32.895 16 34 16C35.105 16 36 15.105 36 14C36 12.895 35.105 12 34 12z M24 14C18.495178 14 14 18.495178 14 24C14 29.504822 18.495178 34 24 34C29.504822 34 34 29.504822 34 24C34 18.495178 29.504822 14 24 14z M24 17C27.883178 17 31 20.116822 31 24C31 27.883178 27.883178 31 24 31C20.116822 31 17 27.883178 17 24C17 20.116822 20.116822 17 24 17z"
          style={{ fill: "#fff", transition: 'fill 0.3s ease' }}
        />
      </svg>
    );
  };
  
  export default InstagramIcon;
  
import React, { useState, useEffect } from "react";
import "./style.css";

const AccordionMap = ({ data, open }) => {
  const [accordionItems, setaccordionItems ] = useState();

  const click = (i) => {
  	const newAccordion = accordionItems?.map((el) => {
      if (i === el.id) {
        el.open = !el.open
        return el;
      }
      return el;
    });
    setaccordionItems(newAccordion);
  };

  // const open = (hrefLink) => {
  //   window.open(hrefLink, "_blank");
  // };

  useEffect(() => {
  	const accordion = [];
    data?.forEach((i) => {
      accordion.push({
        id: i.id,
        title: i.title, 
        link: i?.href,
        table: i?.table,
        open: false
      });
    });
      setaccordionItems(accordion);
  }, [data]);
    
  return (
    <section id="map-xs-wrap">
      <div className="container-custom">
          <div className="accordion">
            {accordionItems?.map((item) => (
              <div className={`item ${item.open ? "open" : ""}`} onClick={() => click(item.id)} key={item.id}>
                <div className={`button ${item.open ? "open" : ""}`}><span/></div>
                <h3 className="heading">
                    {item.title}
                </h3>
                <div className={item.open 
                  ? "contentItem contentItem-open" 
                  : "contentItem p-top-bottom"}
                >
                    <div className={item.open 
                      ? "contentItem-text contentItem-text-open table-wrap" 
                      : "contentItem-text"}
                    >
                        {/* {item.table} */}
                        <table>
                          <tbody>
                              {item?.table?.map((e, i) => (
                                  <tr key={`item${i}`}>
                                      <td>{e?.open}</td>
                                      <td>{e?.time}</td>
                                  </tr>
                              ))}
                          </tbody>
                        </table>
                    </div>
                    <button onClick={() => open({ link: item.link, title: item.title })} className="btn btn-primary btn-gold">Go to</button>
                </div>
              </div>
            ))}
          </div>
      </div>
    </section>
  )
};

export default AccordionMap;

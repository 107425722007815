import React, { useEffect, useState }from 'react';
import axios from "axios";
import { axiosLink } from '../../axiosLink';
import Token from "../../Utils/Token"
import Input from '../Input';

const AddNewViewer = ({ show, hideModal }) => {
    const [data, setUser] = useState();
    const [done, setDone] = useState(false);
    const [exist, setExist] = useState(false);
    const { activeToken } = Token();

    const handleChange = (event) => {
        setUser({ ...data, [event.target.name]: event.target.value });
      };

      const handleLogin = (event) => {
        event.preventDefault();
        axios.post(
            `${axiosLink}/pdfview/addPdfView`,
            { email: data.email },
            {
                headers: {
                    Authorization: activeToken,
                    'Content-Type': 'application/json',
                },
            },
            ).then((res) => {
                if(res.data?.msg) {
                    setDone(true)
                    setTimeout(() => {
                        setUser();
                        setDone(false)
                        hideModal(true);
                    }, 4000);
                   
                } else {
                    setExist(true);
                    setTimeout(() => {
                        setExist(false);
                    }, 4000);
                }
            console.log("RES", res);
        })
    }

    return (
        <div className="in fade fade-onload pdf-dashboard-email" style={show ? { display: "none" } : { display: "flex" }}>
            <div className="modal-dialog">
                <div className="modal-content p-3">
                    <button onClick={() => hideModal(true)} className='button-add-new'>X</button>
                    <h3>Add New Viewer</h3>
                    <form onSubmit={handleLogin} className="he-max-w-100 he-text-center">
                        <Input change={handleChange} defaultValue={data?.email} type="text" name="email" placeholder="Email" />
                        <br />
                            {exist && (<span className="he-absolute he-d-block he-input__error-msg he-parag-small he-fw-medium he-mt-12 he-text-left">This user exist</span>)}
                            {done && <span className="he-d-block he-login-form-success he-parag-small he-fw-medium he-mt-12 he-text-left" style={{ color: 'green', fontWeight: "bold" }}>User created sucessfully!</span>}
                        <br />
                        <button className="btn mr-10" aria-label="login-button" type="submit">
                            ADD
                        </button>
                        <button onClick={() => hideModal(true)} type="button" className="btn he-fw-bold">
                            X
                        </button>
                    </form>
                </div>
            </div>
            {/* onClick={() => changeStatus(el)} */}
        </div>
  )
}

export default AddNewViewer;
import React from 'react'
import Contact from '../components/Contact/Contact';
import { contactData } from '../assets/dummyData/contactData';

const ContactPage = () => {
  const lang = "en";
  return (
    <Contact data={contactData[lang]}/>
  )
}

export default ContactPage;
import React from 'react';
import { Helmet as HelmetReact } from 'react-helmet';
import metaImg from "../../assets/img/hp_about_1_inner-min.jpg"

function Helmet({
  title,
  metaDesc,
  type,
  twitterDescription,
  twitterImage,
  twitterTitle,
  locale,
  opengraphImage,
}) {

  const base_url = window.location.origin;
  
  return (
    <HelmetReact>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={metaDesc} />
      <meta property="og:type" content={type || "website"} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDesc} />
      <meta property="og:image" content={`${base_url}${opengraphImage || metaImg}`} />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="twitter:title" content={twitterTitle || title} />
      <meta property="twitter:description" content={twitterDescription || metaDesc} />
      <meta property="twitter:image" content={`${base_url}${twitterImage || metaImg}`} />
    </HelmetReact>
  );
}

export default Helmet;
